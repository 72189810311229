import React from "react";
import { Box, Typography, Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import { Flag, Visibility } from "@material-ui/icons";


const AboutUs = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        textAlign: "left",
        height: "100%",
    
      }}
    >
    <Box display={'flex'}>
      <Box>
      <img src="/Images/AboutUs1.png" alt="Social vector" width={700}/>
      </Box>
      <Box  textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography variant={isSmallScreen ? 'body2' : 'body1'} color="text.secondary" sx={{ mt: 2,textAlign:'left' }}>
        KAVACH boasts a dedicated development team of over 20 professionals
        based in the metro city of Noida. Brought to you by Teetrahedron
        Manufacturing Services, a company with over 9 years of experience,
        Kavach excels in aiding manufacturing businesses with Manufacturing
        Excellence, Plant Layout Design, System Standardization (ISO
        Implementation, Manufacturing Audit), Energy Cost Reduction, and DOJO
        Implementation.
      </Typography>
      <Grid  mt={4}  justifyContent="center" width={'100%'} height={'100%'}>
        <Box>
          <Box sx={{width:"100%",height:'auto',textAlign:'left'}}>
            <CardContent>
              <Typography
                variant="h5"
                component="h2"
                sx={{ fontWeight: "bold" }}
              >
                Our Vision
              </Typography>
        
              <Typography variant="body2" sx={{textAlign:'left',mt:2}}>
                At Kavach, our vision is to revolutionize safety management
                through digital innovation, fostering a safer and more efficient
                workplace. We aspire to empower organizations to seamlessly
                protect their personnel, equipment, and environment, driving
                proactive safety measures and continuous improvement. By
                advancing safety technology, we aim to set new standards for
                workplace safety and operational excellence.
              </Typography>
            </CardContent>
          </Box>
        </Box>
        <Box>
          <Box sx={{width:"100%",height:'auto',textAlign:'left'}}>
            <CardContent >
              <Typography
                variant="h5"
                component="h2"
                sx={{ fontWeight: "bold"}}
              >
                Our Mission
              </Typography>
        
              <Typography variant="body2" sx={{textAlign:'left',mt:2}}>
                Our Kavach Safety Web App helps organizations digitize their
                safety management activities, ensuring the protection of
                personnel, equipment, and the environment from adverse
                incidents. By streamlining safety processes, Kavach enables
                proactive management and enhances overall workplace safety.
              </Typography>
            </CardContent>
          </Box>
        </Box>
      </Grid>
      </Box>
    </Box>
    </Box>
  );
};

export default AboutUs;
