// import React, { useState, useEffect } from 'react';
// import { 
//   Typography, 
//   TextField, 
//   Button, 
//   FormControl, 
//   InputLabel, 
//   Select, 
//   MenuItem, 
//   Grid, 
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box,
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// // import { DatePicker } from '@material-ui/pickers';
// import { firestore } from '../../firebase/firebaseConfig';
// import ImageUploader from './../../componants/ImageUploader'; 
// import firebase from 'firebase/compat/app';
// import { DatePicker, TimePicker } from '@material-ui/pickers';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },

// }));

// const WorkPermit = ({ userId, email, company_name }) => {
//   const classes = useStyles();
//   const [date, setDate] = useState(new Date());
//   const [departmentName, setDepartmentName] = useState('');
//   const [noOfWorkPermitIssued, setNoOfWorkPermitIssued] = useState(1);
//   const [logRegisterImage, setLogRegisterImage] = useState(null);
//   const [departments, setDepartments] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [caseCount, setCaseCount] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   const [workPermitType, setWorkPermitType] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       // Fetch departments
//       const departmentsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('department_name')
//         .get();
//       const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setDepartments(departmentData);

//       // Fetch case count
//       const companyDoc = await firestore.collection('company_data').doc(company_name).get();
//       if (companyDoc.exists) {
//         setCaseCount(companyDoc.data().case_count || 0);
//       }


//       // Fetch user reports
//       fetchReports();
//     };

//     fetchData();
//   }, [company_name, userId]);

//   const fetchReports = async () => {
//     const reportsSnapshot = await firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('work_permit')
//       .where('userId', '==', userId)
//       .get();

//     const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//     setReports(reportsData);
//   };

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };

//   const validate = () => {
//     const errors = {};
//     if (!date) errors.date = 'Date is required';
//     if (!departmentName) errors.departmentName = 'Department Name is required';
//     if (!noOfWorkPermitIssued) errors.noOfWorkPermitIssued = 'No. of Work Permit Issued is required';
//     if (!logRegisterImage) errors.logRegisterImage = 'Log Register Image is required';
//     if (!workPermitType) errors.workPermitType = 'Work Permit Type is required';
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
//   const uploadImage = async (file) => {
//     if (!file) return null;
  
//     const storageRef = firebase.storage().ref();
//     const fileRef = storageRef.child(`work_permit_images/${Date.now()}_${file.name}`);
    
//     try {
//       await fileRef.put(file);
//       const downloadURL = await fileRef.getDownloadURL();
//       return downloadURL;
//     } catch (error) {
//       console.error("Error uploading image: ", error);
//       throw error;
//     }
//   };

// const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         let imageUrl = null;
//         if (logRegisterImage) {
//           imageUrl = await uploadImage(logRegisterImage);
//         }
  
//         const caseNumber = caseCount + 1;
//         await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('work_permit')
//           .add({
//             date,
//             departmentName,
//             noOfWorkPermitIssued,
//             noOfWorkPermitClosed: 0,
//             noOfWorkPermitInspected: 0,
//             noOfWorkPermitComplied: 0,
//             workPermitType,
//             logRegisterImage: imageUrl,
//             caseNumber,
//             userId,
//             isPending: true,
//           });
  
//         // Update case count
//         await firestore.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });
//         const workPermitRef = firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('work_permit')
//         .doc('details');

//       const workPermitDoc = await workPermitRef.get();

//       if (workPermitDoc.exists) {
//         await workPermitRef.delete();
//       }
  
//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };
//   const clearForm = () => {
//     setDate(new Date());
//     setDepartmentName('');
//     setNoOfWorkPermitIssued(0);
//     setWorkPermitType('');
//     setLogRegisterImage(null);
//     setErrors({});
//   };
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           Work Permit
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date"
//                 value={date.toString()}
//                 onChange={setDate}
//                 fullWidth
//                 error={!!errors.date}
//                 helperText={errors.date}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.departmentName}>
//                 <InputLabel>Department Name</InputLabel>
//                 <Select
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                 >
//                   {departments.map((dept) => (
//                     <MenuItem key={dept.id} value={dept.name}>
//                       {dept.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.departmentName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.departmentName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="No. of Work Permit Issued"
//                 type="number"
//                 value={noOfWorkPermitIssued.toString()}
//                 onChange={(e) => setNoOfWorkPermitIssued(parseInt(e.target.value, 10) || 0)}
//                 fullWidth
//                 error={!!errors.noOfWorkPermitIssued}
//                 helperText={errors.noOfWorkPermitIssued}
//                 inputProps={{ min: 1 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.workPermitType}>
//                 <InputLabel>Work Permit Type</InputLabel>
//                 <Select
//                   value={workPermitType}
//                   onChange={(e) => setWorkPermitType(e.target.value)}
//                 >
//                   <MenuItem value="Hot work">Hot work</MenuItem>
//                   <MenuItem value="Work at height">Work at height</MenuItem>
//                   <MenuItem value="Excavation">Excavation</MenuItem>
//                   <MenuItem value="Heavy lifting">Heavy lifting</MenuItem>
//                   <MenuItem value="Confined space">Confined space</MenuItem>
//                   <MenuItem value="Electrical works">Electrical works</MenuItem>
//                   <MenuItem value="Radiation works">Radiation works</MenuItem>
//                   <MenuItem value="Others">Others</MenuItem>
//                 </Select>
//                 {errors.workPermitType && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.workPermitType}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//            <Grid item xs={12}>
//               <ImageUploader
//                 value={logRegisterImage}
//                 onChange={setLogRegisterImage}
//                 error={!!errors.logRegisterImage}
//                 helperText={errors.logRegisterImage}
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className={classes.submitButton}
//             disabled={isLoading}
//           >
//             {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//           </Button>
//           <Button variant="outlined" onClick={clearForm}>
//             Clear
//           </Button>
//         </form>
//       </Paper>

//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.date)} </Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>
//     </div>
//   );
// };

// export default WorkPermit;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { firestore } from '../../firebase/firebaseConfig';
import ImageUploader from '../../componants/ImageUploader';
import firebase from 'firebase/compat/app';
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledReportsSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const WorkPermit = ({ userId, email, company_name }) => {
  const [date, setDate] = useState(new Date());
  const [departmentName, setDepartmentName] = useState('');
  const [noOfWorkPermitIssued, setNoOfWorkPermitIssued] = useState(1);
  const [logRegisterImage, setLogRegisterImage] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [errors, setErrors] = useState({});
  const [caseCount, setCaseCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [workPermitType, setWorkPermitType] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchData = async () => {
      const departmentsSnapshot = await firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDepartments(departmentData);

      const companyDoc = await firestore.collection('company_data').doc(company_name).get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }

      fetchReports();
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('work_permit')
      .where('userId', '==', userId)
      .get();

    const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReports(reportsData);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const validate = () => {
    const errors = {};
    if (!date) errors.date = 'Date is required';
    if (!departmentName) errors.departmentName = 'Department Name is required';
    if (!noOfWorkPermitIssued) errors.noOfWorkPermitIssued = 'No. of Work Permit Issued is required';
    if (!logRegisterImage) errors.logRegisterImage = 'Log Register Image is required';
    if (!workPermitType) errors.workPermitType = 'Work Permit Type is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadImage = async (file) => {
    if (!file) return null;
  
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`work_permit_images/${Date.now()}_${file.name}`);
    
    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        let imageUrl = null;
        if (logRegisterImage) {
          imageUrl = await uploadImage(logRegisterImage);
        }
  
        const caseNumber = caseCount + 1;
        await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('work_permit')
          .add({
            date,
            departmentName,
            noOfWorkPermitIssued,
            noOfWorkPermitClosed: 0,
            noOfWorkPermitInspected: 0,
            noOfWorkPermitComplied: 0,
            workPermitType,
            logRegisterImage: imageUrl,
            caseNumber,
            userId,
            isPending: true,
          });
  
        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });
        const workPermitRef = firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('work_permit')
          .doc('details');

        const workPermitDoc = await workPermitRef.get();

        if (workPermitDoc.exists) {
          await workPermitRef.delete();
        }
  
        clearForm();
        setIsLoading(false);
        setSnackbar({ open: true, message: 'Form submitted successfully', severity: 'success' });
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsLoading(false);
        setSnackbar({ open: true, message: 'Failed to submit form', severity: 'error' });
      }
    }
  };

  const clearForm = () => {
    setDate(new Date());
    setDepartmentName('');
    setNoOfWorkPermitIssued(1);
    setWorkPermitType('');
    setLogRegisterImage(null);
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            Work Permit
          </Typography>
          <FormControl onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={setDate}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!errors.date} 
                      helperText={errors.date}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.departmentName && (
                    <Typography variant="caption" color="error">
                      {errors.departmentName}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="No. of Work Permit Issued"
                  type="number"
                  value={noOfWorkPermitIssued}
                  onChange={(e) => setNoOfWorkPermitIssued(parseInt(e.target.value, 10) || 0)}
                  fullWidth
                  error={!!errors.noOfWorkPermitIssued}
                  helperText={errors.noOfWorkPermitIssued}
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.workPermitType}>
                  <InputLabel>Work Permit Type</InputLabel>
                  <Select
                    value={workPermitType}
                    onChange={(e) => setWorkPermitType(e.target.value)}
                  >
                    <MenuItem value="Hot work">Hot work</MenuItem>
                    <MenuItem value="Work at height">Work at height</MenuItem>
                    <MenuItem value="Excavation">Excavation</MenuItem>
                    <MenuItem value="Heavy lifting">Heavy lifting</MenuItem>
                    <MenuItem value="Confined space">Confined space</MenuItem>
                    <MenuItem value="Electrical works">Electrical works</MenuItem>
                    <MenuItem value="Radiation works">Radiation works</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                  {errors.workPermitType && (
                    <Typography variant="caption" color="error">
                      {errors.workPermitType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <ImageUploader
                  value={logRegisterImage}
                  onChange={setLogRegisterImage}
                  error={!!errors.logRegisterImage}
                  helperText={errors.logRegisterImage}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={24} /> : null}
              >
                Submit
              </StyledButton>
              <Button variant="outlined" onClick={clearForm}>
                Clear
              </Button>
            </Box>
          </FormControl>
        </StyledPaper>

        <StyledPaper elevation={3}>
          <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          {/* <Box mt={2}>
            {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <StyledCard key={report.id}>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                  </CardContent>
                </StyledCard>
              ))
            }
          </Box> */}
          <Box mt={3}>
          <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <Grid item xs={12} sm={6} md={4} key={report.id}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                    <Typography>Description: {report.description}</Typography>
                    <Typography color={report.isPending ? 'error' : 'green'} >Status: {report.isPending ? 'Pending' : 'Completed'}</Typography>
                  </CardContent>
                </StyledCard>
                </Grid>
              ))}
          </Grid>
          </Box>
        </StyledPaper>
      </Container>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default WorkPermit;