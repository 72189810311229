import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NotificationImportant, TrackChanges, TrendingUp } from "@material-ui/icons";
import { Article, Radar, WorkHistory } from "@mui/icons-material";

// Reusable Card Component
const ResponsiveCard = ({ Icon, title, description }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        boxShadow: 3,
        borderRadius: 2,
        height: 250, // Auto height for flexibility
        padding: 2,
        gap: 2,
        border: `1px solid green`,
        flexDirection: isSmallScreen ? "column" : "row", // Stack vertically on small screens
        width: "100%", // Ensure card takes full width of the grid item
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isSmallScreen ? "100%" : 150,
          height: isSmallScreen ? 150 : "auto",
        }}
      >{Icon}</Box>
      <CardContent
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center content vertically
          "&:last-child": { paddingBottom: "2px" },
        }}
      >
        <Typography
          gutterBottom
          variant={isSmallScreen ? "subtitle2" : "h6"}
          component="div"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: isSmallScreen ? "0.75rem" : "1rem" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

// Data for the cards
const cards = [
  {
    Icon: <TrendingUp style={{fontSize:40,color:'#80ED99'}}/>,
    title: "Safety Dashboards for trend analysis",
    description:
      "The customizable safety dashboard displays leading and lagging indicators, action summary, safety pyramid.",
  },
  {
    Icon: <Article style={{fontSize:40,color:'#80ED99'}}/>, // Replace with appropriate icons
    title: "Safety Library to store documents",
    description:
      "The document management system can be used to store any safety or SOP-related documents and set reminder alerts.",
  },
  {
    Icon: <TrackChanges style={{fontSize:40,color:'#80ED99'}}/>, // Replace with appropriate icons
    title: "Observation management",
    description:
      "Improve behavior-based safety practices within your organization by encouraging employees to report any type of observation they notice.",
  },
  {
    Icon: <Radar style={{fontSize:40,color:'#80ED99'}}/>, // Replace with appropriate icons
    title: "Incident and near-miss reporting",
    description:
      "Enable quick and efficient reporting of incidents and near-misses to enhance workplace safety. This system ensures that all safety concerns are documented, tracked.",
  },
  {
    Icon: <NotificationImportant style={{fontSize:40,color:'#80ED99'}}/>, // Replace with appropriate icons
    title: "Alerts",
    description:
      "Receive timely alerts and notifications to stay informed about critical updates, tasks, and safety reminders, ensuring proactive management and quick response to potential issues.",
  },
  {
    Icon: <WorkHistory style={{fontSize:40,color:'#80ED99'}}/>, // Replace with appropriate icons
    title: "Customizable workflows",
    description:
      "Offers two types of incident investigation workflows – sequential and parallel with 5 levels of approval and escalation feature.",
  },
];


const ThreeCardGrid = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={4}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ResponsiveCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ThreeCardGrid;
