// MapComponent.js
import React from 'react';
import { Box } from '@mui/material';

const MapComponent = ( { latitude, longitude, zoom } ) => {
   const src = `https://maps.google.com/maps?q=${latitude},${longitude}&z=${zoom}&output=embed`;

    return (
        <Box sx={{ width: '100%', height: '500px', overflow: 'hidden' ,borderRadius: '8px',
        border: '2px solid silver',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                src={src}
                allowFullScreen
                
            ></iframe>
        </Box>
    );
};

export default MapComponent;
