import React, { useEffect, useState, useCallback } from "react";
import { Typography, Button, Box, Paper, Container, ThemeProvider, createTheme, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions, styled, Card, IconButton, InputLabel, Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { auditSections } from "../utils/questions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getUIDLocally } from "./../utils/userUtils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DetailedDataTable from "../Screens/users/auditTable/DetailedDataTable";
import { ActionPlanModal } from "./ActionPlanModal";
import { Question } from "./Question";
import { useLocation, useNavigate } from 'react-router-dom';

const db = firebase.firestore();

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          marginBottom: "16px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f8f8f8",
          borderRadius: "8px 8px 0 0",
          "& .MuiAccordionSummary-content": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "16px 24px 24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});

const SafetyAuditQuestionnaire = ({ company_name }) => {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [images, setImages] = useState({});
  const [actionPlanModalOpen, setActionPlanModalOpen] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [currentQuestionText, setCurrentQuestionText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        if (!userId) {
          throw new Error("User ID not found. Please log in again.");
        }
        const userDocRef = db.collection("peoples").doc("users").collection("users").doc(userId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
        } else {
          throw new Error("User data not found. Please ensure you have the correct permissions.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoadError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const handleChange = useCallback((questionId, value) => {
    setAnswers(prev => ({ ...prev, [questionId]: value }));
    setErrors(prev => ({ ...prev, [questionId]: false }));
  }, []);

  const handleImageChange = useCallback((questionId, file, previewUrl) => {
    setImages(prev => ({ ...prev, [questionId]: file }));
  }, []);

  const handleActionPlanClick = useCallback((questionId, questionText) => {
    setCurrentQuestionId(questionId);
    setCurrentQuestionText(questionText);
    setActionPlanModalOpen(true);
  }, []);

  const handleActionPlanSubmit = useCallback(async (actionPlans, questionId, questionText) => {
    console.log("Action plans submitted for question:", questionId, questionText);
    console.log("Action plans:", actionPlans);
    // Additional actions if needed
  }, []);

  const validateSection = useCallback((questions) => {
    const newErrors = {};
    questions.forEach((question) => {
      if (!answers[question.id] || answers[question.id].length === 0) {
        newErrors[question.id] = true;
      }
    });
    setErrors(prev => ({ ...prev, ...newErrors }));
    return Object.keys(newErrors).length === 0;
  }, [answers]);

  const uploadImage = useCallback(async (file) => {
    if (!file) return "";
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`images/${file.name}_${Date.now()}`);
    await fileRef.put(file);
    return await fileRef.getDownloadURL();
  }, []);


  const handleSubmit = useCallback(
    async (section, e) => {
      e.preventDefault();
      if (!userData) {
        alert("User data is not available. Please try again.");
        return;
      }
      const { company_name, userId } = userData;
      const isValid = validateSection(section.questions);
      if (isValid) {
        setIsLoading(true);
        try {
          // Fetch the most recent audit document
          const auditQuery = db
            .collection("company_data")
            .doc(company_name)
            .collection(company_name)
            .doc("Audits")
            .collection(userId)
            .orderBy("createdAt", "desc")
            .limit(1);
  
          const auditSnapshot = await auditQuery.get();
          
          if (auditSnapshot.empty) {
            throw new Error("No active audit found. Please start a new audit.");
          }
  
          const auditDocRef = auditSnapshot.docs[0].ref;
          const questionCollection = auditDocRef.collection("questions");
  
          const questionPromises = section.questions.map(async (question) => {
            const questionId = question.id;
            const answer = answers[question.id] || "";
            const imageFile = images[question.id];
            let imageUrl = "";
  
            if (imageFile) {
              imageUrl = await uploadImage(imageFile);
            }
  
            return questionCollection.add({
              questionId,
              answer,
              imageUrl,
            });
          });
  
          await Promise.all(questionPromises);
  
          // // Update the audit document to mark it as not pending
          // await auditDocRef.update({
          //   isPending: false,
          //   lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
          // });
  
          setIsLoading(false);
          alert("Form submitted successfully");
        } catch (e) {
          console.error("Error adding document: ", e);
          setIsLoading(false);
          alert("Failed to submit form: " + e.message);
        }
      }
    },
    [validateSection, answers, images, userData, uploadImage]
  );

  if (!userData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  


  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: "12px" }}>
            <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
                Safety Audit Questionnaire for {company_name}
              </Typography>
              <Button variant="contained" onClick={() => setOpenDialog(true)}>Audit Report</Button>
            </Box>
            {auditSections.map((section) => (
              <Accordion key={section.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{section.id}) {section.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    {section.questions.map((question) => (
                      <Grid item xs={12} key={question.id}>
                        <Box sx={{ p: 3, border: "1px solid #e0e0e0", borderRadius: "8px", backgroundColor: "#ffffff" }}>
                          <Question
                            question={question}
                            answer={answers[question.id]}
                            onChange={handleChange}
                            error={errors[question.id]}
                            onImageChange={handleImageChange}
                            onActionPlanClick={handleActionPlanClick}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={4} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleSubmit(section, e)}
                      disabled={isLoading}
                      sx={{ minWidth: "200px" }}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : "Submit Section"}
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        </Container>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="lg" fullWidth>
          <DialogTitle>Detailed Report</DialogTitle>
          <DialogContent>
            <DetailedDataTable company_name={userData.company_name} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
        <ActionPlanModal
          open={actionPlanModalOpen}
          onClose={() => setActionPlanModalOpen(false)}
          section={auditSections.find(section => section.questions.some(q => q.id === currentQuestionId))?.title.replace(/[^a-zA-Z0-9-_]/g, "_") || ""}
          questionId={currentQuestionId}
          questionText={currentQuestionText}
          company_name={userData.company_name}
          sanitizedTitle={auditSections.find(section => section.questions.some(q => q.id === currentQuestionId))?.title.replace(/[^a-zA-Z0-9-_]/g, "_") || ""}
          onSubmit={handleActionPlanSubmit}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default SafetyAuditQuestionnaire;