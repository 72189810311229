import React from "react";
import { Box, Typography, Button, Link, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DownloadBanners = ( { onScrollTo } ) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleScrollTo = (section) => {
    onScrollTo(section);
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens, horizontally on larger screens
        height: { xs: "auto", md: 400 },
        justifyContent: "space-evenly",
        alignItems: "center",
        px: 2, // Add horizontal padding for small screens
        width:'100%',
        height:'auto',
      
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? "50%" : "25%", // Full width on small screens, 40% on larger screens
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 4, md: 0 }, // Add margin-bottom on small screens
       
        }}
      >
        <img
          src="/Images/sheildMobile.png"
          alt="Image"
          height={{ xs: 200, md: 300 }} // Adjust height for small screens
          style={{ maxWidth: "100%", height: "auto" }} // Ensure image scales with screen size
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "50%" }, // Full width on small screens, 40% on larger screens
          padding: { xs: 2, md: 6 }, // Adjust padding for different screen sizes
          textAlign: "center"
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ fontSize: { xs: "1.5rem", md: "2rem"}, mt:4 }} // Adjust font size for different screens
          >
            Safety Management
          </Typography>
        </Box>
        <Box sx={{ mb: 3}}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: { xs: "1.25rem", md: "1.5rem", my: 6 } }} // Adjust font size for different screens
          >
            while on the move!
          </Typography>
        </Box>

        <Box >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontSize: { xs: "0.875rem", md: "1rem", textAlign:'left'} }} // Adjust font size for different screens
          >
            With the Kavach Safety Web App, you can effortlessly manage your
            workplace safety activities from anywhere. Report incidents or
            near-misses, approve permit requests, or conduct safety audits—all
            directly from your web browser. Stay connected and ensure a safer
            work environment with the convenience and flexibility of the Kavach
            Safety Web App.
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Button sx={{ mx: 1, transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)'
        }}}>
            <img
              src="/Images/playicon.png"
              width={120}
              style={{ maxWidth: "100%", height: "auto" ,
              }}
              
            />
          </Button>
          <Button sx={{ mx: 1 ,transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)'
        }}}>
            <img
              src="/Images/appstoricon.png"
              width={120}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button onClick={() => handleScrollTo("contact")} underline="always" color="primary">
            Request a free Demo account
          </Button>
          <Typography variant="body2" color="text.secondary">
            before you can use the app.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadBanners;
