import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, Button, Box, CircularProgress
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';

const DetailedDataTable = ({ companyName, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailedData();
  }, [companyName, startDate, endDate, dataType]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return 'Invalid Date';
  };

  const fetchDetailedData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const ppeGrievancesCollection = db
      .collection('company_data')
      .doc(companyName)
      .collection(companyName)
      .doc('for_user')
      .collection('ppe_grievances');

    const querySnapshot = await ppeGrievancesCollection.get();

    const detailedData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
    //   const dateObj = data.dateOfPPEGrievancesRaised.toDate();
    const dateObj = data.dateOfPPEGrievancesRaised instanceof firebase.firestore.Timestamp 
    ? data.dateOfPPEGrievancesRaised.toDate() 
    : new Date(data.dateOfPPEGrievancesRaised);
  //  console.log('data1',dateObj,startDate,endDate);

      if (dateObj >= startDate && dateObj <= endDate) {
       // console.log('data2',dateObj);
        if (
          (dataType === 'reported' && data.isPending) ||
          (dataType === 'closed' && !data.isPending)
        ) {
         //   console.log('data3',dateObj);
          detailedData.push({
            caseNumber: data.caseNumber,
            dateOfPPEGrievancesRaised: dateObj.toLocaleDateString(),
            grievancesRaisedBy: data.grievancesRaisedBy,
            reportingPersonDesignation: data.reportingPersonDesignation,
            employeeId: data.employeeId,
            departmentName: data.departmentName,
            areaName: data.areaName,
            ppeGrievancesDetails: data.ppeGrievancesDetails
          });
        }
      }
    });

    setData(detailedData);
    setLoading(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(workbook, `ppe_grievances_${dataType}_detailed_data.xlsx`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detailed Data for {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '1rem' }}>
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Case Number</TableCell>
              <TableCell>Date Raised</TableCell>
              <TableCell>Raised By</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Employee ID</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Grievance Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.caseNumber}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.caseNumber}</TableCell>
                <TableCell>{row.dateOfPPEGrievancesRaised}</TableCell>
                <TableCell>{row.grievancesRaisedBy}</TableCell>
                <TableCell>{row.reportingPersonDesignation}</TableCell>
                <TableCell>{row.employeeId}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.areaName}</TableCell>
                <TableCell>{row.ppeGrievancesDetails}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedDataTable;