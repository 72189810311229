import React from 'react';
import { Typography, Divider, Container, Box } from '@mui/material';
import SearchableInput from './../../componants/SearchableInput';

const AddAccidentReport = ({ companyName }) => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add Injury Category
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="injury_category"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Injury Category"
        />

        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
          Add Affected Workman Employee Type
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="affected_workman_employee_type"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Affected Workman Employee Type"
        />

        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
          Add Body Part Affected
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="body_part_affected"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Body Part Affected"
        />

        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
          Add Type of Accident/Injury
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="type_of_accident"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Type of Accident/Injury"
        />
      </Box>
    </Container>
  );
};

export default AddAccidentReport;