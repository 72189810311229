import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BarChart, ExpandLess, ExpandMoreOutlined, FormatAlignCenter, Home, Warning, Whatshot, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Avatar, Button, Collapse, ThemeProvider, Typography, styled, IconButton } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getUIDLocally, setLoginStatusLocally, setUIDLocally } from "./../utils/userUtils";
import { useEffect } from "react";
import { useState } from "react";
import { Analytics, CalendarMonth, Engineering, HealthAndSafety, SafetyCheck, Task, TextSnippet } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme";

const drawerWidth = 260;
const collapsedDrawerWidth = 90;

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    backgroundColor: "#000000",
    color: "#fff",
    width: open ? drawerWidth : collapsedDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
}));


// Routes

const chartroutes = [
  {
    path: "/user/user/unsafe-act-report-chart",
    label: <Typography variant="body2">Unsafe Act Report</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
    permission: "Unsafe Act",
  },
  {
    path: "/user/user/near-miss-report-chart",
    label: <Typography variant="body2">Near Miss Report</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Near Miss Report",
  },
  {
    path: "/user/user/fire-injury-report-chart",
    label: <Typography variant="body2">Fire Incident Report</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
    permission: "Fire Injury",
  },
  {
    path: "/user/user/accident-report-chart",
    label: <Typography variant="body2">Accident Report</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Accident Report",
  },
  {
    path: "/user/user/work-permit-chart",
    label: <Typography variant="body2">Work Permit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
    permission: "Work Permit",
  },
  {
    path: "/user/user/kaizen-report-chart",
    label: <Typography variant="body2">Kaizen Report</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
    permission: "Kaizen Reports",
  },
  {
    path: "/user/user/ppe-grievances-report-chart",
    label: <Typography variant="body2">PPE Grievances Report</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
    permission: "PPE Grievances",
  },
  {
    path: "/user/user/weekly-safety-meeting-chart",
    label: <Typography variant="body2">Weekly Safety Meeting</Typography>,
    icon: <CalendarMonth style={{ fontSize: 20, color: "blue" }} />,
    permission: "Weekly Safety Meeting",
  },
];

const routes = [
  {
    path: "/user/user/unsafe-act-report",
    label: <Typography variant="body2">Unsafe Act Report</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
    permission: "Unsafe Act",
  },
  {
    path: "/user/user/near-miss-report",
    label: <Typography variant="body2">Near Miss Report</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Near Miss Report",
  },
  {
    path: "/user/user/fire-injury-report",
    label: <Typography variant="body2">Fire Incident Report</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
    permission: "Fire Injury",
  },
  {
    path: "/user/user/accident-report",
    label: <Typography variant="body2">Accident Report</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Accident Report",
  },
  {
    path: "/user/user/work-permit",
    label: <Typography variant="body2">Work Permit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
    permission: "Work Permit",
  },
  {
    path: "/user/user/kaizen-report",
    label: <Typography variant="body2">Kaizen Report</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
    permission: "Kaizen Reports",
  },
  {
    path: "/user/user/ppe-grievances-report",
    label: <Typography variant="body2">PPE Grievances Report</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
    permission: "PPE Grievances",
  },
  {
    path: "/user/user/weekly-safety-meeting",
    label: <Typography variant="body2">Weekly Safety Meeting</Typography>,
    icon: <CalendarMonth style={{ fontSize: 20, color: "blue" }} />,
    permission: "Weekly Safety Meeting",
  },
];

// //added for managers

const managerRoutes = [
  {
    path: "/user/manager/unsafe-act-list",
    label: <Typography variant="body2">Unsafe Act List</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
  },
  {
    path: "/user/manager/near-miss-list",
    label: <Typography variant="body2">Near Miss Report List</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
  },
  {
    path: "/user/manager/fire-injury-list",
    label: <Typography variant="body2">Fire Injury List</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
  },
  {
    path: "/user/manager/accident-report-list",
    label: <Typography variant="body2">Accident Report List</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
  },
  {
    path: "/user/manager/work-permit-list",
    label: <Typography variant="body2">Work Permit List</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
  },
  {
    path: "/user/manager/work-permit-auditor-list",
    label: <Typography variant="body2">Work Permit Audit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "#008B8B" }} />,
  },
  {
    path: "/user/manager/kaizen-reports-list",
    label: <Typography variant="body2">Kaizen Report List</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
  },
  {
    path: "/user/manager/ppe-grievances-list",
    label: <Typography variant="body2">PPE Grievances List</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
  },
];

export default function UserDrawer() {
  const [userData, setUserData] = useState(null);
  // const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  // const location = useLocation();
  // const state = location.state;

  const [openForm, setOpenForm] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [openCharts, setOpenCharts] = useState(false);

  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleClickAudit = () => {
    navigate("/user/user/audit");
  };

  const handleClickForm = () => {
    setOpenForm(!openForm);
  };

  const handleClickManager = () => {
    setOpenManager(!openManager);
  };

  const handleClickChart = () => {
    setOpenCharts(!openCharts);
  };
  const handleDashboard = () => {
    navigate("/user/user");
  };

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setLoginStatusLocally(false);
      setUIDLocally(null);
      setUserData(null); // Clear user data from state
      // Optionally, redirect to the login or home page after logging out
      window.location.href = "/"; // Or use a router method if using react-router
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        const userDocRef = firebase
          .firestore()
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(userId);
        const userDoc = await userDocRef.get();

        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return;
  }

  const { company_name, email, permissions, role, user_name, userId } = userData;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <CssBaseline />
        <CustomDrawer
          variant="permanent"
          open={open}
          anchor="left"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              p: 1,
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              {open ? (
                <ChevronLeft style={{ color: "white" }} />
              ) : (
                <ChevronRight style={{ color: "white" }} />
              )}
            </IconButton>
          </Box>
          <Divider />
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <Avatar
              alt="User Name"
              src="/path/to/profile-picture.jpg"
              sx={{ width: open ? 60 : 30, height: open ? 60 : 30 }}
            />
            {open && (
              <Box textAlign="center">
                <Typography variant="body1">{user_name}</Typography>
                <Typography variant="body2">{email}</Typography>
                <Typography variant="body2">
                  <strong>Role:</strong> {role}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />
          <Box>
            <List>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  
                  px: 2.5,
                }}
                onClick={handleDashboard}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Home style={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </List>

            {/* chart section */}
            <List>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleClickChart}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Analytics style={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Analytics"
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openCharts ? <ExpandLess style={{opacity: open ? 1 : 0}}/> : <ExpandMoreOutlined style={{opacity: open ? 1 : 0}}/>}
              </ListItemButton>
              <Collapse in={openCharts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {chartroutes.map(
                    (route, index) =>
                      (!route.permission ||
                        permissions.includes(route.permission)) && (
                        <ListItem
                          key={route.path}
                          disablePadding
                         
                        >
                          <ListItemButton
                            sx={{
                              height: 40,
                              borderRadius: "8px",
                              "&:hover": {
                                backgroundColor: "#3f51b5", // Replace with your desired hover color
                                color: "white", // Optional: change text color on hover
                              },
                            }}
                            onClick={() => navigate(route.path)}
                          >
                            <ListItemIcon
                              sx={{
                                color: "black",
                                
                              }}
                            >
                              {route.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={route.label}
                              sx={{ fontSize: "2px" ,opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                  )}
                </List>
              </Collapse>
            </List>

            {/* Forms section */}
            <List>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "#3f51b5", // Replace with your desired hover color
                    color: "white", // Optional: change text color on hover
                  },
                }}
                onClick={handleClickForm}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FormatAlignCenter style={{ color: "#FFF" }} />
                </ListItemIcon>
                <ListItemText primary="Form's" sx={{ opacity: open ? 1 : 0 }} />
                {openForm ? <ExpandLess style={{opacity: open ? 1 : 0}}/> : <ExpandMoreOutlined style={{opacity: open ? 1 : 0}}/>}
              </ListItemButton>
              <Collapse in={openForm} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {routes.map((route, index) =>
                      (!route.permission ||
                        permissions.includes(route.permission)) && (
                        <ListItem key={route.path} disablePadding >
                          <ListItemButton
                            sx={{
                              height: 40,
                              borderRadius: "8px",
                              "&:hover": {
                                backgroundColor: "#3f51b5", // Replace with your desired hover color
                                color: "white", // Optional: change text color on hover
                              },
                            }}
                            onClick={() => navigate(route.path)}
                          >
                            <ListItemIcon
                              sx={{
                                color: "black",
                              }}
                            >
                              {route.icon}
                            </ListItemIcon>
                            <ListItemText primary={route.label} sx={{ opacity: open ? 1 : 0 }}/>
                          </ListItemButton>
                        </ListItem>
                      )
                  )}
                </List>
              </Collapse>
            </List>

            {/* added for managers List*/}
            <List>
              {role === "manager" && (
                <>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&:hover": {
                        backgroundColor: "#3f51b5", // Replace with your desired hover color
                        color: "white", // Optional: change text color on hover
                      },
                    }}
                    onClick={handleClickManager}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippet style={{ color: "#ffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Report's"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {openManager ? <ExpandLess style={{opacity: open ? 1 : 0}}/> : <ExpandMoreOutlined style={{opacity: open ? 1 : 0}}/>}
                  </ListItemButton>
                  <Collapse in={openManager} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {managerRoutes.map((route, index) => (
                        <ListItem key={route.path} disablePadding>
                          <ListItemButton
                            sx={{
                              height: 40,
                              borderRadius: "8px",
                              "&:hover": {
                                backgroundColor: "#3f51b5", // Replace with your desired hover color
                                color: "white", // Optional: change text color on hover
                              },
                            }}
                            onClick={() => navigate(route.path)}
                          >
                            <ListItemIcon sx={{ color: "black" }}>
                              {route.icon}
                            </ListItemIcon>
                            <ListItemText primary={route.label} sx={{ opacity: open ? 1 : 0 }}/>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </List>

            {/* added for audit */}
            <List>
              <>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "#3f51b5", // Replace with your desired hover color
                      color: "white", // Optional: change text color on hover
                    },
                  }}
                  onClick={handleClickAudit}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Task style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Audit"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </>
            </List>
          </Box>
          {/* Logout and Copyright Section */}
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "#ff6700",
                color: "#fff",
                minWidth: open ? "auto" : "40px",
                p: open ? "auto" : "6px",
              }}
            >
              {open ? "Logout" : "X"}
            </Button>
            {open && (
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="body2" component="h6" color="#fff">
                  &copy; {new Date().getFullYear()} {company_name}. All rights
                  reserved.
                </Typography>
              </Box>
            )}
          </Box>
        </CustomDrawer>
        <Toolbar />
      </Box>
    </ThemeProvider>
  );
}
