import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Button, Box, CircularProgress, Collapse, IconButton
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ImGift } from 'react-icons/im';

const DetailedDataTable = ({ companyName, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailedData();
  }, [companyName, startDate, endDate, dataType]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return 'Invalid Date';
  };

  const fetchDetailedData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const nearMissReportsCollection = db
      .collection('company_data')
      .doc(companyName)
      .collection(companyName)
      .doc('for_user')
      .collection('accident_reports');

    const querySnapshot = await nearMissReportsCollection.get();

    const detailedData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
     // const dateObj = data.dateOfFireIncident;
     const dateObj = data.dateOfReporting instanceof firebase.firestore.Timestamp 
     ? data.dateOfReporting.toDate() 
     : new Date(data.dateOfReporting);

     //console.log('...',data.DateOfInvestigation);

      if (dateObj >= startDate && dateObj <= endDate) {
        const isRelevant = (
          (dataType === 'reported') || (dataType === 'closed' && !data.isPending) || (dataType === 'timelyinvestigated') ||
          (dataType === 'investigated' && data.DateOfInvestigation) ||
          (dataType === 'actionPlanOpen' && data.actionPlans && data.actionPlans.some(plan => plan.status === 'Open')) ||
          (dataType === 'actionPlanClosed' && data.actionPlans && data.actionPlans.some(plan => plan.status === 'Close'))
        );

        if (isRelevant) {
          const actionPlans = data.actionPlans || [];
          const baseData = {
            caseNumber: data.caseNumber,
            dateOfReporting: formatDate(dateObj.toLocaleDateString()),
            injuryCategory: data.injuryCategory,
            areaName: data.areaName,
            reportingPersonName: data.reportingPersonName,
            employeeId: data.employeeId,
            reportingPersonDesignation: data.reportingPersonDesignation,
            //dateOfReporting: formatDate(data.dateOfReporting),
            affectedWorkmenName: data.affectedWorkmenName,
            affectedWorkmanEmploymentType: data.affectedWorkmanEmploymentType,
            contractorName: data.contractorName,
            affectedWorkmenEmployeeId: data.affectedWorkmenEmployeeId,
            manpowerAffected: data.manpowerAffected,
            gender: data.gender,
            dateOfJoining: formatDate(data.dateOfJoining),
            bodyPartAffected: data.bodyPartAffected,
            typeOfAccident: data.typeOfAccident,
            descriptionOfInjury: data.descriptionOfInjury,
            status: data.isPending ? 'Open' : 'Closed',
            dateOfClosing: formatDate(data.dateOfClosing),
           // investigationTeam: data.investigationTeam,
            rootCause: data.rootCause,
            DateOfInvestigation: data.DateOfInvestigation ? formatDate(data.DateOfInvestigation) : '----',
            InvestigationTeam: data.InvestigationTeam ? data.InvestigationTeam : '---',
            actionPlans: actionPlans.map(plan => ({
              ApDescription: plan.ApDescription,
              ResponsiblePerson: plan.ResponsiblePerson,
              TargetDate: formatDate(plan.TargetDate),
              TypeOfActionPlan: plan.TypeOfActionPlan,
              status: plan.status
            })),
            actionPlanStatus: actionPlans.map(plan => plan.status).join(', ')

          };

          detailedData.push(baseData);
        }
      }
    });
    //console.log('detailedData: ', detailedData);

    setData(detailedData);
    setLoading(false);
  };

  const exportToExcel = () => {
    const flattenedData = data.flatMap(row => {
      if (row.actionPlans.length === 0) {
        return [{
          ...row,
          actionPlanDescription: 'N/A',
          actionPlanResponsiblePerson: 'N/A',
          actionPlanTargetDate: 'N/A',
          actionPlanType: 'N/A',
          actionPlanStatus: 'N/A'
        }];
      }
      return row.actionPlans.map(plan => ({
        ...row,
        actionPlanDescription: plan.ApDescription,
        actionPlanResponsiblePerson: plan.ResponsiblePerson,
        actionPlanTargetDate: plan.TargetDate,
        actionPlanType: plan.TypeOfActionPlan,
        actionPlanStatus: plan.status
      }));
    });

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(workbook, `${dataType}_detailed_data.xlsx`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detailed Data for {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '1rem' }}>
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Case Number</TableCell>
              <TableCell>Date </TableCell>
              <TableCell>Injury Catagory</TableCell>
              <TableCell>Area name</TableCell>
              <TableCell>Reporting person name</TableCell>
              <TableCell>Employee ID</TableCell>
              <TableCell>Reporting person designation</TableCell>
              <TableCell>Affected workman name</TableCell>
              <TableCell>Affected workman employement type</TableCell>
              <TableCell>Contractor Name</TableCell>
              <TableCell>Affected workman employee id</TableCell>
              <TableCell>Manpower affected</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Date of joining</TableCell>
              <TableCell>Body part affected</TableCell>
              <TableCell>Type of accident</TableCell>
              <TableCell>Description of injury</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date of closing</TableCell>
              <TableCell>Investigation Team</TableCell>
              <TableCell>Root cause</TableCell>
              <TableCell>Date of Investigation</TableCell>  
              <TableCell>Status of action plans</TableCell>            
            </TableRow>
          </TableHead>
        
          <TableBody>
            {
                
                data.map((row, index) => (
              <Row key={index} row={row} />
            ))
            
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.caseNumber}</TableCell>
        <TableCell>{row.dateOfReporting}</TableCell>
        <TableCell>{row.injuryCategory}</TableCell>
        <TableCell>{row.areaName}</TableCell>
        <TableCell>{row.reportingPersonName}</TableCell>
        <TableCell>{row.employeeId}</TableCell>
        <TableCell>{row.reportingPersonDesignation}</TableCell>
        <TableCell>{row.affectedWorkmenName}</TableCell>
        <TableCell>{row.affectedWorkmanEmploymentType}</TableCell>
        <TableCell>{row.contractorName}</TableCell>
        <TableCell>{row.affectedWorkmenEmployeeId}</TableCell>
        <TableCell>{row.manpowerAffected}</TableCell>
        <TableCell>{row.gender}</TableCell>
        <TableCell>{row.dateOfJoining}</TableCell>
        <TableCell>{row.bodyPartAffected}</TableCell>
        <TableCell>{row.typeOfAccident}</TableCell>
        <TableCell>{row.descriptionOfInjury}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.dateOfClosing}</TableCell>
        <TableCell>{row.InvestigationTeam}</TableCell>
        <TableCell>{row.rootCause}</TableCell>
        <TableCell>{row.DateOfInvestigation}</TableCell>
        <TableCell>{row.actionPlanStatus}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Action Plans
              </Typography>
              <Table size="small" aria-label="action plans">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Responsible Person</TableCell>
                    <TableCell>Target Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.actionPlans.map((plan, index) => (
                    <TableRow key={index}>
                      <TableCell>{plan.ApDescription}</TableCell>
                      <TableCell>{plan.ResponsiblePerson}</TableCell>
                      <TableCell>{plan.TargetDate}</TableCell>
                      <TableCell>{plan.TypeOfActionPlan}</TableCell>
                      <TableCell>{plan.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DetailedDataTable;