// firebase/firebaseConfig.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB46qrGqmON-5jKNcKin2XVEu3_N9UQwVM",
  authDomain: "safetymanager-b3d02.firebaseapp.com",
  projectId: "safetymanager-b3d02",
  storageBucket: "safetymanager-b3d02.appspot.com",
  messagingSenderId: "93465309838",
  appId: "1:93465309838:web:fc9fd67aa93f2433ff0fdf",
  measurementId: "G-PSSQYLRWEQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export firebase to use it in other parts of your application
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
