export const auditSections = [
  {
    id :1,
    title: "HEALTH AND SAFETY POLICY",
    questions: [
      {
        id: 1,
        question: "Does the organization have a health and safety policy?",
        type: "radio",
        options: ["Yes", "No", "Not Applicable"],
      },
      {
        id: 2,
        question: "Do you have any corporate safety policy?",
        type: "radio",
        options: ["Yes", "No", "Not Applicable"],
      },
      {
        id: 3,
        question:"Who has signed the health safety policy? (indicate his position)",
        type: "text",
      },
      {
        id: 4,
        question:"Whether it is prepared as per guidelines of the statutory provisions?",
        type: "radio",
        options: ["Yes", "No", "Not Applicable"],
      },
      {
        id: 5,
        question: "When was the safety policy declared and adopted?",
        type: "text",
      },
      {
        id: 6,
        question: "How many times has it been updated till now?",
        type: "text",
      },
      {
        id: 7,
        question: "Whether the policy is made know to all ?",
        type: "text",
      },
      {
        id: 8,
        question:"Whether the safety policy was scrutinized by outside expert agency ?",
        type: "text",
      },
      {
        id: 9,
        question: "What was the last date of update ?",
        type: "text",
      },
      {
        id: 10,
        question: "Does it find a place in the annual report ?",
        type: "text",
      },
    ],
  },
  { id :2,
    title: "SAFETY AND HEALTH ORGANIZATION",
    questions: [
      {
        id: 11,
        question: "Does the factory have a safety department?",
        type: "radio",
        options: ["Yes", "No","Not Applicable"],
        subQuestions: [
          { id: 11.1, question: "Name of Head of Safety Department" },
          { id: 11.2, question: "Designation" },
          { id: 11.3, question: "Qualification" },
          { id: 11.4, question: "Experience" },
          { id: 11.5, question: "Status" },
        ],
      },
      {
        id: 12,
        question:"What is the strength of the safety department including safety officers and staff?",
        type: "text",
      },
      {
        id: 13,
        question:"Does the head of safety department/ safety officer report to the chief executive ?",
        type: "text",
      },
      {
        id: 14,
        question:"How often are the safety officers retrained in the latest techniques of total safety management ? What is the frequency of retraining?",
        type: "text",
      },
      {
        id: 15,
        question:"What additional duties the safety officer is required to do?",
        type: "text",
      },
      {
        id: 16,
        question:"What is the power of safety officer vis-a-vis unsafe condition or unsafe act?",
        type: "text",
      },
      {
        id: 17,
        question: "Does the factory have safety committee(s)?",
        type: "radio",
        options: ["Yes", "No", "Not applicable"],
      },
      {
        id: 18,
        question: "How often are the meetings of safety committee(s) held?",
        type: "text",
      },
      {
        id: 19,
        question:
          "Is the tenure of the safety committee(s) as per the statute?",
        type: "text",
      },
      {
        id: 20,
        question:
          "How are the members of safety committee (s) selected? (elected/nominated)",
        type: "text",
      },
      {
        id: 21,
        question: "How often are the meetings of safety committee(s) held?",
        type: "text",
      },
      {
        id: 22,
        question:
          "What are the subjects? Are the problems discussed in the meetings? ",
        type: "text",
      },
      {
        id: 23,
        question:
          "How are the recommendations of the committee(s) implemented?",
        type: "text",
      },
      {
        id: 24,
        question:
          "Are the minutes of the safety committee(s) meetings circulated among the members ?",
        type: "text",
      },
      {
        id: 25,
        question:
          "Are the minutes forwarded to the trade union(s) and chief executive and occupier?",
        type: "text",
      },
      {
        id: 26,
        question:
          "How the management and trade union play their active roles in supporting and accepting the committee(s) recommendations?",
        type: "text",
      },
      {
        id: 27,
        question:
          "How are the safety committee(s) members apprised of the latest developments in safety, health and environment?",
        type: "text",
      },
      {
        id: 28,
        question: "What is the annual safety budget?",
        type: "text",
      },
      {
        id: 29,
        question:
    "How much percentage is this budget of the total turnover of the company?",
        type: "text",
      },
      {
        id: 30,
        question:"How much budget has utilized till date?",
        type: "text",
      },
      {
        id: 31,
        question:"Is the safety budget adequate ?",
        type: "text",
      },
      {
        id: 32,
        question:"How is the safety budget arrived at ?",
        type: "text",
      },
      {
        id: 33,
        question:
    "What is the pattern of expenditure for the last five years ?",
        type: "text",
      },
      {
        id: 34,
        question:
          "What are the approved sanctions for the expenditure in this budget ?",
        type: "text",
      },
      {
        id: 35,
        question:
          "Does this budget get reflected in the annual report of the company?",
        type: "text",
      },
    ],
  },
  {id :3,
    title: "ACCIDENT REPORTING",

    questions: [
      {
        id: 36,
        question:
          "Whether the accident data for the last three years for reportable and non-reportable accident available?",
        type: "text",
      },
      {
        id: 37,
        question:
          "Is there any system of classifying and analyzing the near-miss incidents and accidents? Give the details.",
        type: "text",
      },
      {
        id: 38,
        question:
          "Are all near-miss incidents and accidents reported and investigated?",
        type: "text",
      },
      {
        id: 39,
        question:
          "For how many years are the investigation reports retained?",
        type: "text",
      },
      {
        id: 40,
        question:
          "By whom the accident statistics and data are maintained ?",
        type: "text",
      },
      {
        id: 41,
        question: "How is the top management apprised of these data?",
        type: "text",
      },
      {
        id: 42,
        question:
          "Is the accident statistics effectively utilized? If yes, how?",
        type: "text",
      },
      {
        id: 43,
        question:
          "What nature of injuries occurred during the last three years?",
        type: "text",
      },
      {
        id: 44,
        question:
          "How do you ensure implementation of the recommendations to avoid the recurrence of the incidents and accidents?",
        type: "text",
      },
    ],
  },
  { id :4,
    title: "SAFETY INSPECTION",

    questions: [
      {
        id: 45,
        question:
          "What type of safety inspections are carried out and what are their frequency?",
        type: "text",
      },
      {
        id: 46,
        question: "Is there any system of internal inspection?",
        type: "text",
      },
      {
        id: 47,
        question: "Who does the inspections ?",
        type: "text",
      },
      {
        id: 48,
        question:
          "Are the check-list prepared for these inspections? (Specify item-wise, for example, housekeeping, fire protection, etc).",
        type: "text",
      },
      {
        id: 49,
        question: "To whom the recommendations are submitted ?",
        type: "text",
      },
    ],
  },
  {id :5,
    title: "SAFETY EDUCATION AND TRAINING",
    questions: [
      {
        id: 50,
        question: "Is there any training department ?",
        type: "text",
      },
      {
        id: 51,
        question: "Is there any programme of induction training?",
        type: "text",
      },
      {
        id: 52,
        question:
          "Mention the duration of induction training for each category of employees?",
        type: "text",
      },
      {
        id: 53,
        question:
    "Whether the assessment of the trainee worker is done or not?",
        type: "text",
      },
      {
        id: 54,
        question:
     " What infrastructural facilities with audio-visual support are available for training ?",
        type: "text",
      },
      {
        id: 55,
        question:
    "Do the programmes cover the plant safety rules, hazard communication and any other special safety rules or procedures unique to the plant or specific departments?",
        type: "text",
      },
      {
        id: 56,
        question:
          "Whether the training programmes are conducted in the local language?",
        type: "text",
      },
      {
        id: 57,
        question:
          "Whether visits to safety institutions/ organizations are arranged ?",
        type: "text",
      },
      {
        id: 58,
        question:
          "Are all the employees trained and what is the frequency of such training?",
        type: "text",
      },
      {
        id: 59,
        question:
          "Do the training programmes cover safety and health aspects and if so how much (in terms of number of sessions/hours)?",
        type: "text",
      },
      {
        id: 60,
        question:
          "Do the trained supervisors train their own employees in safety and health aspects ?Is the retraining performed whenever new.",
        type: "text",
      },
      {
        id: 61,
        question: "Hazards/process changes are followed/ introduced?",
        type: "text",
      },
      {
        id: 62,
        question:
          "How are the senior management personnel trained in safety and health?",
        type: "text",
      },
      {
        id: 63,
        question:
          "How many employees have been trained in safety and health in the last five years? Give break up with details.",
        type: "text",
      },
      {
        id: 64,
        question:
          "How many man-days/hours are used in training the employees?",
        type: "text",
      },
      {
        id: 65,
        question:
          "How do you ensure that the training is put to use by the employees trained in safety and health?",
        type: "text",
      },
      {
        id: 66,
        question:
          "What is the training plan for the next two years ? Give details.",
        type: "text",
      },
      {
        id: 67,
        question:
          "What documentation system has been established regarding safety and health training?",
        type: "text",
      },
      {
        id: 68,
        question:
          "Does the factory has safety suggestion schemes? Give details.",
        type: "text",
      },
      {
        id: 69,
        question:
          "Docs your factory participate in National Awards/Suggestion schemes?",
        type: "text",
      },
      {
        id: 70,
        question: "Has your factory been awarded during last five years?",
        type: "text",
      },
      {
        id: 71,
        question:
          "Are safety contests organized in the factory?Give details.",
        type: "text",
      },
      {
        id: 72,
        question:
          "What are the publications of your organization? Do they include information on safety and health subjects?",
        type: "text",
      },
      {
        id: 73,
        question:
          "Is the literature on safety and health made available to the employees?",
        type: "text",
      },
      {
        id: 74,
        question:
          "How is the safety and health publicized in your factory?",
        type: "radio",
        options: [
          "Bulletin boards?",
          "Post serious accidents?",
          "New letter?",
          "Others? Specify",
        ],
      },
      {
        id: 75,
        question:
          "Does the organization celebrate safety day/week or organize safety exhibition?",
        type: "text",
      },
      {
        id: 76,
        question: "When was the last safety day/week celebrated?",
        type: "text",
      },
    ],
  },
  {id :6,
    title: "FIRST AID",
    questions: [
      {
        id: 77,
        question:
          "Are adequate number of first aid boxes provided? Give location details ?",
        type: "text",
      },
      {
        id: 78,
        question: "78. Is there any first aid/ambulance room? ",
        type: "text",
      },
      {
        id: 79,
        question:
          "Are qualified/trained first aid's available in each shift?",
        type: "text",
      },
      {
        id: 80,
        question:
          "How many qualified/trained first aide's are available at each plant/department? ",
        type: "text",
      },
      {
        id: 81,
        question:
          "How many persons are trained/given refreshers training in first aid in a year?",
        type: "text",
      },
    ],
  },
  {id :7,
    title: "OCCUPATIONAL HEALTH CENTRE",
    questions: [
      {
        id: 82,
        question:
          "Whether occupational safety and health center is provided or not?",
        type: "text",
      },
      {
        id: "83",
        question:
          "Does it conform to the provisions of the existing legislation? ",
        type: "text",
      },
      {
        id: 84,
        question:
          "Are the Medical Attendants/Doctors available in each shift ?",
        type: "text",
      },
      {
        id: 85,
        question: "Is ambulance van available in each shift?",
        type: "text",
      },
      {
        id: 86,
        question: "Any liaison with the nearest hospital(s)? Give details.",
        type: "text",
      },
    ],
  },
  {id :8,
    title: "GENERAL WORKING CONDITION",
    questions: [
      {
        id: 87,
        question:
          "Are all the passages, floors and the stairways in good condition?",
        type: "text",
      },
      {
        id: 88,
        question: "Do you have the system to deal with the spillage? ",
        type: "text",
      },
      {
        id: 89,
        question:
          "Do you have sufficient disposable bins clearly marked and whether these are suitably located?",
        type: "text",
      },
      {
        id: 90,
        question: "Are drip trays positioned wherever necessary ?",
        type: "text",
      },
      {
        id: 91,
        question:
          "Do you have adequate localized extraction and scrubbing facilities for dust, fumes and gases ? Please specify.",
        type: "text",
      },
      {
        id: 92,
        question:
          "Whether walkaways are clearly marked and free from obstruction?",
        type: "text",
      },
      {
        id: 93,
        question:
          "Do you have any inter-departmental competition for good housekeeping?",
        type: "text",
      },
      {
        id: 94,
        question:
          "Has your organization elaborated good housekeeping practices and standards and made them known to the employees?",
        type: "text",
      },
      {
        id: 95,
        question:
          "Are there any working conditions which make the floors slippery? If so, what measures are taken to make them safe?",
        type: "text",
      },
      {
        id: 96,
        question:
          "Does the company have adequate measures to suppress polluting dust arising out from road transport?",
        type: "text",
      },
      {
        id: 97,
        question:
          "Are there any machines/processes generating noise? Specify.",
        type: "text",
      },
      {
        id: 98,
        question: "Was any noise study conducted ?  ",
        type: "text",
      },
      {
        id: 99,
        question: "Which are the areas having high-level noise ?",
        type: "text",
      },
      {
        id: 100,
        question:
          "Have engineering and administrative controls been implemented to reduce noise exposure below the permissible limits ?",
        type: "text",
      },
      {
        id: 101,
        question:
          "Is there a system of subjecting all those employees to periodic audiometric test who work in high level noise areas ?",
        type: "text",
      },
      {
        id: 102,
        question:
          "Whether the workers are made aware of the ill-effects of high noise.",
        type: "text",
      },
      {
        id: 103,
        question:
          "Whether any personal protective equipment along with ear muffs/plugs are provided and used.",
        type: "text",
      },
      {
        id: 104,
        question: "Whether natural ventilation is adequate or not?",
        type: "text",
      },
      {
        id: 105,
        question:
          "Whether dust/fumes/hot air is generated in the process? Give details.   ",
        type: "text",
      },
      {
        id: 106,
        question:
          "Is there any exhaust dilution ventilation system in any section of the plant?",
        type: "text",
      },
      {
        id: 107,
        question:
          "Whether any ventilation study has been carried out in the section(s) to check the record?",
        type: "text",
      },
      {
        id: 108,
        question:
          "Are periodic/preventive maintenance of ventilation system carried out and record is maintained?",
        type: "text",
      },
      {
        id: 109,
        question:
          "Does any ventilation system recirculate the exhausted air in work areas ? ",
        type: "text",
      },
      {
        id: 110,
        question: "Is the work environment assessed andmonitored?",
        type: "text",
      },
      {
        id: 111,
        question:
          "Whether personal protective equipment are given to workers exposed to dust/fumes and gases? Give details.",
        type: "text",
      },
      {
        id: 112,
        question:
          "Was any study carried out for the assessment of illumination level?",
        type: "text",
      },
      {
        id: 113,
        question:
          "Is there any system of periodical cleaning and replacing the lighting fittings/lamps in order to ensure that they give the intended illumination levels ?   ",
        type: "text",
      },
      {
        id: 114,
        question:
          "Are the workers subject to periodic optometry tests and records maintained? Give details.",
        type: "text",
      },
    ],
  },
 {id :10,
   title: "HAZARD IDENTIFICATION AND CONTROL",
   questions: [
     {
       id: 115,
       question: "Are all the hazardous areas identified?",
       type: "text",
     },
     {
       id: 116,
       question:
         "What are the types of hazards (physical noise, heat, etc, and chemical-fire, explosion, toxic release, etc)?",
       type: "text",
     },
     {
       id: 117,
       question:
         "What steps have been taken to prevent these hazards? Give details.",
       type: "text",
     },
     {
       id: 118,
       question:
         "Are there any safety inter-locks, alarms and trip system? Give details.",
       type: "text",
     },
     {
       id: 119,
       question:
         "Are these tested periodically? How often? Please specify.",
       type: "text",
     },
     {
       id: 120,
       question:
         "Are there any ambient monitoring devices with alarms for leakage of hazardous materials? Give details.",
       type: "text",
     },
     {
       id: 121,
       question:
         "Are safety audit or HAZOP or any other studies carried out and the recommendations implemented? Give details.",
       type: "text",
     },
     {
       id: 122,
       question:
         "What has been the major modification done in plant/process and has the approval of the competent authority concerned?",
       type: "text",
     },
     {
       id: 123,
       question:
         "What decision and monitoring equipment are available and used for checking the environmental conditions in and around the plant? Give details.",
       type: "text",
     },
   ],
 },
 {id :11,
   title: "TECHNICAL ASPECT",
   questions: [
     {
       id: 124,
       question:
         "Are written safe operating procedures available for all operations?",
       type: "text",
     },
     {
       id: 125,
       question:
         "Whether the written safe operating procedures displayed or made available and explained in the local language to the workers?",
       type: "text",
     },
     {
       id: 126,
       question:
         "Whether the safe operating procedures are prepared jointly by the plant and safety departments?",
       type: "text",
     },
     {
       id: 127,
       question:
         "What system is used to ensure that the existing safe operating procedures are updated? Give details.",
       type: "text",
     },
     {
       id: 128,
       question:
         "Have the workers been informed of the consequences of failure to observe the safe operating procedures?",
       type: "text",
     },
     {
       id: 129,
       question:
         "Are contractor workers educated and trained to observe safety at workplace?",
       type: "text",
     },
     {
       id: 130,
       question:
          "Whether contractor's workers are permitted on process/operations? Give details.",
         type: "text",
       },
     ],
   },
   {id :12,
     title: "WORK PERMIT SYSTEM",
     questions: [
       {
         id: 130,
         question:
           "What necessary type of work permits exists in your factory? Give details.",
         type: "text",
       },
       {
         id: 131,
       question: "What are the hazardous chemicals handled?",
         type: "text",
       },
       {
         id: 132,
         question:
           "Are the keys kept for individual locks which are used for electrical lock outs with the supervisor concerned?",
         type: "text",
       },
     ],
   },
   {id :13,
     title: "WASTE DISPOSAL SYSTEM",
     questions: [
       {
         id: 133,
         question:
           "Is identification done for various types of wastes? Give details.",
         type: "text",
       },
       {
         id: 134,
         question:
           "Are these quantities less than those specified by the hazardous wastes (Management & Handling Rules, 1989)?",
         type: "text",
       },
       {
         id: 135,
         question: "What are their disposal modes?",
         type: "text",
       },
       {
         id: 136,
         question:
           "What are the systems/measures adopted for controlling air/water/land pollution?",
         type: "text",
       },
       {
         id: 137,
         question:
         "What is the system of effluent treatment plant and whether it is approved by the competent authority?",
       type: "text",
     },
     {
       id: 138,
       question: "How are the treated effluent used?",
       type: "text",
     },
   ],
 },
 {id :14,
   title: "PERSONAL PROTECTIVE EQUIPMENT (PPE)",
   questions: [
     {
       id: 139,
       question:
         "Has a list of required PPE for each area/operation been developed and the required PPE is made available to the workers?",
       type: "text",
     },
     {
       id: 140,
       question:
         "Are the safety department and the workers consulted in the selection of PPE?",
       type: "text",
     },
     {
       id: 141,
       question: "Have the workers been trained in proper use of PPE?",
       type: "text",
     },
     {
       id: 142,
       question: "What is the system of replacement/issue of PPE?",
       type: "text",
     },
     {
         id: 143,
         question:
           "What are the arrangements for safe custody and storage of PPE provided to the workers?",
         type: "text",
       },
       {
         id: 144,
         question:
           "Are the contractor's workers provided with the required PPE? Who is responsible? Give details.",
         type: "text",
       },
       {
         id: 145,
         question: "Are the PPE conform to any standard? Give details.",
         type: "text",
       },
     ],
   },
   {id :15,
     title: "FIRE PROTECTION",
     questions: [
       {
         id: 146,
         question:
           "Give the details of PPE and also specify the responsibility for their inspection and maintenance?",
         type: "text",
       },
       {
         id: 147,
         question:
           "Indicate on a plant layout the location, number (Quantity), and types of portable fire extinguishers available.",
         type: "text",
       },
       {
         id: 148,
         question:
           "Are the fire fighting system and equipment approved, tested, and maintained as per relevant standards?",
         type: "text",
       },
       {
         id: 149,
         question:
           "What is the inspection and maintenance schedule of the above extinguishers? Who performs these functions?",
         type: "text",
       },
       {
         id: 150,
         question:
           "Which areas of the plant are covered by fire hydrants? Indicate the locations of the hydrant points and how the required pressure is maintained in the system and ensured.",
         type: "text",
       },
       {
         id: 151,
         question:
           "What is the capacity of the dedicated water reservoir for supply to the hydrants? What is the source of water?",
         type: "text",
       },
       {
         id: 152,
         question:
           "How is the power supply to the fire hydrant pump ensured?",
         type: "text",
       },
       {
         id: 153,
         question:
           "Are all personnel conversant with the fire prevention and protection measures? Give details.",
         type: "text",
       },
       {
         id: 154,
         question:
           "What percentage of plant personnel, staff, and officers have been trained in the use of portable fire extinguishers? Give details.",
         type: "text",
       },
       {
         id: 155,
         question:
           "Do you have fixed or automatic fire fighting installation(s) in any section of your plant?",
         type: "text",
       },
       {
         id: 156,
         question:
           "Are the fire alarms adequate and free from obstruction?",
         type: "text",
       },
       {
         id: 157,
         question: "Do you have a fire department? If yes, give details.",
         type: "text",
       },
       {
         id: 158,
         question:
           "What is the system for conducting mock drills? Give details.",
         type: "text",
       },
       {
         id: 159,
         question:
           "Do you have any mutual aid scheme with any of your neighbouring industry or any local organization(s)?",
         type: "text",
       },
       {
         id: 160,
         question:
           "Give details of the existing fire resistant walls and doors.",
         type: "text",
       },
       {
         id: 161,
         question:
           "Do you have any system of colour coding for all the pipelines for hazardous chemicals? Give details including marking of flow directions.",
         type: "text",
     },
     {
       id: 162,
       question:
         "Are there any safe containers for the movement of small quantities of hazardous chemicals? Give details.",
       type: "text",
     },
     {
       id: 163,
       question:
         "Are all self-closing fire doors in good condition and free from obstructions?",
       type: "text",
     },
     {
       id: 164,
       question:
         "How many major and minor incidents/fires were there in the factory during the last five years? Give department/plant-wise.",
       type: "text",
     },
     {
       id: 165,
       question:
         "Have all the fires/incidents been investigated and corrective actions taken? Give break up.",
       type: "text",
     },
   ],
 },
 {id :16,
   title: "EMERGENCY PREPAREDNESS",
   questions: [
     {
       id: 166,
       question:
         "Is there an on-site emergency plan for your factory? (Attach a copy of the plan)",
       type: "text",
     },
     {
       id: 167,
       question:
         "What is the frequency of conducting mock drills of the on-site emergency plan?",
       type: "text",
     },
     {
       id: 168,
       question:
         "What are the number and location of emergency control centres and assembly points?",
       type: "text",
     },
     {
       id: 169,
       question:
         "Whether the emergency team or key personnel are identified?",
       type: "text",
     },
     {
       id: 170,
       question:
         "Are suitable and adequate protective and rescue equipment available? How is the emergency rescue team trained to use these equipment?",
         type: "text",
       },
       {
         id: 171,
         question:
           "How is the emergency communication with local bodies and other organizations ensured? Give details.",
         type: "text",
       },
       {
         id: 172,
         question:
           "Is any alternate power source identified? Give details.",
         type: "text",
       },
       {
         id: 173,
         question:
           "What is the medical emergency response system? Give details.",
         type: "text",
       },
       {
         id: 174,
       question:
         "Are you a member of any MUTUAL-AID-SCHEME in your area? If so, give details.",
       type: "text",
     },
     {
       id: 175,
       question:
         "How many emergency alarm systems are available? Give details.",
       type: "text",
     },
   ],
 },
 {id :17,
   title: "PLANT LAYOUT AND AREA CLASSIFICATION",
   questions: [
     {
       id: 176,
       question:
         "What is the system of classification of hazardous zones in the plant for electrical installations? Please specify.",
       type: "text",
     },
     {
       id: 177,
       question:
         "Whether periodic inspection and preventive maintenance of electrical installations is done by a qualified person and record is maintained?",
       type: "text",
     },
     {
       id: 178,
       question:
         "Whether plant layout with area classification has been displayed at appropriate place(s)?",
       type: "text",
     },
     ],
   },
   {id :18,
     title: "STATIC ELECTRICITY",
     questions: [
       {
         id: 179,
         question:
           "Whether the process(s) and equipment generating and accumulating static charge have been identified? Give details.",
         type: "text",
       },
       {
         id: 180,
         question:
           "Whether all such equipment are properly bonded and earthed?",
         type: "text",
       },
       {
         id: 181,
         question:
           "How is electrical resistance for earthing circuits maintained? Are periodic inspections done and recorded?",
         type: "text",
       },
       {
         id: 182,
         question:
           " Are adequate earthing arrangements made at the terminal points where hazardous chemicals are handled through pipes?",
         type: "text",
       },
       {
         id: 183,
         question:
           "Are anti-static charge devices fitted wherever necessary?",
         type: "text",
       },
       {
         id: 184,
         question:
           "Whether these devices are periodically checked and maintained by a qualified person?",
         type: "text",
       },
     ],
   },
   {id :19,
     title: "PRESSURE VESSELS (FIRED AND UNFIRED)",
     questions: [
       {
         id: 185,
         question:
           "Give details of the plants, piping, and vessels which are operated at a pressure greater than atmospheric pressure.",
         type: "text",
       },
       {
         id: 186,
         question:
           "How is it ensured that the working pressure inside the pressure vessels/pressure plants will not exceed their maximum working pressure for which they are designed?",
         type: "text",
       },
       {
         id: 187,
         question:
         "What means of isolating the pressure vessels or means to prevent rise in pressure are installed?",
       type: "text",
     },
     {
       id: 188,
       question:
         "What standards/codes of practice are adopted for the design, fabrication, operation, and maintenance of the pressure vessels, and are records maintained?",
       type: "text",
     },
     {
         id: 189,
         question: "How are the pressure vessels tested? Give details.",
         type: "text",
       },
       {
         id: 190,
         question:
           "Is there any competent person for testing these pressure vessels? Give details.",
         type: "text",
       },
       {
         id: 191,
         question: "How are the recorded results verified?",
         type: "text",
       },
       {
         id: 192,
         question:
           "Give details of safety devices available for these pressure vessels.",
         type: "text",
       },
       {
         id: 193,
         question:
           "Whether a log book for pressure vessels and pressure plant has been maintained?",
         type: "text",
       },
     ],
   },
   {id :20,
     title: "NEW EQUIPMENT REVIEW",
     questions: [
       {
         id: 194,
         question:
           "What is the system for effecting any change in the existing plant, equipment, or process? Whether it is approved by the appropriate competent authority?",
       type: "text",
     },
     {
       id: 195,
       question:
         "Whether the P & I diagrams and other related documents are updated accordingly?",
       type: "text",
     },
   ],
 },
 {id :21,
   title: "LIFTING MACHINES & TACKLE",
   questions: [
     {
       id: 196,
       question:
         "Whether all the lifting machines are marked with their S.W.L.?",
       type: "text",
     },
     {
       id: 197,
       question:
         "Are all the examinations and tests documented in the prescribed form?",
       type: "text",
     },
     {
       id: 198,
       question:
         "Are all the examinations and tests carried out and certified by competent person(s)? Give details.",
       type: "text",
     },
     {
       id: 199,
       question:
         "Are adequate lifting tackles provided at all the places where it is required? Give details.",
       type: "text",
     },
     {
       id: 200,
       question:
         "Are the trained operators engaged for operating the equipment? Give details.",
         type: "text",
       },
       {
         id: 201,
         question: "What is the system of training such operators?",
         type: "text",
       },
       {
         id: 202,
         question:
           "Are all the lifting machines and tackles maintained in good condition and record maintained?",
         type: "text",
       },
     ],
   },
   {id :22,
     title: "MOBILE EQUIPMENT VEHICULAR TRAFFIC AND",
     questions: [
       {
         id: 203,
         question: "Are all the mobile equipment in good condition?",
         type: "text",
       },
       {
         id: 204,
         question: "Are trained drivers engaged for fork-lift trucks?",
         type: "text",
       },
       {
         id: 205,
         question:
           "What is the system for identifying these drivers from other drivers?",
         type: "text",
       },
       {
         id: 206,
         question:
           "What system do you adopt to assess their standard of driving as poor/fair/satisfactory/good?",
         type: "text",
       },
       {
         id: 207,
         question: "Are there adequate number of warning signs/signals?",
         type: "text",
       },
       {
         id: 208,
         question:
           "Are the hazards associated with transportation within the plant identified and safety measures taken? Give details.",
         type: "text",
       },
     ],
   },
   {id :23,
     title: "ACCESS",
     questions: [
       {
         id: 209,
         question:
           "Is adequate safe access provided to all places where workers need to work?",
         type: "text",
       },
       {
         id: 210,
         question: "Are all such access in good condition?",
         type: "text",
     },
     {
       id: 211,
       question: "Are portable access platforms necessary? If yes:",
       type: "text",
       sub_questions: [
        {
          id: 211.1,
          question: "i) Are these sufficient?",
          type: "text",
        },
         {
           id: 211.2,
           question: "ii) Are these regularly inspected?",
           type: "text",
         },
         {
           id: 211.3,
           question: "iii) Are these readily available?",
           type: "text",
         },
         {
           id: 211.4,
           question: "iv) Are these provided with toe-boards and railings?",
           type: "text",
         },
       ],
     },
     {
       id: 212,
       question: "Oiling and greasing points:",
       type: "text",
       sub_questions: [
         {
           id: 212.1,
           question:
             "i) Are these located and extended to safe place clear of moving parts?",
           type: "text",
         },
         {
           id: 212.2,
           question: "ii) Are these easily accessible?",
           type: "text",
         },
         {
           id: 212.3,
           question: "iii) Are these liable to drip into walkways?",
           type: "text",
         },
         {
           id: 212.4,
           question:
             "iv) Whether such workers were trained and whether they are provided with fit-tight clothings and register is maintained?",
           type: "text",
         },
       ],
     },
     {
       id: 213,
       question:
           "Are all drain covers in good condition and fitting flush?",
         type: "text",
       },
     ],
   },
   {id :24,
     title: "MATERIAL HANDLING",
     questions: [
       {
         id: 214,
         question: "Are there adequate storage facilities available?",
         type: "text",
       },
       {
         id: 215,
         question: "Are these areas clearly defined?",
         type: "text",
       },
       {
         id: 216,
         question: "Are all racks and steel ages in good condition?",
         type: "text",
       },
       {
         id: 217,
         question: "Have you adequate equipment for handling materials?",
         type: "text",
       },
       {
         id: 218,
         question:
           "Do the workers know the hazards associated with manual material handling?",
         type: "text",
       },
       {
         id: 219,
         question:
           "Where manual handling is necessary, are the workers trained?",
         type: "text",
       },
       {
         id: 220,
         question: "Do they practice this?",
         type: "text",
       },
       {
         id: 221,
         question:
           "Do workers follow safe procedures for storage of materials?",
         type: "text",
       },
       {
         id: 222,
         question: "Is the system consistently applied?",
         type: "text",
       },
       {
         id: 223,
         question: "Whether contractor workers are trained in safety?",
         type: "text",
       },
       {
         id: 224,
         question:
           "What is the system for handling over plant to the maintenance department and receiving back?",
         type: "text",
       },
       {
         id: 225,
         question:
           "What is the system for the preventive and predictive maintenance and how do you ensure its effectiveness? Give details.",
         type: "text",
       },
     ],
   },
   {id :25,
     title: "TANK STORAGE VESSEL AREA",
     questions: [
       {
         id: 226,
         question: "Whether it is a pressure vessel or not?",
         type: "text",
       },
       {
         id: 227,
         question:
           "Give storage vessels designation (exceeding threshold quantities specified in MSIHC, Rules 1989).",
         type: "text",
       },
       {
         id: 228,
         question: "Give the names of storage materials in each of them.",
         type: "text",
       },
       {
         id: 229,
         question: "What are the vessel sizes (capacity in tonnes)?",
         type: "text",
       },
       {
         id: 230,
         question:
           "What is the material of construction for each vessel and what standards are followed in designing/fabricating the vessel?",
         type: "text",
       },
       {
         id: 231,
         question: "What are the operating pressure and temperature?",
         type: "text",
       },
       {
         id: 232,
         question:
           "What are the vessels' locations? (Please indicate on-site plan or plot plan)",
         type: "text",
       },
       {
         id: 233,
         question:
           "Indicate whether vessels are above ground or underground.",
         type: "text",
       },
       {
         id: 234,
         question:
           "If any of the tanks store flammable material, whether electrical installations are flameproof or not?",
       type: "text",
     },
     {
       id: 235,
       question: "Are these storage vessels bunded/dyked?",
       type: "text",
     },
     {
       id: 236,
       question: "If yes, what is the capacity of the bunds/dykes?",
       type: "text",
     },
     {
       id: 237,
       question:
         "Are the vessels properly bonded and earthed and are they periodically checked and recorded?",
       type: "text",
     },
     {
       id: 238,
       question: "How are vessels isolated in the event of a mishap?",
       type: "text",
     },
     {
       id: 239,
       question:
         "Are the vessels fitted with remotely controlled isolation valves?",
       type: "text",
     },
     {
       id: 240,
       question:
           "Are vessels provided with emergency vent relief valve, bursting disc, level indicator, pressure gauge, overflow line?",
         type: "text",
       },
       {
         id: 241,
         question: "Where do such vents discharge?",
         type: "text",
       },
       {
         id: 242,
         question:
           "Are the vessels provided with alarms for high level, high temperature, and high pressure?",
         type: "text",
       },
       {
         id: 243,
         question:
           "Are standby empty tanks provided for emptying in case of emergencies?",
         type: "text",
       },
       {
         id: 244,
         question:
           "What provisions are made for fire fighting/tackling emergency situations around the storage vessels?",
         type: "text",
     },
     {
       id: 245,
       question:
         "Has any consequence analysis been carried out for these vessels? (If yes, give details)",
       type: "text",
     },
     {
       id: 246,
       question:
         "What periodical testings are carried out on the vessels to find out the integrity of the vessels?",
       type: "text",
     },
     {
       id: 247,
       question:
         "Whether these tests are certified by the approved competent persons?",
       type: "text",
     },
     {
       id: 248,
       question:
         "Whether log sheets are filled up on a daily basis for recording the parameters of these vessels?",
       type: "text",
     },
   ],
 },
 {id :26,
   title: "ON-SITE GAS CYLINDERS STORAGE AREA",
   questions: [
     {
       id: 249,
       question:
         "What are the various gas cylinders used in the plant? (Give details).",
       type: "text",
     },
     {
       id: 250,
       question: "What are the storage facilities?",
       type: "text",
     },
     {
       id: 251,
       question:
         "What measures are taken for combating any emergency in the cylinders storage area?",
       type: "text",
     },
     {
       id: 252,
       question:
         "Are valid licenses available for storing all these cylinders?",
       type: "text",
     },
     {
       id: 253,
       question:
         "Whether integrity test certificates are obtained from the suppliers of the cylinders?",
         type: "text",
       },
     ],
   },
   {id :27,
     title: "COMMUNICATION SYSTEM ADOPTED IN PLANT",
     questions: [
       {
         id: 254,
         question:
           "Are public address systems available in all plant areas?",
         type: "text",
       },
       {
         id: 255,
         question:
           "Are public address systems provided with uninterrupted power supply?",
         type: "text",
       },
       {
         id: 256,
         question:
           "Whether public address systems are checked periodically for their proper functioning?",
         type: "text",
       },
       {
         id: 257,
         question: "Is there any hot line provided to the fire station?",
         type: "text",
       },
       {
         id: 258,
         question:
           "What is the means of communicating emergency in the plant?",
         type: "text",
       },
     ],
   },
   {id :28,
     title: "TRANSPORTATION",
     questions: [
       {
         id: 259,
         question:
           "What potentially hazardous materials are transported to or from the site (including wastes)?",
         type: "text",
       },
       {
         id: 260,
         question: "What modes of transport are used:",
         type: "radio",
         options: ["Road", "Rail", "Pipelines"],
     },
     {
       id: 261,
       question:
         "Does the company employ licensed vehicles of its own or outside sources?",
       type: "text",
     },
     {
       id: 262,
       question:
         "Are the loading/unloading procedures on-site and safety precautions displayed?",
       type: "text",
     },
     {
       id: 263,
       question:
         "Are loaded tankers or trucks parked in a specific area on-site?",
       type: "text",
     },
     {
       id: 264,
       question:
         "Do all truck and tanker drivers carry a TREM card or instruction booklet?",
       type: "text",
     },
     {
       id: 265,
       question:
         "Do all truck and tanker drivers receive training in handling emergencies during transport?",
       type: "text",
     },
     {
       id: 266,
       question: "What hazardous materials are transported by rail?",
       type: "text",
     },
     {
       id: 267,
       question: "Does the company have a direct siding on site?",
       type: "text",
     },
     {
       id: 268,
       question: "Are tankers or other wagons used in transportation?",
       type: "text",
     },
   ],
 },
 {id :29,
   title: "PIPELINES",
   questions: [
     {
       id: 269,
       question:
         "What materials are transported to and from the site by pipeline?",
       type: "text",
     },
     {
       id: 270,
       question: "Are the pipelines underground or overground?",
       type: "text",
     },
     {
       id: 271,
       question:
         "Are corrosion protection measures employed in pipelines?",
       type: "text",
     },
     {
       id: 272,
       question: "Whether intermediate booster pumps are used?",
       type: "text",
     },
     {
       id: 273,
       question:
         "What is the maximum, minimum, and average transfer rates?",
       type: "text",
     },
     {
       id: 274,
       question: "Are the pipelines extended in the public domain?",
       type: "text",
     },
     {
       id: 275,
       question: "Are the pipelines dedicated for each type of chemical?",
       type: "text",
     },
     {
       id: 276,
       question:
         "Are the pipelines fitted with safety equipment such as leak detectors, automatic shut-off valves, etc.?",
       type: "text",
     },
     {
       id: 277,
       question:
         "What is the frequency and method of testing of the pipeline?",
       type: "text",
     },
     {
       id: 278,
       question:
         "Is there a written procedure for tackling leakages in pipelines?",
       type: "text",
     },
   ],
 },
];
