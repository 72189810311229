import React, { useState } from "react";
import { Box, TextField, Button, Typography, Grid, Alert } from "@mui/material";
import MapComponent from "./MapComponent";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    Cname: "",
    Dname: "",
  });

  const [result, setResult] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    setIsSubmitted(false);

    const formData = new FormData(event.target);

    // Your Web3Forms access key
    formData.append("access_key", "5aa7a785-688a-413f-80fc-7717f74de2e5");

    // Add the CC field for sending to multiple email addresses
    formData.append(
      "cc",
      "vikrambaghel917@gmail.com, baghelvikram917@gmail.com, vikram.tetrahedron@gmail.com"
    );

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      setIsSubmitted(true);
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              padding: 2,
              border: "1px solid #28CC9E",
              borderRadius: "8px",
              boxShadow: 2,
              width: 400,
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Get in Touch
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Please fill in all fields
            </Typography>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              type="email"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              type="tel"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              label="Organization"
              name="Cname"
              value={formData.Cname}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              label="Designation"
              name="Dname"
              value={formData.Dname}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginTop: 2,
                backgroundColor: "#28CC9E",
                color: "white",
                padding: "12px 20px",
                "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
              }}
            >
              Submit
            </Button>

            {isSubmitted && (
              <Alert severity="success" sx={{ marginTop: 2 }}>
                Form Submitted Successfully!
              </Alert>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box sx={{ width: 600 }}>
            <MapComponent
              latitude={28.6181466}
              longitude={77.386043}
              zoom={13}
            />
            <Box sx={{ my: 4 }}>
              <Typography variant="body1" color="textSecondary" paragraph>
                If you have any questions or would like to learn more about us,
                feel free to reach out. We would love to hear from you!
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
