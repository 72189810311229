import React, { useRef } from "react";
import Navbar from "./Navbar";
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Footer from "./Footer";
import ContactForm from "../../componants/ContactForm";
import Pricing from "../../componants/Pricing";
import AboutUs from "../../componants/AboutUs";
import SlidingCarousel from "../../componants/SlidingCarousel";
import Statistics from "../../componants/Statistics";
import SafetyAppFeatures from "../../componants/SafetyAppFeatures";
import FreeTrialBanner from "../../componants/FreeTrialBanner";
import FeatureSection from "../../componants/FeatureSection";
import DownloadBanners from "../../componants/DownloadBanners";
import FAQ from "../../componants/FaqSection";
import   theme  from "../../theme";
import CardStack from "../../componants/CardStack";
 
const metaRobots = document.createElement('meta');
metaRobots.name = 'robots';
metaRobots.content = 'noindex, nofollow';
document.head.appendChild(metaRobots);
const Home = () => {
  const homeRef = useRef(null);
  const pricingRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const scrollToSection = (section) => {
    switch (section) {
      case "home":
        homeRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "pricing":
        pricingRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {/* NAVBAR */}
        <Navbar onScrollTo={scrollToSection} />

        {/* HOME SECTION */}
        <Box
          ref={homeRef}
          sx={{
            width: "100%",
            height: 700,
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "space-between" : "space-evenly",
            backgroundColor: "#DCFFCC",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              padding: isSmallScreen ? 0 : 10,
              pt: 10,
            }}
          >
            <Typography variant={isSmallScreen ? "h5" : "h1"}>
            Safety Incident Management Software in India
            </Typography>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              Use Safety Incident Management Software to centralize the collection and documentation of all relevant details — including the "what, when, where, and why" — for any incidents that occur within your organization.

            </Typography>
            <Box
              sx={{
                width: isSmallScreen ? "100%" : "80%",
                height: "auto",
                display: "flex",
                justifyContent: "space-between",
                my: 4,
              }}
            >
              <Button
                variant="contained"
                onClick={() => scrollToSection("contact")}
                sx={{
                  width: isSmallScreen ? "40%" : "40%",
                  backgroundColor: "#28CC9E",
                  color: "white",
                  "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
                }}
              >
                <Typography sx={{ fontSize: isSmallScreen ? "10px" : "h6" }}>
                  Take Demo
                </Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => scrollToSection("pricing")}
                sx={{
                  width: isSmallScreen ? "40%" : "40%",
                  backgroundColor: "#28CC9E",
                  color: "white",
                  "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
                }}
              >
                <Typography sx={{ fontSize: isSmallScreen ? "10px" : "h6" }}>
                  Get Started
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "80%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",

              p: 10,
            }}
          >
           <Box sx={{width:'100%',height:'100%',pt:10}}>
              <CardStack/>
           </Box>
            {/* <img src="/Images/Tablet.png" alt="" width={"100%"} /> */}
            {/* <Box width={"100%"} textAlign={"left"}>
        <Typography>
          Our Safety Web App is designed to enhance workplace safety by offering
          a comprehensive platform for reporting and monitoring safety
          incidents. The app features a user-friendly dashboard where employees
          can submit Safety Observation Reports, as well as reports for Near
          Misses, Unsafe Acts, Accidents, and Fire Incidents.
        </Typography>
      </Box>  */}
          </Box>
        </Box>
        {/* <Box>
        <Typography mt={4} pl={10}>
          Our Safety Web App is designed to enhance workplace safety by offering
          a comprehensive platform for reporting and monitoring safety
          incidents. The app features a user-friendly dashboard where employees
          can submit Safety Observation Reports, as well as reports for Near
          Misses, Unsafe Acts, Accidents, and Fire Incidents.
        </Typography>
      </Box> */}

        {/* Our Client's Section */}
        <Box
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            padding: 6,
          }}
        >
          {isSmallScreen ? (
            <h6>Trusted by leading componies</h6>
          ) : (
            <h2>Trusted by leading componies</h2>
          )}
          <SlidingCarousel />
        </Box>

        {/* OVERVIEW SECTION */}
        <Box
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            padding: 2,
            alignItems: "center",
          }}
        >
          <h1>Save hours of reporting,<br/> cut costs and minimize re-work</h1>
          {/* <FeatureSection
          image="/Images/image1.png"
          title="Dashboard"
          description="Interface for a management system labeled 'TMS.' It features a blue sidebar with navigation icons and a main content area displaying various reports, such as 'Near Miss Report,' 'Accident Report,' and 'Fire Incident Report.' Each report is presented in a card format with a 'View Chart' button, allowing users to access detailed visual data. The design is clean and functional, with a focus on easy navigation and quick access to important information."
          imageFirst
        /> */}

          <FeatureSection
            image="/Images/Forms.png"
            title="Effortless Work Incident Reporting and Management with Kavach"
            description="With Kavach, you can streamline your work incident report process by instantly reporting and managing incidents and near-misses. Our platform eliminates the need for paperwork, allowing you to handle safety issues efficiently and accurately. Experience a seamless approach to work incident reporting and management, ensuring prompt resolution and enhanced safety in your workplace."
            imageFirst={false}
            backgroundColor="#DCFFCC"
          />
          <FeatureSection
            image="/Images/MultiCharts.png"
            title="Comprehensive Safety Incident Reporting and Analysis Dashboard"
            description="This safety management software provides comprehensive incident reports, including near miss reports, unsafe act reports, fire incident reports, and PPE grievances. The user interface features detailed graphical analyses, such as monthly and area-specific comparisons of reported vs. investigated incidents and action plans. These visual tools help organizations monitor and improve workplace safety by identifying trends and addressing issues promptly."
            imageFirst
            backgroundColor="white"
          />
          <FeatureSection
            image="/Images/Tablet.png"
            title="Effortless Safety Observation and Incident Reporting System"
            description="This software system simplifies managing your daily safety observation report doc and incident reports. It helps organizations keep thorough safety records, ensuring precise tracking and management of workplace accidents to enhance safety and compliance. Users can easily download their data in Excel or PDF formats, streamlining reporting and record-keeping processes. Experience improved safety management and compliance with effortless documentation and reporting capabilities.
"
            imageFirst={false}
            backgroundColor="#DCFFCC"
          />
        </Box>

        {/* KEY FEATURES */}
        <Box
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            padding: 2,
          }}
        >
          {isSmallScreen ? <h6>Key Features</h6> : <h1>Key Features</h1>}
          <SafetyAppFeatures />
        </Box>
        {/* STATISTIC SECTION */}
        <Box sx={{ width: "100%", height: "auto", backgroundColor: "#DCFFCC" }}>
          <Statistics />
        </Box>

        {/* PRICING */}
        <Box
          ref={pricingRef}
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            padding: 2,
          }}
        >
          {isSmallScreen ? <h6>Pricing</h6> : <h1>Pricing</h1>}
          <Pricing />
        </Box>

        {/* FREE TRIAL BANNER */}
        <Box
          sx={{
            height: "auto",
            width: "100%",
            mt: 4,
            backgroundColor: "#DCFFCC",
          }}
        >
          <FreeTrialBanner onScrollTo={scrollToSection} />
        </Box>

        {/* APP DOWNLOAD BANNER */}
        <Box ref={contactRef} sx={{ height: "auto", width: "100%" }}>
          <DownloadBanners onScrollTo={scrollToSection} />
        </Box>

        {/* Frequently Ask Question And Answers */}
        <Box
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            mt: 4,
            p: 2,
            backgroundColor: "#DCFFCC",
          }}
        >
          {isSmallScreen ? (
            <h6>Frequently Asked Questions</h6>
          ) : (
            <h2>Frequently Asked Questions</h2>
          )}
          <FAQ />
        </Box>

        {/* ABOUT US */}
        <Box
          ref={aboutRef}
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            mt: 4,
          }}
        >
          {/* {isSmallScreen ? <h6>About</h6> :  <h2>About</h2> } */}
          <AboutUs />
        </Box>

        {/* CONTACT FORM */}
        <Box
          ref={contactRef}
          sx={{
            width: "100%",
            height: "auto",
            textAlign: "center",
            padding: 2,
            mt: 4,
          }}
        >
          {isSmallScreen ? <h6>Contact Us</h6> : <h1>Contact Us</h1>}
          <ContactForm />
        </Box>

        {/* FOOTER SECTION */}
        <Box sx={{ paddingTop: 2, backgroundColor: "#1FAB89" }}>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
