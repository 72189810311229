import React from 'react';
import { Typography, Divider, Container, Box } from '@mui/material';
import SearchableInput from './../../componants/SearchableInput';

const UnSafeActUnSafeCondition = ({ companyName }) => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add Type of Observation
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="type_of_observation"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Type of Observation"
        />

        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
          Add Severity
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="severity"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Severity"
        />

        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
          Add Responsible Department
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="responsible_department"
          companyName={companyName}
          containerHeight={300}
          placeholder="Add Responsible Department"
        />
      </Box>
    </Container>
  );
};

export default UnSafeActUnSafeCondition;