// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// // import firebase from 'firebase/app';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import 'firebase/firestore';
// import { createSearchParams, Link, useNavigate } from 'react-router-dom'; // Import for navigation
// import { setLoginStatusLocally, setUIDLocally } from './../utils/userUtils'; // Ensure these utility functions are imported correctly
// import Header from '../componants/Header';

// const CompanyList = () => {
//   const [companies, setCompanies] = useState([]);
//   const [companyName, setCompanyName] = useState('');
//   const [refreshing, setRefreshing] = useState(false);
//   const navigate = useNavigate();
// //   const navigate = useNavigate(); // Hook for programmatic navigation

//   useEffect(() => {
//     fetchCompanies();
//   }, []);

//   const fetchCompanies = async () => {
//     setRefreshing(true);
//     const firestore = firebase.firestore();
//     const companiesCollection = firestore.collection('company_data');
//     const querySnapshot = await companiesCollection.get();
//     const companyData = [];

//     for (const doc of querySnapshot.docs) {
//       const companyInfo = doc.data();
//       const companyName = companyInfo.name;
//       const companyRef = companiesCollection.doc(companyName);
//       const userInfoCollectionRef = companyRef.collection(companyName).doc('for_user').collection('users_information');
//       let totalActiveUsers = 0;
//       try {
//         const userInfoSnapshot = await userInfoCollectionRef.get();
//         totalActiveUsers = userInfoSnapshot.size;
//       } catch (error) {
//         console.error('Error getting user info count:', error);
//       }
//       companyData.push({
//         ...companyInfo,
//         case_count: companyInfo.case_count,
//         total_active_users: totalActiveUsers,
//       });
//     }

//     setCompanies(companyData);
//     setRefreshing(false);
//   };

//   const handleAddCompany = async (e) => {
//     e.preventDefault();
//     const firestore = firebase.firestore();
//     const companyRef = firestore.collection('company_data').doc(companyName);
//     await companyRef.set({
//       name: companyName,
//       total_active_users: 0,
//       case_count: 0,
//     });

//     const companyNameAdminRef = companyRef.collection(companyName).doc('for_admin');
//     await companyNameAdminRef.set({
//       name: 'for_admin',
//     });
//     const companyNameUserRef = companyRef.collection(companyName).doc('for_user');
//     await companyNameUserRef.set({
//       name: 'for_user',
//     });

//     // Set up other collections and documents as needed
//     await companyNameAdminRef.collection('department_name').doc('details').set({});
//     await companyNameAdminRef.collection('reporting_person_designation').doc('details').set({});
//     await companyNameAdminRef.collection('type_of_observation').doc('details').set({});
//     await companyNameAdminRef.collection('zone_leader').doc('details').set({});
//     await companyNameAdminRef.collection('company_details').doc('details').set({});

//     await companyNameAdminRef.collection('severity').doc('details').set({});
//     await companyNameAdminRef.collection('responsible_department').doc('details').set({});
//     await companyNameAdminRef.collection('injury_category').doc('details').set({});
//     await companyNameAdminRef.collection('affected_workman_employee_type').doc('details').set({});
//     await companyNameAdminRef.collection('body_part_affected').doc('details').set({});

//     await companyNameAdminRef.collection('type_of_accident').doc('details').set({});
//     await companyNameAdminRef.collection('topics_discussed').doc('details').set({});
//     await companyNameAdminRef.collection('ppe_type').doc('details').set({});
//     await companyNameAdminRef.collection('area_managers').doc('details').set({});
//     await companyNameAdminRef.collection('company_zones').doc('details').set({});
//     await companyNameAdminRef.collection('zone_assignment').doc('details').set({});
//     // await companyNameAdminRef.collection('kaizen').doc('details').set({});

//     await companyNameAdminRef.collection('credentials').doc('users').set({});

//     // for users
//     await companyNameUserRef.collection('near_miss_report').doc('details').set({});
//     await companyNameUserRef.collection('unsafe_act').doc('details').set({});
//     await companyNameUserRef.collection('accident_reports').doc('details').set({});
//     await companyNameUserRef.collection('weekly_safety_reports').doc('details').set({});
//     await companyNameUserRef.collection('fire_injury_reports').doc('details').set({});
//     await companyNameUserRef.collection('ppe_grievances').doc('details').set({});
//     await companyNameUserRef.collection('kaizen_reports').doc('details').set({});
//     await companyNameUserRef.collection('work_permit').doc('details').set({});

//     setCompanyName('');
//     fetchCompanies(); // Refresh the company list after adding a new company
//   };

// // // Logout handler
// const handleLogout = async () => {
//     try {
//       await firebase.auth().signOut();
//       setLoginStatusLocally(false);
//       setUIDLocally(null);
//      // setUserData(null); // Clear user data from state
//       // Optionally, redirect to the login or home page after logging out
//       window.location.href = '/'; // Or use a router method if using react-router
//     } catch (error) {
//       console.error('Error logging out:', error);
//     }
//   };
 
//   // const handleCompanyCardClick = (companyName) => {
//   //   //navigate(`/admin/dashboard`);
//   //   navigate({
//   //     pathname: `/admin/dashboard`,
//   //     search: `?companyName=${companyName}`
//   //   });
//   // };
//   const handleCompanyCardClick = (companyName) => {
//     // navigate(`/admin/dashboard?companyName=${companyName}`);
//     navigate(`/admin/dashboard?companyName=${companyName}`,{state: {companyName} });
//   };
//   return (
//     <Container className="mt-5">
//      <Row className="mb-4">
//         <Col md={12} className="d-flex justify-content-end">
//           <Button variant="danger" onClick={handleLogout}>
//             Logout
//           </Button>
//         </Col>
//       </Row>
//       {/* <Header/> */}
//       <Row>
//         <Col md={6}>
//           <h2 className="mb-4">Add Company</h2>
//           <Form onSubmit={handleAddCompany}>
//             <Form.Group controlId="formCompanyName">
//               <Form.Label>Company Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter company name"
//                 value={companyName}
//                 onChange={(e) => setCompanyName(e.target.value)}
//               />
//             </Form.Group>
//             <Button variant="primary" type="submit">
//               Add Company
//             </Button>
//           </Form>
//         </Col>
//         <Col md={6}>
//           <h2 className="mb-4">Company List</h2>
//           <Row>
//             {companies.map((company, index) => (
//               <Col md={6} key={index} className="mb-4">
//                 <Card onClick={() => handleCompanyCardClick(company.name)}>
//                   <Card.Body>
//                     <Card.Title>{company.name}</Card.Title>
//                     <Card.Text>
//                       Active Users: {company.total_active_users}
//                       <br />
//                       Case Count: {company.case_count}
//                     </Card.Text>
//                     {/* Add any additional actions or links here */}
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default CompanyList;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { setLoginStatusLocally, setUIDLocally } from './../utils/userUtils';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setRefreshing(true);
    const firestore = firebase.firestore();
    const companiesCollection = firestore.collection('company_data');
    const querySnapshot = await companiesCollection.get();
    const companyData = [];

    for (const doc of querySnapshot.docs) {
      const companyInfo = doc.data();
      const companyName = companyInfo.name;
      const companyRef = companiesCollection.doc(companyName);
      const userInfoCollectionRef = companyRef.collection(companyName).doc('for_user').collection('users_information');
      let totalActiveUsers = 0;
      try {
        const userInfoSnapshot = await userInfoCollectionRef.get();
        totalActiveUsers = userInfoSnapshot.size;
      } catch (error) {
        console.error('Error getting user info count:', error);
      }
      companyData.push({
        ...companyInfo,
        case_count: companyInfo.case_count,
        total_active_users: totalActiveUsers,
      });
    }

    setCompanies(companyData);
    setRefreshing(false);
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const firestore = firebase.firestore();
    const companyRef = firestore.collection('company_data').doc(companyName);
    await companyRef.set({
      name: companyName,
      total_active_users: 0,
      case_count: 0,
    });

    const companyNameAdminRef = companyRef.collection(companyName).doc('for_admin');
    await companyNameAdminRef.set({ name: 'for_admin' });
    const companyNameUserRef = companyRef.collection(companyName).doc('for_user');
    await companyNameUserRef.set({ name: 'for_user' });

    // Set up other collections and documents as needed
    await companyNameAdminRef.collection('department_name').doc('details').set({});
    // Other collections...
    await companyNameUserRef.collection('work_permit').doc('details').set({});

    setCompanyName('');
    fetchCompanies(); // Refresh the company list after adding a new company
  };

  // const handleLogout = async () => {
  //   try {
  //     await firebase.auth().signOut();
  //     setLoginStatusLocally(false);
  //     setUIDLocally(null);
  //     window.location.href = '/';
  //   } catch (error) {
  //     console.error('Error logging out:', error);
  //   }
  // };

  const handleCompanyCardClick = (companyName) => {
    navigate(`/admin/dashboard?companyName=${companyName}`, { state: { companyName } });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Add Company
          </Typography>
          <form onSubmit={handleAddCompany}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              margin="normal"
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Add Company
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Company List
          </Typography>
          <Grid container spacing={3}>
            {companies.map((company, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{ cursor: 'pointer' }} onClick={() => handleCompanyCardClick(company.name)}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {company.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Active Users: {company.total_active_users}
                      <br />
                      Case Count: {company.case_count}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* Add additional actions or links here if needed */}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyList;
