// import React, { useEffect, useState } from 'react';
// import {
//   Container,
//   Typography,
//   Card,
//   CardContent,
//   Button,
//   Grid,
//   Modal,
//   Box,
//   TextField,
//   IconButton,
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab
// } from '@mui/material';
// import { styled } from '@mui/system';
// import CloseIcon from '@mui/icons-material/Close';
// import moment from 'moment';
// import { firestore } from '../../firebase/firebaseConfig'; // Adjust the path as needed
// import { useLocation } from 'react-router-dom';
// import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/storage';

// const StyledCard = styled(Paper)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   '&:hover': {
//     boxShadow: theme.shadows[4],
//   },
// }));

// const ModalContent = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   backgroundColor: theme.palette.background.paper,
//   boxShadow: theme.shadows[5],
//   padding: theme.spacing(4),
//   outline: 'none',
//   maxHeight: '80vh',
//   overflowY: 'auto',
// }));

// const UnsafeActList = () => {
//   const [unsafeActs, setUnsafeActs] = useState([]);
//   const [selectedUnsafeAct, setSelectedUnsafeAct] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [date, setDate] = useState(new Date());

//   const [imageOfVerification, setImageOfverification] = useState(null);

//   const [tabValue, setTabValue] = useState(0);
//   const location = useLocation();
//   const { company_name } = location.state || {};

//   useEffect(() => {
//     const getUnsafeActs = async () => {
//       const unsafeActsRef = collection(firestore, 'company_data', company_name, company_name, 'for_user', 'unsafe_act');
//       const snapshot = await getDocs(unsafeActsRef);
//       const unsafeActsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

//       // Sort work permits by case number (highest first)
//      const sortedunsafeActsData = unsafeActsData.sort((a, b) => {
//       // Assuming caseNumber is a string, we'll parse it to an integer
//       const caseNumberA = parseInt(a.caseNumber, 10);
//       const caseNumberB = parseInt(b.caseNumber, 10);
//       return caseNumberB - caseNumberA; // For descending order
//     });
//       setUnsafeActs(unsafeActsData);
//     };
//     getUnsafeActs();
//   }, [company_name]);

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };

//   const handleModalOpen = (unsafeAct) => {
//     setSelectedUnsafeAct(unsafeAct);
//     setIsModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//     setSelectedUnsafeAct(null);
//   };

//   const handleDateChange = (event) => {
//     setDate(event.target.value);
//   };

//   const uploadImage = async (file) => {
//     if (!file) return null;

//     const storageRef = firebase.storage().ref();
//     const fileRef = storageRef.child(`unsafe_act_report_images/${Date.now()}_${file.name}`);

//     try {
//       await fileRef.put(file);
//       const downloadURL = await fileRef.getDownloadURL();
//       return downloadURL;
//     } catch (error) {
//       console.error('Error uploading image: ', error);
//       throw error;
//     }
//   };

//   const handleCloseUnsafeAct = async () => {
//     setIsLoading(true);
//     let imageUrl = null;
//     if (imageOfVerification) {
//       imageUrl = await uploadImage(imageOfVerification);
//     }
//     try {
//       const unsafeActRef = doc(firestore, 'company_data', company_name, company_name, 'for_user', 'unsafe_act', selectedUnsafeAct.id);
//       await updateDoc(unsafeActRef, {
//         isPending: false,
//         dateOfClosing: date,
//         imageOfVerification: imageUrl,
//       });
//       setIsLoading(false);
//       handleModalClose();
//     } catch (error) {
//       console.error('Error updating unsafe act:', error);
//       setIsLoading(false);
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const filteredunsafeActs = unsafeActs.filter(w =>
//     tabValue === 0 ? w.isPending : !w.isPending
//   );
//   const handleImageChange = (e) => {
//     if (e.target.files.length > 0) {
//       setImageOfverification(e.target.files[0]);
//     }
//   };

//   return (
//     <Container maxWidth="md">
//       <Typography variant="h4" gutterBottom>
//         Unsafe Acts
//       </Typography>
//       <Tabs value={tabValue} onChange={handleTabChange} centered>
//         <Tab label="Pending" />
//         <Tab label="Completed" />
//       </Tabs>
//       <Box mt={3}>
//         <Grid container spacing={2}>
//         {filteredunsafeActs.map((unsafeAct) => (
//           <Grid item xs={12} key={unsafeAct.id}>
//             <StyledCard elevation={1}>
//               <CardContent>
//                 <Typography variant="h6">Case Number: {unsafeAct.caseNumber}</Typography>
//                 <Typography>Date of Reporting: {formatDate(unsafeAct.date)}</Typography>
//                 <Typography>Description: {unsafeAct.description}</Typography>
//                 <Typography>Status: {unsafeAct.isPending ? 'Pending' : 'Completed'}</Typography>
//                 <Box mt={2}>
//                   <Button variant="contained" color="primary" onClick={() => handleModalOpen(unsafeAct)}>
//                     View Details
//                   </Button>
//                 </Box>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//       </Box>

//       <Modal
//         open={isModalOpen}
//         onClose={handleModalClose}
//         aria-labelledby="unsafe-act-modal-title"
//       >
//         <ModalContent>
//           <IconButton
//             aria-label="close"
//             onClick={handleModalClose}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//           {selectedUnsafeAct && (
//             <Box>
//               <Typography id="unsafe-act-modal-title" variant="h6" gutterBottom>Unsafe Act Details</Typography>
//               <Typography>Date of Reporting: {formatDate(selectedUnsafeAct.date)}</Typography>
//               <Typography>Description: {selectedUnsafeAct.description}</Typography>
//               <Typography>Case Number: {selectedUnsafeAct.caseNumber}</Typography>
//               <Typography>Area Name: {selectedUnsafeAct.areaName}</Typography>
//               <Typography>Auditor Name: {selectedUnsafeAct.auditorName}</Typography>
//               <Typography>Department Name: {selectedUnsafeAct.departmentName}</Typography>
//               <Typography>Employee ID: {selectedUnsafeAct.employeeId}</Typography>
//               <Typography>Reporting Person Designation: {selectedUnsafeAct.reportingPersonDesignation}</Typography>
//               <Typography>Responsible Department: {selectedUnsafeAct.responsibleDepartment}</Typography>
//               {selectedUnsafeAct.image && (
//                 <Box mt={2} mb={2}>
//                   <img src={selectedUnsafeAct.image} alt="Unsafe Act" style={{ width: '100%', borderRadius: '4px' }} />
//                 </Box>
//               )}
//               {selectedUnsafeAct.isPending && (
//                 <Box mt={2}>
//                   <Typography>Date of Closing*</Typography>
//                   <TextField
//                     type="date"
//                     value={moment(date).format('YYYY-MM-DD')}
//                     onChange={handleDateChange}
//                     fullWidth
//                     margin="normal"
//                     disabled={true}
//                   />
//                   <Grid item xs={12} >
//               <input
//                 accept="image/*"
//                 id="contained-button-file"
//                 type="file"
//                 onChange={handleImageChange}
//                 style={{ display: 'none' }}
//               />
//               <label htmlFor="contained-button-file">
//                 <Button variant="contained" color="primary" component="span">
//                   Upload Image for Verification
//                 </Button>
//               </label>
//               {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
//             </Grid>
//                   <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleCloseUnsafeAct}
//                       disabled={isLoading}
//                     >
//                       {isLoading ? 'Closing...' : 'Close the form'}
//                     </Button>
//                     {isLoading && <CircularProgress size={24} />}
//                   </Box>
//                 </Box>
//               )}
//               <Box mt={2}>
//                 <Button variant="outlined" onClick={handleModalClose}>
//                   Back to list
//                 </Button>
//               </Box>
//             </Box>
//           )}
//         </ModalContent>
//       </Modal>
//     </Container>
//   );
// };

// export default UnsafeActList;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Divider,
 
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { firestore } from "../../firebase/firebaseConfig";
import { useLocation } from "react-router-dom";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const StyledPaper = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
}));

const UnsafeActList = () => {
  // ... (state and other functions remain the same)
  const [unsafeActs, setUnsafeActs] = useState([]);
  const [selectedUnsafeAct, setSelectedUnsafeAct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const [imageOfVerification, setImageOfverification] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const { company_name } = location.state || {};

  useEffect(() => {
    const getUnsafeActs = async () => {
      const unsafeActsRef = collection(
        firestore,
        "company_data",
        company_name,
        company_name,
        "for_user",
        "unsafe_act"
      );
      const snapshot = await getDocs(unsafeActsRef);
      const unsafeActsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort work permits by case number (highest first)
      const sortedunsafeActsData = unsafeActsData.sort((a, b) => {
        // Assuming caseNumber is a string, we'll parse it to an integer
        const caseNumberA = parseInt(a.caseNumber, 10);
        const caseNumberB = parseInt(b.caseNumber, 10);
        return caseNumberB - caseNumberA; // For descending order
      });
      setUnsafeActs(unsafeActsData);
    };
    getUnsafeActs();
  }, [company_name]);

  const formatDate = (timestamp) => {
    if (typeof timestamp === "string" && timestamp.includes("-")) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      ).format("MMMM D, YYYY");
    }
    return "Invalid Date";
  };

  const handleModalOpen = (unsafeAct) => {
    setSelectedUnsafeAct(unsafeAct);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUnsafeAct(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `unsafe_act_report_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleCloseUnsafeAct = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const unsafeActRef = doc(
        firestore,
        "company_data",
        company_name,
        company_name,
        "for_user",
        "unsafe_act",
        selectedUnsafeAct.id
      );
      await updateDoc(unsafeActRef, {
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating unsafe act:", error);
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredunsafeActs = unsafeActs.filter((w) =>
    tabValue === 0 ? w.isPending : !w.isPending
  );
  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfverification(e.target.files[0]);
    }
  };

  return (
    <StyledPaper elevation={3}>
      <Container maxWidth="lg">
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Unsafe Acts Management
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab label="Pending" icon={<WarningIcon />} iconPosition="start" />
          <Tab
            label="Completed"
            icon={<CheckCircleIcon />}
            iconPosition="start"
          />
        </Tabs>
        <Box mt={3}>
        <Grid container spacing={2}>
          {filteredunsafeActs.map((unsafeAct) => (
            <Grid item xs={12} sm={6} md={4} key={unsafeAct.id}>
              <StyledCard>
                <CardContent>
                    <Typography >
                      <strong>Case Number:</strong>{unsafeAct.caseNumber}
                    </Typography>
                  <Typography >
                    <strong>Reported on:</strong> {formatDate(unsafeAct.date)}
                  </Typography>
                  <Typography >
                    <strong>Area Name:</strong> {unsafeAct.areaName}
                  </Typography>
                  <Typography variant="body1" paragraph>
                  <strong>Description:</strong>
                    {unsafeAct.description.length > 40
                      ? `${unsafeAct.description.substring(0, 100)}...`
                      : unsafeAct.description}
                  </Typography>
                  <Typography color={unsafeAct.isPending ? 'error' : 'green'} ><strong>Status:</strong> {unsafeAct.isPending ? 'Pending' : 'Completed'}</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleModalOpen(unsafeAct)}
                    fullWidth
                  >
                    View Details
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        </Box>
      </Container>

        {/* Details Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="unsafe-act-modal-title"
      >
        <ModalContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="unsafe-act-modal-title" variant="h5" component="h2">
              Unsafe Act Details
            </Typography>
            <IconButton onClick={handleModalClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedUnsafeAct && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Case Number</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.caseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Date of Reporting</Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(selectedUnsafeAct.date)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1" paragraph>
                    {selectedUnsafeAct.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Area Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.areaName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Department Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Auditor Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.auditorName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Employee ID</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.employeeId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Reporting Person Designation
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.reportingPersonDesignation}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Responsible Department
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.responsibleDepartment}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />

              {selectedUnsafeAct.image && (
                <Box mt={2} mb={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reported Image
                  </Typography>
                  <img
                    src={selectedUnsafeAct.image}
                    alt="Unsafe Act"
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Box>
              )}

              {selectedUnsafeAct.isPending && (
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    Close Unsafe Act
                  </Typography>
                  <TextField
                    label="Date of Closing"
                    type="date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mt={2}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" component="span" fullWidth>
                        Upload Verification Image
                      </Button>
                    </label>
                    {imageOfVerification && (
                      <Typography variant="body2" color="textSecondary" mt={1}>
                        {imageOfVerification.name}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseUnsafeAct}
                      disabled={isLoading}
                    >
                      {isLoading ? "Closing..." : "Close Unsafe Act"}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </ModalContent>
      </Modal>
    </StyledPaper>
  );
};

export default UnsafeActList;
