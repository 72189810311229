import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Box, Paper, Container, ThemeProvider, createTheme, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions, styled, Card, IconButton, InputLabel, Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Close, CloudUpload, Delete } from "@material-ui/icons";
import { auditSections } from "../utils/questions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getUIDLocally } from "./../utils/userUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DetailedDataTable from "../Screens/users/auditTable/DetailedDataTable";

const db = firebase.firestore();

const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: "#f5f5f5",
      },
    },
    typography: {
      h4: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 600,
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&:before": {
              display: "none",
            },
            boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            marginBottom: "16px",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: "#f8f8f8",
            borderRadius: "8px 8px 0 0",
            "& .MuiAccordionSummary-content": {
              margin: "12px 0",
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "16px 24px 24px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 600,
          },
        },
      },
    },
  });
  const PreviewImage = styled("img")(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: 200,
    objectFit: "contain",
    marginTop: theme.spacing(2),
  }));
  
  const StyledPaper = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
  }));
  
  const ModalContent = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    maxHeight: "80vh",
    overflowY: "auto",
    borderRadius: theme.shape.borderRadius,
  }));
  
const ActionPlanForm = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  }));

export const ActionPlanModal = React.memo(({ open,section, onClose, questionId, questionText, company_name, sanitizedTitle, onSubmit }) => {
    const [actionPlans, setActionPlans] = useState([{
      TypeOfActionPlan: "",
      ResponsiblePerson: "",
      ApDescription: "",
      TargetDate: null,
      Status: "Open"
    }]);
  
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = useCallback(async () => {
      // try {
      //   const questionDocRef = db.collection("company_data")
      //     .doc(company_name)
      //     .collection(company_name)
      //     .doc("for_user")
      //     .collection("audits")
      //     .doc(sanitizedTitle)
      //     .collection("questions")
      //     .doc(questionText.replace(/[^a-zA-Z0-9-_]/g, "_"));
  
      //   const actionPlanRef = questionDocRef.collection("action_plan").doc();
      //   await actionPlanRef.set({
      //     action_plans: actionPlans,
      //     created_at: firebase.firestore.FieldValue.serverTimestamp()
      //   });
  
      //   onSubmit(actionPlans, questionId, questionText);
      //   onClose();
      // } catch (error) {
      //   console.error("Error submitting action plans:", error);
      //   alert("Failed to submit action plans");
      // }
      try {
        const sanitizedTitle = section.replace(/[^a-zA-Z0-9-_]/g, "_");
        const sanitizedQuestionId = questionId.replace(/[^a-zA-Z0-9-_]/g, "_");
    
        const questionDocRef = db.collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("for_user")
          .collection("audits")
          .doc(sanitizedTitle)
          .collection("questions")
          .doc(sanitizedQuestionId);
    
        const actionPlanPromises = actionPlans.map(async (plan, index) => {
          return questionDocRef.collection("action_plans").doc(`plan_${index + 1}`).set({
            TypeOfActionPlan: plan.TypeOfActionPlan,
            ResponsiblePerson: plan.ResponsiblePerson,
            ApDescription: plan.ApDescription,
            TargetDate: plan.TargetDate,
            Status: plan.Status,
          });
        });
    
        await Promise.all(actionPlanPromises);
    
        // Update the question document to indicate that action plans exist
        await questionDocRef.update({
          hasActionPlans: true
        });
    
        console.log("Action plans submitted successfully");
        return true;
      } catch (error) {
        console.error("Error submitting action plans:", error);
        return false;
      }
    }, [actionPlans, company_name, sanitizedTitle, questionText, questionId, onSubmit, onClose]);
  
    const handleActionPlanChange = (index, field, value) => {
      const newActionPlans = [...actionPlans];
      newActionPlans[index][field] = value;
      setActionPlans(newActionPlans);
    };
  
    const handleAddActionPlan = () => {
      setActionPlans([...actionPlans, {
        TypeOfActionPlan: "",
        ResponsiblePerson: "",
        ApDescription: "",
        TargetDate: null,
        Status: "Open"
      }]);
    };
  
    const handleRemoveActionPlan = (index) => {
      const newActionPlans = actionPlans.filter((_, i) => i !== index);
      setActionPlans(newActionPlans);
    };
  
    return (
          <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="action-plan-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
          <Typography
            id="action-plan-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
            fontWeight="bold"
            sx={{ mb: 3 }}
          >
            Action Plans
          </Typography>
          {actionPlans.map((plan, index) => (
            <ActionPlanForm key={index}>
              <Typography
                variant="subtitle1"
                gutterBottom
                fontWeight="bold"
              >
                Action Plan {index + 1}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type of Action Plan</InputLabel>
                    <Select
                      value={plan.TypeOfActionPlan}
                      onChange={(e) =>
                        handleActionPlanChange(
                          index,
                          "TypeOfActionPlan",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="Elimination">Elimination</MenuItem>
                      <MenuItem value="Substitution">Substitution</MenuItem>
                      <MenuItem value="Engineering Controls">
                        Engineering Controls
                      </MenuItem>
                      <MenuItem value="Administrative Controls">
                        Administrative Controls
                      </MenuItem>
                      <MenuItem value="PPE">PPE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Responsible Person"
                    value={plan.ResponsiblePerson}
                    onChange={(e) =>
                      handleActionPlanChange(
                        index,
                        "ResponsiblePerson",
                        e.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    value={plan.ApDescription}
                    onChange={(e) =>
                      handleActionPlanChange(
                        index,
                        "ApDescription",
                        e.target.value
                      )
                    }
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Target Date"
                    value={plan.TargetDate}
                    onChange={(newValue) =>
                      handleActionPlanChange(index, "TargetDate", newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth margin="normal" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={plan.Status}
                      onChange={(e) =>
                        handleActionPlanChange(
                          index,
                          "Status",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => handleRemoveActionPlan(index)}
                >
                  Remove
                </Button>
              </Box>
            </ActionPlanForm>
          ))}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddActionPlan}
            sx={{ mt: 2 }}
          >
            Add Action Plan
          </Button>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Submit Action Plans"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  });