import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import firebase from 'firebase/compat/app';
import {
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import 'firebase/compat/firestore';
// import 'firebase/auth';

// const firestore = firebase.firestore();
import { firestore } from '../../firebase/firebaseConfig';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  zoneContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
  },
}));

const CompanyZones = ({ companyName }) => {
  const classes = useStyles();
  const [zones, setZones] = useState([
    {
      name: '',
      alias: '',
      subZone: '',
      category: '',
      remark: '',
    },
  ]);
  const [errors, setErrors] = useState({});

  const handleAddZone = () => {
    setZones([
      ...zones,
      {
        name: '',
        alias: '',
        subZone: '',
        category: '',
        remark: '',
      },
    ]);
  };

  const handleSubmit = async () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      alert('Please fill in all mandatory fields');
      return;
    }

//     try {
//       // Implement your logic to save company zones here
//       console.log('Company Zones:', zones);
//       alert('Your data has been submitted successfully!');
//       clearForm();
//     } catch (error) {
//       console.error('Error saving company zones:', error);
//       alert('Error occurred while submitting data. Please try again.');
//     }
//   };

try {
    const savedZoneIds = [];
    if (Array.isArray(zones) && zones.length > 0) {
      for (const zone of zones) {
        const savedZone = await firestore
          .collection('company_data')
          .doc(companyName)
          .collection(companyName)
          .doc('for_admin')
          .collection('company_zones')
          .add({
            name: zone.name,
            alias: zone.alias,
            subZone: zone.subZone,
            category: zone.category,
            remark: zone.remark,
          });
        savedZoneIds.push(savedZone.id);
      }
    }
    console.log('Company Zones saved with IDs:', savedZoneIds);
    alert('Your data has been submitted successfully!');
    clearForm();
  } catch (error) {
    console.error('Error saving company zones:', error);
    alert('Error occurred while submitting data. Please try again.');
  }
};

  const validateFields = () => {
    const errors = {};

    zones.forEach((zone, index) => {
      if (!zone.name.trim()) {
        errors[`name-${index}`] = 'Name is required';
      }
      if (!zone.category.trim()) {
        errors[`category-${index}`] = 'Category is required';
      }
    });

    return errors;
  };

  const clearForm = () => {
    setZones([
      {
        name: '',
        alias: '',
        subZone: '',
        category: '',
        remark: '',
      },
    ]);
    setErrors({});
  };

  const handleZoneChange = (value, field, index) => {
    const updatedZones = [...zones];
    updatedZones[index][field] = value;
    setZones(updatedZones);
    setErrors({ ...errors, [`${field}-${index}`]: '' }); // Clear error for this field
  };

  const handleRemoveZone = (index) => {
    const updatedZones = [...zones];
    updatedZones.splice(index, 1);
    setZones(updatedZones);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h5" gutterBottom>
        Company Zones
      </Typography>
      {zones.map((zone, index) => (
        <Box key={index} className={classes.zoneContainer}>
          <Typography variant="subtitle1" className={classes.label}>
            Zone {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Name *
              </Typography>
              <TextField
                fullWidth
                value={zone.name}
                onChange={(e) => handleZoneChange(e.target.value, 'name', index)}
                error={!!errors[`name-${index}`]}
                helperText={errors[`name-${index}`]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Alias (optional)
              </Typography>
              <TextField
                fullWidth
                value={zone.alias}
                onChange={(e) => handleZoneChange(e.target.value, 'alias', index)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Sub Zone (optional)
              </Typography>
              <TextField
                fullWidth
                value={zone.subZone}
                onChange={(e) => handleZoneChange(e.target.value, 'subZone', index)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Category *
              </Typography>
              <TextField
                fullWidth
                select
                value={zone.category}
                onChange={(e) => handleZoneChange(e.target.value, 'category', index)}
                error={!!errors[`category-${index}`]}
                helperText={errors[`category-${index}`]}
              >
                <MenuItem value="red">Red</MenuItem>
                <MenuItem value="orange">Orange</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Remark
              </Typography>
              <TextField
                fullWidth
                value={zone.remark}
                onChange={(e) => handleZoneChange(e.target.value, 'remark', index)}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionButtons}>
            {index === zones.length - 1 && (
              <>
                <IconButton onClick={handleAddZone}>
                  <AddCircleOutlineIcon />
                </IconButton>
                {index > 0 && (
                  <IconButton onClick={() => handleRemoveZone(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default CompanyZones;