import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

const FeatureSection = ({ image, title, description, imageFirst = true ,backgroundColor}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>  
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen
              ? "column"
              : imageFirst
              ? "row"
              : "row-reverse",
            padding: isSmallScreen ? 2 : 6,
            gap: 1,
            height: "auto",
            borderRadius:6,
            backgroundColor:backgroundColor
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={image}
              alt={title}
              style={{
                width: "60%",
                maxWidth: 600,
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "left",
            }}
          >
            <Typography variant={isSmallScreen ? "h6" : "h4"} fontWeight="bold">
              {title}
            </Typography>
            <Typography
            mt={4}
              variant={isSmallScreen ? "body2" : "body1"}
              color="text.secondary"
            >
              {description}
            </Typography>
          </Box>
        </Box>
    </>
  );
};

export default FeatureSection;
