// // PPEGrievancesReport.js
// import React, { useState, useEffect } from 'react';
// import { 
//   Typography, 
//   TextField, 
//   Button, 
//   FormControl, 
//   InputLabel, 
//   Select, 
//   MenuItem, 
//   Grid, 
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker } from '@material-ui/pickers';
// import firebase from 'firebase/compat/app';
// import { firestore } from '../../firebase/firebaseConfig';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const PPEGrievancesReport = ({ userId, email, company_name }) => {
//   const classes = useStyles();
//   const [dateOfPPEGrievancesRaised, setDateOfPPEGrievancesRaised] = useState(new Date());
//   const [grievancesRaisedBy, setGrievancesRaisedBy] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [departmentName, setDepartmentName] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [ppeGrievancesDetails, setPPEGrievancesDetails] = useState('');

//   const [departments, setDepartments] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [caseCount, setCaseCount] = useState(0);
//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);
//  // console.log('ppe company',company_name,userId,email);

//   useEffect(() => {
//     const fetchData = async () => {
//       // Fetch departments from Firestore
//       const companyRef = firestore.collection('company_data').doc(company_name);
//       const departmentsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('department_name')
//         .get();
//       const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setDepartments(departmentData);

//       // Fetch areas from Firestore
//       const areasSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('company_zones')
//         .get();
//       const areaData = areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setAreas(areaData);

//        //fetch reporting person designation 
//   const designationSnapshot = await firestore
//   .collection('company_data')
//   .doc(company_name)
//   .collection(company_name)
//   .doc('for_admin')
//   .collection('reporting_person_designation').get();
//     const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id,
//       name: doc.data().name, // Accessing the name field using doc.data().name
//      }));
//     setrpDesignation(rpdData);

//      // Fetch case count
//      const companyDoc = await companyRef.get();
//      if (companyDoc.exists) {
//        setCaseCount(companyDoc.data().case_count || 0);
//      }
//      // Fetch user reports
//      fetchReports();
//     };

//     fetchData();
//   }, [company_name,userId]);

//   const fetchReports = async () => {
//     const reportsSnapshot = await firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('ppe_grievances')
//       .where('userId', '==', userId)
//       .get();

//     const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//     setReports(reportsData);
//   };

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };

//   const validate = () => {
//     const errors = {};
//     if (!dateOfPPEGrievancesRaised) errors.dateOfPPEGrievancesRaised = 'Date is required';
//     if (!grievancesRaisedBy) errors.grievancesRaisedBy = 'Grievances Raised By is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!departmentName) errors.departmentName = 'Department Name is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!ppeGrievancesDetails) errors.ppeGrievancesDetails = 'PPE Grievances Details are required';
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         const docRef = await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('ppe_grievances')
//           .add({
//             dateOfPPEGrievancesRaised,
//             grievancesRaisedBy,
//             reportingPersonDesignation,
//             employeeId,
//             departmentName,
//             areaName,
//             ppeGrievancesDetails,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//             // Update the case count
//       await firestore.collection('company_data').doc(company_name).update({
//         case_count: caseNumber,
//       });
//        console.log('Document written with ID: ', docRef.id);
//        const detailRef = firestore
//        .collection('company_data')
//        .doc(company_name)
//        .collection(company_name)
//        .doc('for_user')
//        .collection('ppe_grievances')
//        .doc('details');

//      const detailRefDoc = await detailRef.get();

//      if (detailRefDoc.exists) {
//        await detailRef.delete();
//      }
//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDateOfPPEGrievancesRaised(new Date());
//     setGrievancesRaisedBy('');
//     setReportingPersonDesignation('');
//     setEmployeeId('');
//     setDepartmentName('');
//     setAreaName('');
//     setPPEGrievancesDetails('');
//     setErrors({});
//   };
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           PPE Grievances Report
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of PPE Grievances Raised"
//                 value={dateOfPPEGrievancesRaised.toString()}
//                 onChange={setDateOfPPEGrievancesRaised}
//                 fullWidth
//                 error={!!errors.dateOfPPEGrievancesRaised}
//                 helperText={errors.dateOfPPEGrievancesRaised}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Grievances Raised By"
//                 value={grievancesRaisedBy}
//                 onChange={(e) => setGrievancesRaisedBy(e.target.value)}
//                 fullWidth
//                 error={!!errors.grievancesRaisedBy}
//                 helperText={errors.grievancesRaisedBy}
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Reporting Person Designation</InputLabel>
//                 <Select
//                   value={reportingPersonDesignation}
//                   onChange={(e) => setReportingPersonDesignation(e.target.value)}
//                 >
//                   {rpDesignation.map((rpd) => (
//                     <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.reportingPersonDesignation && <Typography className={classes.error}>{errors.reportingPersonDesignation}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Employee ID"
//                 value={employeeId}
//                 onChange={(e) => setEmployeeId(e.target.value)}
//                 fullWidth
//                 error={!!errors.employeeId}
//                 helperText={errors.employeeId}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.departmentName}>
//                 <InputLabel>Department Name</InputLabel>
//                 <Select
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                 >
//                   {departments.map((dept) => (
//                     <MenuItem key={dept.id} value={dept.name}>
//                       {dept.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.departmentName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.departmentName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Area Name</InputLabel>
//                 <Select
//                   value={areaName}
//                   onChange={(e) => setAreaName(e.target.value)}
//                 >
//                   {areas.map((area) => (
//                     <MenuItem key={area.id} value={area.name}>
//                       {area.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.areaName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.areaName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="PPE Grievances Details"
//                 multiline
//                 rows={4}
//                 value={ppeGrievancesDetails}
//                 onChange={(e) => setPPEGrievancesDetails(e.target.value)}
//                 fullWidth
//                 error={!!errors.ppeGrievancesDetails}
//                 helperText={errors.ppeGrievancesDetails}
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className={classes.submitButton}
//             disabled={isLoading}
//           >
//             {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//           </Button>
//           <Button variant="outlined" onClick={clearForm}>
//             Clear
//           </Button>
//         </form>
//       </Paper>
//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.dateOfPPEGrievancesRaised)} </Typography>
//                 <Typography>Description: {report.ppeGrievancesDetails}</Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>
//     </div>
//   );
// };

// export default PPEGrievancesReport;

import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import firebase from 'firebase/compat/app';
import { firestore } from '../../firebase/firebaseConfig';
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledForm = styled('form')(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const PPEGrievancesReport = ({ userId, email, company_name }) => {
  const [dateOfPPEGrievancesRaised, setDateOfPPEGrievancesRaised] = useState(new Date());
  const [grievancesRaisedBy, setGrievancesRaisedBy] = useState('');
  const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [areaName, setAreaName] = useState('');
  const [ppeGrievancesDetails, setPPEGrievancesDetails] = useState('');

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);
      
      // Fetch departments
      const departmentsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch reporting person designation
      const designationSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('reporting_person_designation')
        .get();
      setrpDesignation(designationSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }
      
      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('ppe_grievances')
      .where('userId', '==', userId)
      .get();

    setReports(reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const validate = () => {
    const errors = {};
    if (!dateOfPPEGrievancesRaised) errors.dateOfPPEGrievancesRaised = 'Date is required';
    if (!grievancesRaisedBy) errors.grievancesRaisedBy = 'Grievances Raised By is required';
    if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
    if (!employeeId) errors.employeeId = 'Employee ID is required';
    if (!departmentName) errors.departmentName = 'Department Name is required';
    if (!areaName) errors.areaName = 'Area Name is required';
    if (!ppeGrievancesDetails) errors.ppeGrievancesDetails = 'PPE Grievances Details are required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        const docRef = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('ppe_grievances')
          .add({
            dateOfPPEGrievancesRaised,
            grievancesRaisedBy,
            reportingPersonDesignation,
            employeeId,
            departmentName,
            areaName,
            ppeGrievancesDetails,
            caseNumber,
            userId,
            isPending: true,
          });

        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        const detailRef = firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('ppe_grievances')
          .doc('details');

        const detailRefDoc = await detailRef.get();
        if (detailRefDoc.exists) {
          await detailRef.delete();
        }

        clearForm();
        setIsLoading(false);
        alert('Form submitted successfully');
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsLoading(false);
        alert('Failed to submit form');
      }
    }
  };

  const clearForm = () => {
    setDateOfPPEGrievancesRaised(new Date());
    setGrievancesRaisedBy('');
    setReportingPersonDesignation('');
    setEmployeeId('');
    setDepartmentName('');
    setAreaName('');
    setPPEGrievancesDetails('');
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3 }}>
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            PPE Grievances Report
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of PPE Grievances Raised"
                  value={dateOfPPEGrievancesRaised}
                  onChange={setDateOfPPEGrievancesRaised}
                  renderInput={(params) => <TextField {...params} fullWidth error={!!errors.dateOfPPEGrievancesRaised} helperText={errors.dateOfPPEGrievancesRaised} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Grievances Raised By"
                  value={grievancesRaisedBy}
                  onChange={(e) => setGrievancesRaisedBy(e.target.value)}
                  fullWidth
                  error={!!errors.grievancesRaisedBy}
                  helperText={errors.grievancesRaisedBy}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel>Reporting Person Designation</InputLabel>
                  <Select
                    value={reportingPersonDesignation}
                    onChange={(e) => setReportingPersonDesignation(e.target.value)}
                    label="Reporting Person Designation"
                  >
                    {rpDesignation.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.reportingPersonDesignation && <StyledError>{errors.reportingPersonDesignation}</StyledError>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  fullWidth
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    label="Department Name"
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.departmentName && <StyledError>{errors.departmentName}</StyledError>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                    label="Area Name"
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.areaName && <StyledError>{errors.areaName}</StyledError>}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="PPE Grievances Details"
                  multiline
                  rows={4}
                  value={ppeGrievancesDetails}
                  onChange={(e) => setPPEGrievancesDetails(e.target.value)}
                  fullWidth
                  error={!!errors.ppeGrievancesDetails}
                  helperText={errors.ppeGrievancesDetails}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button variant="outlined" onClick={clearForm}>
                Clear
              </Button>
            </Box>
          </StyledForm>
        </StyledPaper>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box>
            {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <Card key={report.id} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.dateOfPPEGrievancesRaised)}</Typography>
                    <Typography>Description: {report.ppeGrievancesDetails}</Typography>
                  </CardContent>
                </Card>
              ))
            }
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default PPEGrievancesReport;