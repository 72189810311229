// import React from 'react';
// import { Grid, Card, CardContent, Box, Typography, Stack } from '@mui/material';
// import { SwapHoriz } from '@mui/icons-material';

// const CustomCard = ({
//   bannerText,
//   bannerColor,
//   bannerTextColor,
//   onCardClick,
//   Icon, // Accepting the icon component as a prop
//   iconProps = {}, // Optional icon props like color, size
//   viewMoreText,
//   title,
//   value,
//   subtitle,
//   subtitleColor
// }) => {
//   return (
//     <Grid item xs={12} sm={6} md={3}>
//       <Card
//         sx={{
//           textAlign: "center",
//           height: 130,
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           backgroundColor: "#000000",
//           color: "#fff",
//           position: "relative",
//           transition: 'transform 0.3s ease-in-out',
//         '&:hover': {
//           transform: 'scale(1.05)',
//         },
//         }}
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             backgroundColor: bannerColor,
//             padding: "4px 12px",
//             borderBottomRightRadius: "8px",
//             color: bannerTextColor,
//             fontWeight: "bold",
//             fontSize: "1rem",
//             cursor: "pointer",
//           }}
//         >
//           {bannerText}
//         </Box>
//         <CardContent
//           sx={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//           onClick={onCardClick}
//         >
//           <Stack
//             spacing={1}
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               mt: 4,
//             }}
//           >
//              {Icon && <Icon {...iconProps} />}
//             <Typography variant="body2" color={'text.secondary'}>
//               {viewMoreText}
//             </Typography>
//           </Stack>
//           <SwapHoriz style={{fontSize:40}}/>
//           <Stack spacing={1}>
//             <Typography variant="h4" fontWeight={"bold"}>
//               {title}
//             </Typography>
//             <Typography variant="h3">{value}</Typography>
//             <Typography fontWeight={"bold"} color={subtitleColor}>
//               {subtitle}
//             </Typography>
//           </Stack>
//         </CardContent>
//       </Card>
//     </Grid>
//   );
// };

// export default CustomCard;

import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { Info, SwapHoriz } from "@mui/icons-material";

const CustomCard = ({ totalIssued ,bannerText, bannerColor, bannerTextColor, openTextColor,closedTextColor, onOpenClick, onCloseClick, Icon, iconProps = {}, openTitle, openValue,
  closedTitle,
  closedValue,
}) => {

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{
          textAlign: "center",
          height: 160,
          width:260,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          position: "relative",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Box
          sx={{
            top: 0,
            left: 0,
            padding: "4px 12px",
            position:"absolute",
            borderBottomRightRadius: "8px",
            fontWeight: "bold",
            fontSize: "1rem",
            
          }}
        >
          {bannerText}: {( openValue ? openValue : 0 ) + (closedValue ? closedValue : 0 )} 
        </Box>
        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt:2
            }}
          >
          <Stack>
          {Icon && <Icon {...iconProps} />}
          
          </Stack>
            <Stack direction={"column"}>
              <Stack direction={'row'} spacing={2}>
                  {/* Open Section */}
              <Stack spacing={1} alignItems="center">
                <Typography variant="h4" color={"orange"}>{ openValue ? openValue : 0 }</Typography>
                <Typography variant="h6" fontWeight={"bold"} color={"orange"}>{openTitle}</Typography>
                <Info onClick={onOpenClick} sx={{cursor:'pointer'}}/>
              </Stack>
              
              <SwapHoriz/>

              {/* Closed Section */}
              <Stack spacing={1} alignItems="center">
                <Typography variant="h4" color={"green"}>{closedValue ? closedValue : 0 }</Typography>
                <Typography variant="h6" fontWeight={"bold"} color={"green"}>{closedTitle}</Typography>
                <Info onClick={onCloseClick} sx={{cursor:'pointer'}}/>
              </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomCard;
