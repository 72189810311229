// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker, TimePicker } from '@material-ui/pickers';
// import { firestore } from '../../firebase/firebaseConfig';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const FireInjuryReport = ({ userId, email, company_name }) => {
//   const classes = useStyles();
//   const [dateOfFireIncident, setDateOfFireIncident] = useState(new Date());
//   const [timeOfEvent, setTimeOfEvent] = useState(new Date());
//   const [departmentName, setDepartmentName] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [reportingPersonName, setReportingPersonName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [dateOfReporting, setDateOfReporting] = useState(new Date());
//   const [ignitionObject, setIgnitionObject] = useState('');
//   const [fireOrigin, setFireOrigin] = useState('');
//   const [fuelEnergy, setFuelEnergy] = useState('');
//   const [materialFirstIgnited, setMaterialFirstIgnited] = useState('');
//   const [possibleCause, setPossibleCause] = useState('');

//   const [departments, setDepartments] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);


//   const [errors, setErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [caseCount, setCaseCount] = useState(0);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const departmentsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('department_name')
//         .get();
//       const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setDepartments(departmentData);
    
//     // Fetch areas from Firestore
//     const areasSnapshot = await firestore
//     .collection('company_data')
//     .doc(company_name)
//     .collection(company_name)
//     .doc('for_admin')
//     .collection('company_zones')
//     .get();
//   const areaData = areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//   setAreas(areaData);

//   //fetch reporting person designation 
//   const designationSnapshot = await firestore
//   .collection('company_data')
//   .doc(company_name)
//   .collection(company_name)
//   .doc('for_admin')
//   .collection('reporting_person_designation').get();
//     const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id,
//       name: doc.data().name, // Accessing the name field using doc.data().name
//      }));
//     setrpDesignation(rpdData);

//     // Fetch case count
//     const companyDoc = await firestore.collection('company_data').doc(company_name).get();
//     if (companyDoc.exists) {
//       setCaseCount(companyDoc.data().case_count || 0);
//     }
//      // Fetch user reports
//      fetchReports();
// };

//     fetchData();
//   }, [company_name,userId]);

//   const fetchReports = async () => {
//     const reportsSnapshot = await firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('fire_injury_reports')
//       .where('userId', '==', userId)
//       .get();

//     const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//     setReports(reportsData);
//   };

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };

//   const validate = () => {
//     const errors1 = {};
//     if (!dateOfFireIncident) { errors1.dateOfFireIncident = '***Date of Fire Incident is required'; }
//     if (!timeOfEvent) { errors1.timeOfEvent = '***Time of Event is required'; }
//     if (!departmentName) { errors1.departmentName = '***Department Name is required'; }
//     if (!areaName) { errors1.areaName = '***Area Name is required'; }
//     if (!reportingPersonName) { errors1.reportingPersonName = '***Reporting Person Name is required'; }
//     if (!employeeId) { errors1.employeeId = '***Employee ID is required'; }
//     if (!reportingPersonDesignation) { errors1.reportingPersonDesignation = '***Reporting Person Designation is required'; }
//     if (!dateOfReporting) { errors1.dateOfReporting = '***Date of Reporting is required'; }
//     if (!ignitionObject) { errors1.ignitionObject = '***Ignition Object is required'; }
//     if (!fireOrigin) { errors1.fireOrigin = '***Fire Origin is required'; }
//     if (!fuelEnergy) { errors1.fuelEnergy = '***Fuel/Energy is required'; }
//     if (!materialFirstIgnited) { errors1.materialFirstIgnited = '***Material First Ignited is required'; }
//     if (!possibleCause) { errors1.possibleCause = '***Possible Cause is required'; }
//     setErrors(errors1);
//     return Object.keys(errors1).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         // Firestore submission logic here
//         const caseNumber = caseCount + 1;
//          const docRef = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('fire_injury_reports')
//         .add({
//          dateOfFireIncident,
//          timeOfEvent,
//          departmentName,
//          areaName,
//          reportingPersonName,
//          employeeId,
//          reportingPersonDesignation,
//          dateOfReporting,
//          ignitionObject,
//          fireOrigin,
//          fuelEnergy,
//          materialFirstIgnited,
//          possibleCause,
//          caseNumber,
//          userId,
//          isPending: true, //to keep track the status of the form
//        });
//        // Update the case count
//       await firestore.collection('company_data').doc(company_name).update({
//         case_count: caseNumber,
//       });
//       const detailRef = firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('fire_injury_reports')
//       .doc('details');

//     const detailRefDoc = await detailRef.get();

//     if (detailRefDoc.exists) {
//       await detailRef.delete();
//     }
//        console.log('Document written with ID: ', docRef.id);

//        // Clear form fields after successful submission
//        clearForm();


//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDateOfFireIncident(new Date());
//     setTimeOfEvent(new Date());
//     setDepartmentName('');
//     setAreaName('');
//     setReportingPersonName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setDateOfReporting(new Date());
//     setIgnitionObject('');
//     setFireOrigin('');
//     setFuelEnergy('');
//     setMaterialFirstIgnited('');
//     setPossibleCause('');
//     setErrors({});
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           Fire Incident Report
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of Fire Incident"
//                 value={dateOfFireIncident.toString()}
//                 onChange={setDateOfFireIncident}
//                 fullWidth
//                 error={!!errors.dateOfFireIncident}
//                 helperText={errors.dateOfFireIncident}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TimePicker
//                 label="Time of Event"
//                 value={timeOfEvent.toLocaleTimeString()}
//                 onChange={setTimeOfEvent}
//                 fullWidth
//                 error={!!errors.timeOfEvent}
//                 helperText={errors.timeOfEvent}
//               />
//             </Grid>
            
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.departmentName}>
//                 <InputLabel>Department Name</InputLabel>
//                 <Select
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                 >
//                   {departments.map((dept) => (
//                     <MenuItem key={dept.id} value={dept.name}>
//                       {dept.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.departmentName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.departmentName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Area Name</InputLabel>
//                 <Select
//                   value={areaName}
//                   onChange={(e) => setAreaName(e.target.value)}
//                 >
//                   {areas.map((area) => (
//                     <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.areaName && <Typography className={classes.error}>{errors.areaName}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Reporting Person Name"
//                 value={reportingPersonName}
//                 onChange={(e) => setReportingPersonName(e.target.value)}
//                 fullWidth
//                 error={!!errors.reportingPersonName}
//                 helperText={errors.reportingPersonName}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Employee ID"
//                 value={employeeId}
//                 onChange={(e) => setEmployeeId(e.target.value)}
//                 fullWidth
//                 error={!!errors.employeeId}
//                 helperText={errors.employeeId}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Reporting Person Designation</InputLabel>
//                 <Select
//                   value={reportingPersonDesignation}
//                   onChange={(e) => setReportingPersonDesignation(e.target.value)}
//                 >
//                   {rpDesignation.map((rpd) => (
//                     <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.reportingPersonDesignation && <Typography className={classes.error}>{errors.reportingPersonDesignation}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of Reporting"
//                 value={dateOfReporting.toString()}
//                 onChange={setDateOfReporting}
//                 fullWidth
//                 error={!!errors.dateOfReporting}
//                 helperText={errors.dateOfReporting}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Ignition Object"
//                 value={ignitionObject}
//                 onChange={(e) => setIgnitionObject(e.target.value)}
//                 fullWidth
//                 error={!!errors.ignitionObject}
//                 helperText={errors.ignitionObject}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Fire Origin"
//                 value={fireOrigin}
//                 onChange={(e) => setFireOrigin(e.target.value)}
//                 fullWidth
//                 error={!!errors.fireOrigin}
//                 helperText={errors.fireOrigin}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Fuel/Energy"
//                 value={fuelEnergy}
//                 onChange={(e) => setFuelEnergy(e.target.value)}
//                 fullWidth
//                 error={!!errors.fuelEnergy}
//                 helperText={errors.fuelEnergy}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Material First Ignited"
//                 value={materialFirstIgnited}
//                 onChange={(e) => setMaterialFirstIgnited(e.target.value)}
//                 fullWidth
//                 error={!!errors.materialFirstIgnited}
//                 helperText={errors.materialFirstIgnited}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="Possible Cause"
//                 value={possibleCause}
//                 onChange={(e) => setPossibleCause(e.target.value)}
//                 fullWidth
//                 multiline
//                 rows={4}
//                 error={!!errors.possibleCause}
//                 helperText={errors.possibleCause}
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className={classes.submitButton}
//             disabled={isLoading}
//           >
//             {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//           </Button>
//           <Button variant="outlined" onClick={clearForm}>
//             Clear
//           </Button>
//         </form>
//       </Paper>
//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.dateOfFireIncident)} </Typography>
//                   <Typography>Possible Cause: {report.possibleCause}</Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>
//     </div>
//   );
// };

// export default FireInjuryReport;

// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box,
//   Container,
//   Snackbar,
// } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { styled } from '@mui/material/styles';
// import { firestore } from '../../firebase/firebaseConfig';
// import { format } from 'date-fns';

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(4),
//   marginBottom: theme.spacing(4),
// }));

// const StyledForm = styled('form')(({ theme }) => ({
//   '& .MuiTextField-root': {
//     marginBottom: theme.spacing(2),
//   },
//   '& .MuiFormControl-root': {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   marginRight: theme.spacing(2),
// }));

// const FireInjuryReport = ({ userId, email, company_name }) => {
//   const [dateOfFireIncident, setDateOfFireIncident] = useState(new Date());
//   const [timeOfEvent, setTimeOfEvent] = useState(new Date());
//   const [departmentName, setDepartmentName] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [reportingPersonName, setReportingPersonName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [dateOfReporting, setDateOfReporting] = useState(new Date());
//   const [ignitionObject, setIgnitionObject] = useState('');
//   const [fireOrigin, setFireOrigin] = useState('');
//   const [fuelEnergy, setFuelEnergy] = useState('');
//   const [materialFirstIgnited, setMaterialFirstIgnited] = useState('');
//   const [possibleCause, setPossibleCause] = useState('');

//   const [departments, setDepartments] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);

//   const [errors, setErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [caseCount, setCaseCount] = useState(0);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const departmentsSnapshot = await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_admin')
//           .collection('department_name')
//           .get();
//         const departmentData = departmentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         setDepartments(departmentData);

//         const areasSnapshot = await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_admin')
//           .collection('company_zones')
//           .get();
//         const areaData = areasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         setAreas(areaData);

//         const designationSnapshot = await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_admin')
//           .collection('reporting_person_designation')
//           .get();
//         const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setrpDesignation(rpdData);

//         const companyDoc = await firestore.collection('company_data').doc(company_name).get();
//         if (companyDoc.exists) {
//           setCaseCount(companyDoc.data().case_count || 0);
//         }

//         fetchReports();
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setSnackbarMessage('Error fetching data. Please try again.');
//         setSnackbarOpen(true);
//       }
//     };

//     fetchData();
//   }, [company_name, userId]);

//   const fetchReports = async () => {
//     try {
//       const reportsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('fire_injury_reports')
//         .where('userId', '==', userId)
//         .get();

//       const reportsData = reportsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//       setReports(reportsData);
//     } catch (error) {
//       console.error('Error fetching reports:', error);
//       setSnackbarMessage('Error fetching reports. Please try again.');
//       setSnackbarOpen(true);
//     }
//   };

//   const validate = () => {
//     const errors = {};
//     if (!dateOfFireIncident) errors.dateOfFireIncident = 'Date of Fire Incident is required';
//     if (!timeOfEvent) errors.timeOfEvent = 'Time of Event is required';
//     if (!departmentName) errors.departmentName = 'Department Name is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting is required';
//     if (!ignitionObject) errors.ignitionObject = 'Ignition Object is required';
//     if (!fireOrigin) errors.fireOrigin = 'Fire Origin is required';
//     if (!fuelEnergy) errors.fuelEnergy = 'Fuel/Energy is required';
//     if (!materialFirstIgnited) errors.materialFirstIgnited = 'Material First Ignited is required';
//     if (!possibleCause) errors.possibleCause = 'Possible Cause is required';
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('fire_injury_reports')
//           .add({
//             dateOfFireIncident,
//             timeOfEvent,
//             departmentName,
//             areaName,
//             reportingPersonName,
//             employeeId,
//             reportingPersonDesignation,
//             dateOfReporting,
//             ignitionObject,
//             fireOrigin,
//             fuelEnergy,
//             materialFirstIgnited,
//             possibleCause,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//         await firestore.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });

//         setCaseCount(caseNumber);
//         clearForm();
//         setSnackbarMessage('Form submitted successfully');
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setSnackbarMessage('Failed to submit form. Please try again.');
//         setSnackbarOpen(true);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const clearForm = () => {
//     setDateOfFireIncident(new Date());
//     setTimeOfEvent(new Date());
//     setDepartmentName('');
//     setAreaName('');
//     setReportingPersonName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setDateOfReporting(new Date());
//     setIgnitionObject('');
//     setFireOrigin('');
//     setFuelEnergy('');
//     setMaterialFirstIgnited('');
//     setPossibleCause('');
//     setErrors({});
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const formatDate = (date) => {
//     return format(new Date(date), 'MMMM d, yyyy');
//   };

//   return (
//     <Container>
//       <StyledPaper>
//         <Typography variant="h4">Fire Incident Report</Typography>
//         <StyledForm onSubmit={handleSubmit}>
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <DatePicker
//                   label="Date of Fire Incident"
//                   value={dateOfFireIncident}
//                   onChange={setDateOfFireIncident}
//                   renderInput={(params) => <TextField {...params} fullWidth error={!!errors.dateOfFireIncident} helperText={errors.dateOfFireIncident} />}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TimePicker
//                   label="Time of Event"
//                   value={timeOfEvent}
//                   onChange={setTimeOfEvent}
//                   renderInput={(params) => <TextField {...params} fullWidth error={!!errors.timeOfEvent} helperText={errors.timeOfEvent} />}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>Department Name</InputLabel>
//                   <Select
//                     value={departmentName}
//                     onChange={(e) => setDepartmentName(e.target.value)}
//                     error={!!errors.departmentName}
//                   >
//                     {departments.map((dept) => (
//                       <MenuItem key={dept.id} value={dept.name}>
//                         {dept.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>Area Name</InputLabel>
//                   <Select
//                     value={areaName}
//                     onChange={(e) => setAreaName(e.target.value)}
//                     error={!!errors.areaName}
//                   >
//                     {areas.map((area) => (
//                       <MenuItem key={area.id} value={area.name}>
//                         {area.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="Reporting Person Name"
//                   value={reportingPersonName}
//                   onChange={(e) => setReportingPersonName(e.target.value)}
//                   fullWidth
//                   error={!!errors.reportingPersonName}
//                   helperText={errors.reportingPersonName}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="Employee ID"
//                   value={employeeId}
//                   onChange={(e) => setEmployeeId(e.target.value)}
//                   fullWidth
//                   error={!!errors.employeeId}
//                   helperText={errors.employeeId}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>Reporting Person Designation</InputLabel>
//                   <Select
//                     value={reportingPersonDesignation}
//                     onChange={(e) => setReportingPersonDesignation(e.target.value)}
//                     error={!!errors.reportingPersonDesignation}
//                   >
//                     {rpDesignation.map((designation) => (
//                       <MenuItem key={designation.id} value={designation.name}>
//                         {designation.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <DatePicker
//                   label="Date of Reporting"
//                   value={dateOfReporting}
//                   onChange={setDateOfReporting}
//                   renderInput={(params) => <TextField {...params} fullWidth error={!!errors.dateOfReporting} helperText={errors.dateOfReporting} />}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Ignition Object"
//                   value={ignitionObject}
//                   onChange={(e) => setIgnitionObject(e.target.value)}
//                   fullWidth
//                   error={!!errors.ignitionObject}
//                   helperText={errors.ignitionObject}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Fire Origin"
//                   value={fireOrigin}
//                   onChange={(e) => setFireOrigin(e.target.value)}
//                   fullWidth
//                   error={!!errors.fireOrigin}
//                   helperText={errors.fireOrigin}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Fuel/Energy"
//                   value={fuelEnergy}
//                   onChange={(e) => setFuelEnergy(e.target.value)}
//                   fullWidth
//                   error={!!errors.fuelEnergy}
//                   helperText={errors.fuelEnergy}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Material First Ignited"
//                   value={materialFirstIgnited}
//                   onChange={(e) => setMaterialFirstIgnited(e.target.value)}
//                   fullWidth
//                   error={!!errors.materialFirstIgnited}
//                   helperText={errors.materialFirstIgnited}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Possible Cause"
//                   value={possibleCause}
//                   onChange={(e) => setPossibleCause(e.target.value)}
//                   fullWidth
//                   error={!!errors.possibleCause}
//                   helperText={errors.possibleCause}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledButton variant="contained" color="primary" type="submit" disabled={isLoading}>
//                   {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//                 </StyledButton>
//                 <StyledButton variant="outlined" onClick={clearForm}>
//                   Clear
//                 </StyledButton>
//               </Grid>
//             </Grid>
//           </LocalizationProvider>
//         </StyledForm>
//       </StyledPaper>

//       <Tabs value={tabValue} onChange={handleTabChange}>
//         <Tab label="Report Form" />
//         <Tab label="View Reports" />
//       </Tabs>

//       {tabValue === 1 && (
//         <Grid container spacing={3}>
//           {reports.map((report) => (
//             <Grid item xs={12} key={report.id}>
//               <Card>
//                 <CardContent>
//                   <Typography variant="h6">Report ID: {report.caseNumber}</Typography>
//                   <Typography variant="body1">Date of Fire Incident: {formatDate(report.dateOfFireIncident)}</Typography>
//                   <Typography variant="body1">Reporting Person: {report.reportingPersonName}</Typography>
//                   <Typography variant="body1">Status: {report.isPending ? 'Pending' : 'Resolved'}</Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       )}

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackbarMessage}
//       />
//     </Container>
//   );
// };

// export default FireInjuryReport;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container,
  Snackbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { firestore } from '../../firebase/firebaseConfig';
import { format, parseISO } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FireInjuryReport = ({ userId, email, company_name }) => {
  const [dateOfFireIncident, setDateOfFireIncident] = useState(new Date());
  const [timeOfEvent, setTimeOfEvent] = useState(new Date());
  const [departmentName, setDepartmentName] = useState('');
  const [areaName, setAreaName] = useState('');
  const [reportingPersonName, setReportingPersonName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
  const [dateOfReporting, setDateOfReporting] = useState(new Date());
  const [ignitionObject, setIgnitionObject] = useState('');
  const [fireOrigin, setFireOrigin] = useState('');
  const [fuelEnergy, setFuelEnergy] = useState('');
  const [materialFirstIgnited, setMaterialFirstIgnited] = useState('');
  const [possibleCause, setPossibleCause] = useState('');

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentsSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('department_name')
          .get();
        const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDepartments(departmentData);

        const areasSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('company_zones')
          .get();
        const areaData = areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAreas(areaData);

        const designationSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('reporting_person_designation')
          .get();
        const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setrpDesignation(rpdData);

        const companyDoc = await firestore.collection('company_data').doc(company_name).get();
        if (companyDoc.exists) {
          setCaseCount(companyDoc.data().case_count || 0);
        }

        fetchReports();
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching data. Please try again.');
        setSnackbarOpen(true);
      }
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    try {
      const reportsSnapshot = await firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('fire_injury_reports')
        .where('userId', '==', userId)
        .get();

      const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReports(reportsData);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setSnackbarMessage('Error fetching reports. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const validate = () => {
    const errors = {};
    if (!dateOfFireIncident) errors.dateOfFireIncident = 'Date of Fire Incident is required';
    if (!timeOfEvent) errors.timeOfEvent = 'Time of Event is required';
    if (!departmentName) errors.departmentName = 'Department Name is required';
    if (!areaName) errors.areaName = 'Area Name is required';
    if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
    if (!employeeId) errors.employeeId = 'Employee ID is required';
    if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
    if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting is required';
    if (!ignitionObject) errors.ignitionObject = 'Ignition Object is required';
    if (!fireOrigin) errors.fireOrigin = 'Fire Origin is required';
    if (!fuelEnergy) errors.fuelEnergy = 'Fuel/Energy is required';
    if (!materialFirstIgnited) errors.materialFirstIgnited = 'Material First Ignited is required';
    if (!possibleCause) errors.possibleCause = 'Possible Cause is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('fire_injury_reports')
          .add({
            dateOfFireIncident,
            timeOfEvent,
            departmentName,
            areaName,
            reportingPersonName,
            employeeId,
            reportingPersonDesignation,
            dateOfReporting,
            ignitionObject,
            fireOrigin,
            fuelEnergy,
            materialFirstIgnited,
            possibleCause,
            caseNumber,
            userId,
            isPending: true,
          });

        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        clearForm();
        fetchReports();
        setSnackbarMessage('Form submitted successfully');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error submitting form:', error);
        setSnackbarMessage('Failed to submit form. Please try again.');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const clearForm = () => {
    setDateOfFireIncident(new Date());
    setTimeOfEvent(new Date());
    setDepartmentName('');
    setAreaName('');
    setReportingPersonName('');
    setEmployeeId('');
    setReportingPersonDesignation('');
    setDateOfReporting(new Date());
    setIgnitionObject('');
    setFireOrigin('');
    setFuelEnergy('');
    setMaterialFirstIgnited('');
    setPossibleCause('');
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const formatDate = (date) => {
    if (date instanceof Date) {
      return format(date, 'dd/MM/yyyy');
    } else if (typeof date === 'string') {
      return format(parseISO(date), 'dd/MM/yyyy');
    } else if (date && typeof date.toDate === 'function') {
      return format(date.toDate(), 'dd/MM/yyyy');
    }
    return 'Invalid Date';
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            Fire Incident Report
          </Typography>
          <StyledFormControl onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Fire Incident"
                  value={dateOfFireIncident}
                  onChange={setDateOfFireIncident}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!errors.dateOfFireIncident}
                      helperText={errors.dateOfFireIncident}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePicker
                  label="Time of Event"
                  value={timeOfEvent}
                  onChange={setTimeOfEvent}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!errors.timeOfEvent}
                      helperText={errors.timeOfEvent}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.departmentName && (
                    <Typography variant="caption" color="error">
                      {errors.departmentName}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.areaName && <Typography color="error">{errors.areaName}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Reporting Person Name"
                  value={reportingPersonName}
                  onChange={(e) => setReportingPersonName(e.target.value)}
                  fullWidth
                  error={!!errors.reportingPersonName}
                  helperText={errors.reportingPersonName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  fullWidth
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel>Reporting Person Designation</InputLabel>
                  <Select
                    value={reportingPersonDesignation}
                    onChange={(e) => setReportingPersonDesignation(e.target.value)}
                  >
                    {rpDesignation.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.reportingPersonDesignation && <Typography color="error">{errors.reportingPersonDesignation}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Reporting"
                  value={dateOfReporting}
                  onChange={setDateOfReporting}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!errors.dateOfReporting}
                      helperText={errors.dateOfReporting}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Ignition Object"
                  value={ignitionObject}
                  onChange={(e) => setIgnitionObject(e.target.value)}
                  fullWidth
                  error={!!errors.ignitionObject}
                  helperText={errors.ignitionObject}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fire Origin"
                  value={fireOrigin}
                  onChange={(e) => setFireOrigin(e.target.value)}
                  fullWidth
                  error={!!errors.fireOrigin}
                  helperText={errors.fireOrigin}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fuel/Energy"
                  value={fuelEnergy}
                  onChange={(e) => setFuelEnergy(e.target.value)}
                  fullWidth
                  error={!!errors.fuelEnergy}
                  helperText={errors.fuelEnergy}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Material First Ignited"
                  value={materialFirstIgnited}
                  onChange={(e) => setMaterialFirstIgnited(e.target.value)}
                  fullWidth
                  error={!!errors.materialFirstIgnited}
                  helperText={errors.materialFirstIgnited}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Possible Cause"
                  value={possibleCause}
                  onChange={(e) => setPossibleCause(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.possibleCause}
                  helperText={errors.possibleCause}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                </StyledButton>
                <StyledButton
                  type="button"
                  variant="outlined"
                  onClick={clearForm}
                  disabled={isLoading}
                >
                  Clear
                </StyledButton>
              </Grid>
            </Grid>
          </StyledFormControl>
        </StyledPaper>

        <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs value={tabValue} 
          onChange={handleTabChange} 
          indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
        >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3}>
          <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <Grid item xs={12} sm={6} md={4} key={report.id}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                    <Typography>Description: {report.description}</Typography>
                    <Typography color={report.isPending ? 'error' : 'green'} >Status: {report.isPending ? 'Pending' : 'Completed'}</Typography>
                  </CardContent>
                </StyledCard>
                </Grid>
              ))}
          </Grid>
          </Box>

        </StyledPaper>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </LocalizationProvider>
  );
};

export default FireInjuryReport;