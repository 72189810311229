import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { FaChartBar } from 'react-icons/fa';
import { Box } from '@mui/system';
import { Button, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';


const AdminDashboard = ({companyName }) => {


  const reportTypes = [
    { name: 'Near Miss Report', path: 'near-miss-report-chart', permission: 'Near Miss Report' },
    { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
    { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
    { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
    { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
    { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
    { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
    { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
  ];
  
  return (
    <Box sx={{width:"100%"}}>
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
        <Typography variant="h4" fontWeight="bold">
          Welcome to {companyName} Dashboard
        </Typography>
      </Box>   
    <Grid container spacing={2}>
      {reportTypes.map((report, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{backgroundColor:"#526D82"}}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" color={'#ffff'}>
                {report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
              </Typography>
              <Button
                component={Link} 
                to={report.path} 
                variant="contained" 
                color="primary" 
                startIcon={<FaChartBar />}
                sx={{ mt: 2 }}
              >
                View Chart
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
  );
};
export default AdminDashboard;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaChartBar } from 'react-icons/fa';
// import { Box, Button, Tab, Tabs, Typography } from '@mui/material';

// const AdminDashboard = ({ companyName }) => {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const reportTypes = [
//     { name: 'Near Miss Report', path: 'near-miss-report-chart', permission: 'Near Miss Report' },
//     { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
//     { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
//     { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
//     { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
//     { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
//     { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
//     { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
//   ];

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box display="flex" justifyContent="center" alignItems="center" mb={2} textAlign="center">
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>

//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         aria-label="report types tabs"
//       >
//         {reportTypes.map((report, index) => (
//           <Tab
//             key={index}
//             label={report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
//           />
//         ))}
//       </Tabs>

//       {reportTypes.map((report, index) => (
//         <Box
//           key={index}
//           role="tabpanel"
//           hidden={value !== index}
//           id={`tabpanel-${index}`}
//           aria-labelledby={`tab-${index}`}
//         >
//           {value === index && (
//             <Box sx={{ p: 3 }}>
//               <Button
//                 component={Link}
//                 to={report.path}
//                 variant="contained"
//                 color="primary"
//                 startIcon={<FaChartBar />}
//               >
//                 View Chart
//               </Button>
//             </Box>
//           )}
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default AdminDashboard;
// import React, { useState } from 'react';
// import { Box, Tab, Tabs, Typography } from '@mui/material';
// import { Outlet, useNavigate, useLocation } from 'react-router-dom';

// const AdminDashboard = ({ companyName }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [value, setValue] = useState(0);

//   const reportTypes = [
//     { name: 'Near Miss Report', path: 'near-miss-report-chart' },
//     { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart' },
//     { name: 'Accident Report', path: 'accident-report-chart' },
//     { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart' },
//     { name: 'Fire Incident Report', path: 'fire-injury-report-chart' },
//     { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart' },
//     { name: 'Kaizen Report', path: 'kaizen-report-chart' },
//     { name: 'Work Permit', path: 'work-permit-chart' },
//   ];

//   React.useEffect(() => {
//     const currentPath = location.pathname.split('/').pop();
//     const index = reportTypes.findIndex(report => report.path === currentPath);
//     if (index !== -1) {
//       setValue(index);
//     }
//   }, [location]);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//     navigate(reportTypes[newValue].path);
//   };

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box display="flex" justifyContent="center" alignItems="center" mb={2} textAlign="center">
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>

//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         aria-label="report types tabs"
//       >
//         {reportTypes.map((report, index) => (
//           <Tab key={index} label={report.name} />
//         ))}
//       </Tabs>

//       <Box sx={{ p: 3 }}>
//         <Outlet />
//       </Box>
//     </Box>
//   );
// };

// export default AdminDashboard;

