// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// // import firebase from 'firebase/app';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import 'firebase/auth';
// import { setLoginStatusLocally, setUIDLocally } from './../utils/userUtils';
// //..
// import { useNavigate } from 'react-router-dom';
// import NavBar from './NavBarScreens/Navbar';

// const AuthenticationModule = () => {
//   const [isUserSignIn, setIsUserSignIn] = useState(true);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState(null);
//   //..
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const handleSignIn = async (e) => {
//     e.preventDefault();
//     if (!email || !password) {
//       setError('Please enter email and password');
//       return;
//     }

//     setIsLoading(true);
//     setError(null);
//     try {
//       const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
//       const user = userCredential.user;

//       if (isUserSignIn) {
//         const userDocRef = firebase.firestore().collection('peoples').doc('users').collection('users').doc(user.uid);
//         const userDoc = await userDocRef.get();
//         console.log('UserSignIn');
//         if (userDoc.exists) {
//           await Promise.all([setLoginStatusLocally(true), setUIDLocally(user.uid)]);
//           //window.location.href = '/profile';
//           //...
//           const userData = userDoc.data();
//           const { company_name, email, role, user_name, permissions } = userData;

//           // Store user data in localStorage
//           localStorage.setItem('userData', JSON.stringify({ userId: user.uid, company_name, email, role, user_name, permissions }));

//           navigate('/profile', { state: { userId: user.uid, company_name, email, role, user_name, permissions } });
//           console.log('UserSignIn success');
//         } else {
//           setError('User not found');
//         }
//       } else {
//         const adminDocRef = firebase.firestore().collection('admins').doc(user.uid);
//         const adminDoc = await adminDocRef.get();
//         console.log('AdminSignIn');
//         if (adminDoc.exists) {
//           await Promise.all([setLoginStatusLocally(true), setUIDLocally(user.uid)]);
//         // window.location.href = '/company-list';
//         //..
//         navigate('/company-list');
//           console.log('AdminSignIn success');
//         } else {
//           setError('Admin not found');
//         }
//       }
//     } catch (error) {
//       setError(error.message);
//     }finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
//      <NavBar />
//       <Row>
//         <Col md={6}>
//           <h2 className="text-center mb-4">{isUserSignIn ? 'User Sign-In' : 'Admin Sign-In'}</h2>
//           <Form onSubmit={handleSignIn}>
//             {error && <Alert variant="danger">{error}</Alert>}
//             <Form.Group controlId="formBasicEmail">
//               <Form.Label>Email address</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </Form.Group>

//             <Form.Group controlId="formBasicPassword">
//               <Form.Label>Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </Form.Group>

//             {/* <Button variant="primary" type="submit" className="w-100 mt-3">
//               Sign In
//             </Button> */}
//             <Button variant="primary" type="submit" className="w-100 mt-3" disabled={isLoading}>
//               {isLoading ? 'Signing In...' : 'Sign In'}
//             </Button>
//           </Form>
//           <div className="text-center mt-3">
//             <Button variant="link" onClick={() => setIsUserSignIn(!isUserSignIn)}>
//               {isUserSignIn ? 'Sign In as Admin' : 'Sign In as User'}
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default AuthenticationModule;

import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { setLoginStatusLocally, setUIDLocally } from "./../utils/userUtils";
import { useLocation, useNavigate } from "react-router-dom";

const AuthenticationModule = () => {
  // const [isUserSignIn, setIsUserSignIn] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isUserSignIn = location.pathname.includes("User"); // Determine role based on URL

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Please enter email and password");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (isUserSignIn) {
        const userDocRef = firebase
          .firestore()
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(user.uid);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          await Promise.all([
            setLoginStatusLocally(true),
            setUIDLocally(user.uid),
          ]);
          const userData = userDoc.data();
          const { company_name, email, role, user_name, permissions } =
            userData;

          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: user.uid,
              company_name,
              email,
              role,
              user_name,
              permissions,
            })
          );

          console.log("state1", company_name, role, permissions);
          // navigate('/profile', { state: { userId: user.uid, company_name, email, role, user_name, permissions } });
          navigate("/user/user", {
            state: {
              userId: user.uid,
              company_name,
              email,
              role,
              user_name,
              permissions,
            },
          });
        } else {
          setError("User not found");
        }
      } else {
        const adminDocRef = firebase
          .firestore()
          .collection("admins")
          .doc(user.uid);
        const adminDoc = await adminDocRef.get();
        if (adminDoc.exists) {
          await Promise.all([
            setLoginStatusLocally(true),
            setUIDLocally(user.uid),
          ]);
          navigate("/company-list");
        } else {
          setError("Admin not found");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {isUserSignIn ? "User Sign-In" : "Admin Sign-In"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSignIn}
            noValidate
            sx={{ mt: 1 }}
          >
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#28CC9E", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#28CC9E", // Border color when hovering
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28CC9E", // Border color when focused
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#28CC9E", // Set the label color
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: "#fff",
                backgroundColor: "#28CC9E",
                "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
              }}
              disabled={isLoading}
            >
              {isLoading ? "Signing In..." : "Sign In"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthenticationModule;
