import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import * as XLSX from 'xlsx';
import exportToExcel from '../../../functions/ExportToExcel';
import { getUIDLocally } from '../../../utils/userUtils';
const db = firebase.firestore();

const DetailedDataTable = ({ company_name }) => {
  const [userData, setUserData] = useState(null);
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const fetchAuditData = async () => {
      try {
        const db = firebase.firestore();
        const auditsRef = db
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('audits');
          

        const auditsSnapshot = await auditsRef.get();
        const auditsData = [];

        for (const auditDoc of auditsSnapshot.docs) {
          const auditData = auditDoc.data();
          const questionsRef = auditDoc.ref.collection('questions');
          const questionsSnapshot = await questionsRef.get();
          
          const questions = questionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          auditsData.push({
            id: auditDoc.id,
            ...auditData,
            questions
          });
        }

        setAuditData(auditsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching audit data:", error);
        setLoading(false);
      }
    };
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        const userDocRef = db.collection("peoples").doc("users").collection("users").doc(userId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchAuditData();
    fetchUserData();
  }, [company_name,userData]);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExportToExcel = useCallback(() => {
    if (userData && userData.company_name) {
      exportToExcel(userData.company_name);
    } else {
      alert("Company data not available for export");
    }
  }, [userData]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {auditData.map((audit, auditIndex) => (
        <Grid item xs={12} key={audit.id}>
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {audit.id}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Auditor Name: {audit.user_name}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Company Name: {company_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Date: {audit.date?.toDate().toLocaleDateString()}
                  </Typography>
                  <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleExportToExcel}>
          Export to Excel
        </Button>
      </Grid>
                </Box>
              </Box>
              
              <TableContainer component={Paper}>
                <Table size="small" aria-label="audit data table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N.</TableCell>
                      <TableCell>Question</TableCell>
                      <TableCell>Observation</TableCell>
                      <TableCell>Evidence</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audit.questions.map((question, questionIndex) => (
                      <TableRow key={question.id}>
                        <TableCell>{questionIndex + 1}</TableCell>
                        <TableCell>{question.questionText}</TableCell>
                        <TableCell>{question.answer}</TableCell>
                        <TableCell>
                          {question.imageUrl ? (
                            <Box
                              component="img"
                              src={question.imageUrl}
                              alt="Evidence"
                              sx={{
                                width: 50,
                                height: 50,
                                objectFit: 'cover',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleOpenModal(question.imageUrl)}
                            />
                          ) : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      ))}
      
      

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '90vw',
            maxHeight: '90vh',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Evidence"
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};

export default DetailedDataTable;