import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const FAQ = () => {
 

  return (
    <Box m="20px">
      <Accordion sx={{textAlign:'left',backgroundColor: "#C1F8CF"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{textAlign:'left',backgroundColor: "#C1F8CF"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            Another Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{textAlign:'left',backgroundColor: "#C1F8CF"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            Your Favorite Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{textAlign:'left',backgroundColor: "#C1F8CF"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            Some Random Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{textAlign:'left',backgroundColor: "#C1F8CF"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">
            The Final Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
