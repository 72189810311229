import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./AccidentReport/DetailedDataTable";

import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const AccidentReportChart = ({ company_name }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    monthlyReported: null,
    monthlyActionPlan: null,
    employeeType: null,
    genderRatio: null,
    bodyPartAffected: null,
    bodyPartActionPlan: null,
    typeOfHazard: null,
    hazardActionPlan: null,
  });
  const [selectedEmployeeType, setSelectedEmployeeType] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const [employeeTypeDataGlobal, setEmployeeTypeDataGlobal] = useState({});
  const [genderDataGlobal, setGenderDataGlobal] = useState({});

  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const [totals, setTotals] = useState({
    totalReported: 0,
    totalClosed: 0,
    totalInvestigated: 0,
    totalTimelyInvestigated: 0,
    totalActionPlanOpen: 0,
    totalActionPlanClosed: 0,
  });

  const chartRefs = useRef([]);

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === "string") {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return "Invalid Date";
  };

  //console.log('comapy1',company_name);
  const fetchData = async () => {
    setLoading(true);
    //console.log('company1',company_name);
    const db = firebase.firestore();
    const accidentReportsCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("accident_reports");
    //console.log('company1',company_name);
    const querySnapshot = await accidentReportsCollection.get();

    const monthlyData = {};
    const employeeTypeData = {
      "Company Employee": { minor: 0, major: 0, firstAid: 0 },
      "Contract Employee": { minor: 0, major: 0, firstAid: 0 },
    };
    const genderData = {
      male: { minor: 0, major: 0, firstAid: 0 },
      female: { minor: 0, major: 0, firstAid: 0 },
    };
    const bodyPartData = {};
    const hazardData = {};

    let totalReported = 0;
    let totalClosed = 0;
    let totalInvestigated = 0;
    let totalTimelyInvestigated = 0;
    let totalActionPlanOpen = 0;
    let totalActionPlanClosed = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // console.log('data', data);
      const {
        dateOfReporting,
        injuryCategory,
        affectedWorkmanEmploymentType,
        gender,
        bodyPartAffected,
        typeOfAccident,
      } = data;
      //const dateObj = new Date(dateOfReporting);
      //const dateObj = formatDate(dateOfReporting);

      const dateObj =
        dateOfReporting instanceof firebase.firestore.Timestamp
          ? dateOfReporting.toDate()
          : new Date(dateOfReporting);
      // console.log('dates',dateObj,startDate.toDate(),endDate);
      if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
        // Update monthly data
        //  console.log('hii');
        const monthLabel = `${dateObj.toLocaleString("default", {
          month: "short",
        })} ${dateObj.getFullYear()}`;
        if (!monthlyData[monthLabel]) {
          monthlyData[monthLabel] = {
            minor: 0,
            major: 0,
            firstAid: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        let injuryCat;
        //  console.log('injuryCatagory', injuryCategory);
        if (injuryCategory === "First Aid") injuryCat = "firstAid";
        else injuryCat = injuryCategory.toLowerCase();

        monthlyData[monthLabel][injuryCat]++;

        // // Update employee type data
        employeeTypeData[affectedWorkmanEmploymentType][injuryCat]++;

        // // Update gender data
        genderData[gender][injuryCat]++;

        //monthlyData[monthLabel][injuryCategory.toLowerCase()]++;

        // Update employee type data
        //  employeeTypeData[affectedWorkmanEmploymentType][injuryCategory.toLowerCase()]++;
        //   if ( injuryCategory === 'First Aid')
        //  console.log('injry category datas',employeeTypeData[affectedWorkmanEmploymentType][injuryCat],injuryCat)
        // Update gender data
        //  genderData[gender][injuryCategory.toLowerCase()]++;

        // Update body part data
        if (!bodyPartData[bodyPartAffected]) {
          bodyPartData[bodyPartAffected] = {
            count: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        bodyPartData[bodyPartAffected].count++;

        // Update hazard data
        if (!hazardData[typeOfAccident]) {
          hazardData[typeOfAccident] = {
            count: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        hazardData[typeOfAccident].count++;

        // Check if investigated
        if (data.DateOfInvestigation) {
          totalInvestigated++;

          // Check if timely investigated
          const investigationDate = new Date(data.DateOfInvestigation);
          const closingDate = new Date(data.dateOfClosing);
          const daysDifference =
            (closingDate - investigationDate) / (1000 * 60 * 60 * 24);

          if (daysDifference <= 2) {
            totalTimelyInvestigated++;
          }
        }

        // Update action plan data (assuming action plans are stored similarly to near miss reports)
        if (data.actionPlans && Array.isArray(data.actionPlans)) {
          data.actionPlans.forEach((plan) => {
            if (plan.status === "Open") {
              monthlyData[monthLabel].actionPlanOpen++;
              bodyPartData[bodyPartAffected].actionPlanOpen++;
              hazardData[typeOfAccident].actionPlanOpen++;
              totalActionPlanOpen++;
            } else if (plan.status === "Close") {
              monthlyData[monthLabel].actionPlanClosed++;
              bodyPartData[bodyPartAffected].actionPlanClosed++;
              hazardData[typeOfAccident].actionPlanClosed++;
              totalActionPlanClosed++;
            }
          });
        }

        // Update totals
        totalReported++;
        if (!data.isPending) totalClosed++;
      }
    });
    //console.log('company1',company_name);
    const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    // console.log('debug',employeeTypeData['Company Employee'])
    setEmployeeTypeDataGlobal(employeeTypeData);
    setGenderDataGlobal(genderData);
    setChartData({
      monthlyReported: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Minor",
            data: sortedMonthLabels.map((label) => monthlyData[label].minor),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Major",
            data: sortedMonthLabels.map((label) => monthlyData[label].major),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "First Aid",
            data: sortedMonthLabels.map((label) => monthlyData[label].firstAid),
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
          },
        ],
      },
      monthlyActionPlan: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Action Plan Open",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].actionPlanOpen
            ),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Action Plan Closed",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].actionPlanClosed
            ),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      employeeType: {
        labels: Object.keys(employeeTypeData),
        datasets: [
          {
            data: Object.values(employeeTypeData).map((type) =>
              Object.values(type).reduce((a, b) => a + b, 0)
            ),
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(53, 162, 235, 0.5)",
            ],
          },
        ],
      },
      genderRatio: {
        labels: Object.keys(genderData),
        datasets: [
          {
            data: Object.values(genderData).map((gender) =>
              Object.values(gender).reduce((a, b) => a + b, 0)
            ),
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(53, 162, 235, 0.5)",
            ],
          },
        ],
      },
      bodyPartAffected: {
        labels: Object.keys(bodyPartData),
        datasets: [
          {
            data: Object.values(bodyPartData).map((part) => part.count),
            backgroundColor: Object.keys(bodyPartData).map(
              () =>
                `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, 0.5)`
            ),
          },
        ],
      },
      bodyPartActionPlan: {
        labels: Object.keys(bodyPartData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(bodyPartData).map(
              (part) => part.actionPlanOpen
            ),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Action Plan Closed",
            data: Object.values(bodyPartData).map(
              (part) => part.actionPlanClosed
            ),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      typeOfHazard: {
        labels: Object.keys(hazardData),
        datasets: [
          {
            data: Object.values(hazardData).map((hazard) => hazard.count),
            backgroundColor: Object.keys(hazardData).map(
              () =>
                `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, 0.5)`
            ),
          },
        ],
      },
      hazardActionPlan: {
        labels: Object.keys(hazardData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(hazardData).map(
              (hazard) => hazard.actionPlanOpen
            ),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Action Plan Closed",
            data: Object.values(hazardData).map(
              (hazard) => hazard.actionPlanClosed
            ),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
    });
    setTotals({
      totalReported,
      totalClosed,
      totalInvestigated,
      totalTimelyInvestigated,
      totalActionPlanOpen,
      totalActionPlanClosed,
    });
    setLoading(false);
  };
  // console.log('chartdatas',chartData.monthlyReported,chartData.monthlyActionPlan,
  //   chartData.employeeType,chartData.genderRatio,chartData.bodyPartActionPlan,
  //   chartData.bodyPartAffected,chartData.typeOfHazard,chartData.hazardActionPlan
  // )

  const handleEmployeeTypeClick = (employeeType) => {
    setSelectedEmployeeType(employeeType);
  };

  const handleGenderClick = (gender) => {
    setSelectedGender(gender);
  };

  const getEmployeeTypeBreakdownData = (type) => {
    const dataObj = employeeTypeDataGlobal[type];
    //const dataObj = chartData.employeeType[type];
    if (dataObj) {
      return [dataObj.minor, dataObj.major, dataObj.firstAid];
    }
    return [0, 0, 0];
  };

  const getGenderBreakdownData = (gender) => {
    const dataObj = genderDataGlobal[gender];
    // const dataObj = chartData.genderRatio[gender];
    if (dataObj) {
      return [dataObj.minor, dataObj.major, dataObj.firstAid];
    }
    return [0, 0, 0];
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    let yOffset = 10;

    const addChartToPDF = (chartRef, title) => {
      return html2canvas(chartRef).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight > pageHeight) {
          pdf.addPage();
          yOffset = 10;
        }

        pdf.text(title, 10, yOffset);
        yOffset += 10;
        pdf.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + 10;
      });
    };

    const chartRefs = document.querySelectorAll(".chart-container");
    const chartPromises = Array.from(chartRefs).map((ref, index) =>
      addChartToPDF(ref, `Chart `)
    );

    Promise.all(chartPromises).then(() => {
      pdf.save("accident_reports.pdf");
    });
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Accident Reports" },
    },
  };

  // const handleDetailedDataClick = (dataType) => {
  //   setSelectedDataType(dataType);
  //   setOpenDialog(true);
  // };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };


  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}`,
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 3000,
      easing: "easeInOutExpo",
    },
  };

  const stackedBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1200,
      easing: "easeInOutCubic",
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Categories",
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Values",
          font: {
            size: 14,
          },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
  };

  // const handleFullScreen = (index) => {
  //   if (fullScreenChart === index) {
  //     setFullScreenChart(null);
  //   } else {
  //     setFullScreenChart(index);
  //   }
  // };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };

  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <ChartContainer elevation={1} sx={{ p: 2, position: "relative" }}>
        <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
          <IconButton
            onClick={() => handleClickOpen(index, title, chartComponent)}
          >
            <FullscreenIcon />
          </IconButton>
          {!isFullScreen && (
            <IconButton onClick={() => handleResize(index)}>
              <AspectRatioIcon />
            </IconButton>
          )}
        </Box>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Box
          sx={{
            height: isFullScreen
              ? "calc(100% - 60px)"
              : chartSizes[index].height,
            width: "100%",
          }}
        >
          {chartComponent}
        </Box>
      </ChartContainer>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Accident Report
      </Typography>
      <Box mb={2}>
        <Grid container spacing={2}>
        <CustomCard
            bannerText="Total Accident Report"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("reported")}
            onCloseClick={() => handleTotalClick("investigated")}
            openTitle="Reported"
            openValue={totals.totalReported}
            closedTitle="Investigated"
            closedValue={totals.totalInvestigated}
            subtitleColor="text.secondary"
          />
          <CustomCard
            bannerText="Total Accident Action Plan"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("actionPlanOpen")}
            onCloseClick={() => handleTotalClick("actionPlanClosed")}
            openTitle="Reported"
            openValue={totals.totalActionPlanOpen}
            closedTitle="Investigated"
            closedValue={totals.totalActionPlanClosed}
            subtitleColor="text.secondary"
          />
              <CustomCard
                bannerText="Accident Report closed"
                bannerColor="#20bf55"
                bannerTextColor="#f8f9fa"
                onCloseClick={() => handleTotalClick("close")}
                openTitle="open"
                openValue={totals.totalOpen}
                closedTitle="closed"
                closedValue={totals.totalClosed}
                subtitleColor="text.secondary"
          />
        </Grid>
      </Box>
      {/* <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={4}> */}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {currentChart?.title} - Full Screen
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {currentChart?.component}
          {/* {renderAdditionalInfo()} */}
          {/* Add additional information here */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Additional information about this chart can be displayed here.
          </Typography>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: { xs: 2, md: 0 },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid> */}
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
          </Grid>
        </Box>
        {/* <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" onClick={fetchData} fullWidth>
            Generate Charts
          </Button>
        </Grid> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
      </Box>
      {/* </Grid> */}
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Monthly Reported Accidents
              </Typography>
              {chartData.monthlyReported ? (
              <Line data={chartData.monthlyReported} options={chartOptions} />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
              
            </Paper> 
          </Grid> */}
          <Grid item xs={12} md={9} className="chart-container">
            {renderChart(
              0,
              "Monthly Reported Accidents",
              chartData.monthlyReported ? (
                <Line
                  data={chartData.monthlyReported}
                  options={lineChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          <Grid item xs={12} md={9} className="chart-container">
            {renderChart(
              0,
              "Monthly Action Plan Open vs Closed",
              chartData.monthlyActionPlan ? (
                <Line
                  data={chartData.monthlyActionPlan}
                  options={lineChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              1,
              "Affected Workman Employee Type",
              chartData.employeeType ? (
                <Pie
                  data={chartData.employeeType}
                  // options={pieOptions}
                  options={{
                    ...pieOptions,
                    onClick: (_, elements) => {
                      if (elements.length > 0) {
                        const { index } = elements[0];
                        handleEmployeeTypeClick(
                          chartData.employeeType.labels[index]
                        );
                      }
                    },
                  }}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          {selectedEmployeeType && (
            <Grid item xs={12} md={6} className="chart-container">
              {renderChart(
                1,
                selectedEmployeeType + " Breakdown",
                <Pie
                  data={{
                    labels: ["Minor", "Major", "First Aid"],
                    datasets: [
                      {
                        data: getEmployeeTypeBreakdownData(
                          selectedEmployeeType
                        ),
                        //data: Object.values(chartData.employeeType.datasets[0].data[chartData.employeeType.labels.indexOf(selectedEmployeeType)]),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.5)",
                          "rgba(53, 162, 235, 0.5)",
                          "rgba(75, 192, 192, 0.5)",
                        ],
                      },
                    ],
                  }}
                  options={pieOptions}
                />
              )}
            </Grid>
          )}

          {/* <Grid item xs={12} md={6}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Male vs Female Ratio
              </Typography>
              {chartData.genderRatio ? (
              <Pie 
                data={chartData.genderRatio} 
                options={{
                  ...chartOptions,
                  onClick: (_, elements) => {
                    if (elements.length > 0) {
                      const { index } = elements[0];
                      handleGenderClick(chartData.genderRatio.labels[index]);
                    }
                  }
                }} 
              />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
            </Paper>
          </Grid> */}

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              2,
              "Male vs Female Ratio",
              chartData.genderRatio ? (
                <Pie
                  data={chartData.genderRatio}
                  options={{
                    ...pieOptions,
                    onClick: (_, elements) => {
                      if (elements.length > 0) {
                        const { index } = elements[0];
                        handleGenderClick(chartData.genderRatio.labels[index]);
                      }
                    },
                  }}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          {/* {selectedGender && (
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className="chart-container">
                <Typography variant="h6" align="center" gutterBottom>
                  {selectedGender} Injury Breakdown
                </Typography>
                <Pie 
                  data={{
                    labels: ['Minor', 'Major', 'First Aid'],
                    datasets: [{
                     // data: Object.values(chartData.genderRatio.datasets[0].data[chartData.genderRatio.labels.indexOf(selectedGender)]),
                      data: getGenderBreakdownData(selectedGender),
                      backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(53, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'],
                    }]
                  }}
                  options={chartOptions}
                />
              </Paper>
            </Grid>
          )} */}

          {selectedGender && (
            <Grid item xs={12} md={6} className="chart-container">
              {renderChart(
                2,
                selectedGender + " Breakdown",
                <Pie
                  data={{
                    labels: ["Minor", "Major", "First Aid"],
                    datasets: [
                      {
                        // data: Object.values(chartData.genderRatio.datasets[0].data[chartData.genderRatio.labels.indexOf(selectedGender)]),
                        data: getGenderBreakdownData(selectedGender),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.5)",
                          "rgba(53, 162, 235, 0.5)",
                          "rgba(75, 192, 192, 0.5)",
                        ],
                      },
                    ],
                  }}
                  options={pieOptions}
                />
              )}
            </Grid>
          )}

          {/* <Grid item xs={5}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Body Part Affected
              </Typography>
              {chartData.bodyPartAffected ? (
              <Pie data={chartData.bodyPartAffected} options={chartOptions} />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
            </Paper>
          </Grid> */}

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              2,
              "Body Part Affected",
              chartData.bodyPartAffected ? (
                <Pie data={chartData.bodyPartAffected} options={pieOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          {/* <Grid item xs={5}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Action Plan Open vs Closed for Body Parts Affected
              </Typography>
              {chartData.bodyPartActionPlan ? (
              <Bar data={chartData.bodyPartActionPlan} options={{
                ...chartOptions,
                scales: {
                  x: { stacked: true },
                  y: { stacked: true }
                }
              }} />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
            </Paper>
          </Grid> */}

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              2,
              "Action Plan Open vs Closed for Body Parts Affected",
              chartData.bodyPartActionPlan ? (
                <Bar
                  data={chartData.bodyPartActionPlan}
                  options={stackedBarChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          {/* <Grid item xs={5}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Type of Hazard
              </Typography>
              {chartData.typeOfHazard ? (
              <Pie data={chartData.typeOfHazard} options={chartOptions} />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
            </Paper>
          </Grid> */}

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              2,
              "Type of Hazard",
              chartData.typeOfHazard ? (
                <Pie data={chartData.typeOfHazard} options={pieOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          {/* <Grid item xs={5}>
            <Paper elevation={3} className="chart-container">
              <Typography variant="h6" align="center" gutterBottom>
                Action Plan Open vs Closed for Hazard Types
              </Typography>
              {chartData.hazardActionPlan ? (
              <Bar data={chartData.hazardActionPlan} options={{
                ...chartOptions,
                scales: {
                  x: { stacked: true },
                  y: { stacked: true }
                }
              }} />
              ) : (
                <Typography variant="body1" align="center">
                  No data available for this period.
                </Typography>
              )}
            </Paper>
          </Grid> */}

          <Grid item xs={12} md={6} className="chart-container">
            {renderChart(
              2,
              "Action Plan Open vs Closed for Hazard Types",
              chartData.hazardActionPlan ? (
                <Bar
                  data={chartData.hazardActionPlan}
                  options={stackedBarChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
        </Grid>
      )}
      {/* <Box mt={3}>
        <Button variant="contained" color="secondary" onClick={handleExportPDF}>
          Export as PDF
        </Button>
      </Box> */}
      {/* <Box mt={3}>
        <Typography variant="h6" gutterBottom>Detailed Data</Typography>
        <Button color="primary" onClick={() => handleDetailedDataClick('accidents')}>
          View Detailed Accident Data
        </Button>
        <Button color="primary" onClick={() => handleDetailedDataClick('actionPlans')}>
          View Detailed Action Plan Data
        </Button>
      </Box> */}

      {/* <Box mt={3}>
            <Typography variant="h6" gutterBottom>Totals</Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('reported')}>
                Total Reported: {totals.totalReported}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('closed')}>
                Total Closed: {totals.totalClosed}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('investigated')}>
                Total Investigated: {totals.totalInvestigated}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('actionPlanOpen')}>
                Total Action Plan Open: {totals.totalActionPlanOpen}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('actionPlanClosed')}>
                Total Action Plan Closed: {totals.totalActionPlanClosed}
              </Button>
            </Typography>
          </Box> */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Detailed Data</DialogTitle>
        <DialogContent>
          <DetailedDataTable
            companyName={company_name}
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
            dataType={selectedDataType}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccidentReportChart;
