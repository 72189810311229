// import React from 'react';
// import { Routes, Route, useLocation } from 'react-router-dom';
// import { Container, Row, Col } from 'react-bootstrap';
// import UserDrawer from './UserDrawer';

// // Import your user components here
// import Dashboard from './users/Dashboard';
// import NearMissReport from './users/NearMissReport';
// import UnsafeActReport from './users/UnsafeActReport';
// import AccidentReport from './users/AccidentReport';
// import WeeklySafetyMeeting from './users/WeeklySafetyMeeting';
// import FireInjuryReport from './users/FireInjuryReport';
// import PPEGrievancesReport from './users/PPEGrievancesReport';
// import KaizenReport from './users/KaizenReport';
// import WorkPermit from './users/WorkPermit';

// const UserRouter = () => {
//   const location = useLocation();
//   const { userId, email, company_name, permissions, user_name } = location.state || {};
// // console.log(permissions);
// // console.log('permission2',permissions);

// console.log("UserRouter state:", location.state); // Add this line for debugging

//   // Add this check
//   if (!permissions) {
//     console.error("Permissions are undefined in UserRouter");
//     return <div>Error: Unable to load user data. Please try logging in again.</div>;
//   }

//   return (
//     <Container fluid>
//       <Row>
//         <Col md={3}>
//           <UserDrawer permissions={permissions} />
//         </Col>
//         <Col md={9}>
//           <Routes>
//             <Route path="/" element={<Dashboard userId={userId} company_name={company_name} />} />
//             {permissions.includes('Near Miss Report') && (
//               <Route path="near-miss-report" element={<NearMissReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Unsafe Act') && (
//               <Route path="unsafe-act-report" element={<UnsafeActReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Accident Report') && (
//               <Route path="accident-report" element={<AccidentReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Weekly Safety Meeting') && (
//               <Route path="weekly-safety-meeting" element={<WeeklySafetyMeeting userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Fire Injury') && (
//               <Route path="fire-injury-report" element={<FireInjuryReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('PPE Grievances') && (
//               <Route path="ppe-grievances-report" element={<PPEGrievancesReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Kaizen Reports') && (
//               <Route path="kaizen-report" element={<KaizenReport userId={userId} email={email} company_name={company_name} />} />
//             )}
//             {permissions.includes('Work Permit') && (
//               <Route path="work-permit" element={<WorkPermit userId={userId} email={email} company_name={company_name} />} />
//             )}
//           </Routes>
//         </Col>
//       </Row>
//     </Container>
//   );
// };
// export default UserRouter;

import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import UserDrawer from "./UserDrawer";

// Import your user components here
import Dashboard from "./users/Dashboard";
import NearMissReport from "./users/NearMissReport";
import UnsafeActReport from "./users/UnsafeActReport";
import AccidentReport from "./users/AccidentReport";
import WeeklySafetyMeeting from "./users/WeeklySafetyMeeting";
import FireInjuryReport from "./users/FireInjuryReport";
import PPEGrievancesReport from "./users/PPEGrievancesReport";
import KaizenReport from "./users/KaizenReport";
import WorkPermit from "./users/WorkPermit";

// Import chart components
import NearMissChart from "./charts/NearMissChart";
import UnsafeActChart from "./charts/UnsafeActChart";
import AccidentReportChart from "./charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "./charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "./charts/FireInjuryChart";
import PPEGrievancesChart from "./charts/PPEGrievancesChart";
import KaizenReportChart from "./charts/KaizenReportChart";
import WorkPermitChart from "./charts/WorkPermitChart";
import { Box } from "@mui/material";

//Added manager companent

import FireInjuryList from "./managers/FireInjuryList";
import NearMissReportList from "./managers/NearMissReportList";
import PPEGrievancesList from "./managers/PPEGrievancesList";
import UnsafeActList from "./managers/UnsafeActList";
import WorkPermitList from "./managers/WorkPermitList";
import WorkPermitAuditorList from "./managers/WorkPermitAuditorList";
import KaizenReportsList from "./managers/KaizenReportsList";
import AccidentReportList from "./managers/AccidentReportList";
import AuditForm from "../componants/AuditForm";
import SafetyAuditQuestionnaire from "../componants/Audit";



const UserRouter = () => {
  // const location = useLocation();
  // const { userId, email, company_name, permissions, user_name } = location.state || {};

  // console.log("UserRouter state:", location.state);
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, email, company_name, permissions, user_name } =
    location.state || {};

  console.log("location user router", location.state);
  useEffect(() => {
    if (location.state) {
      localStorage.setItem("userData", JSON.stringify(location.state));
    } else {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        navigate(location.pathname, { state: parsedData, replace: true });
      }
    }
  }, [location, navigate]);

  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  if (!permissions) {
    console.error("Permissions are undefined in UserRouter");
    return (
      <div>Error1: Unable to load user data. Please try logging in again.</div>
    );
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh' }}>
      <Box>
        <UserDrawer permissions={permissions} />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Routes>
          <Route path="/" element={<Dashboard userId={userId} company_name={company_name} permissions={permissions} /> } />
          <Route path="/audit" element={<AuditForm userId={userId}/>} />
          <Route path="/safety-questionnaire" element={<SafetyAuditQuestionnaire company_name={company_name}/>} />
          {/* <Route path="/audit" element={<SafetyAuditQuestionnaire />} /> */}
          {permissions.includes("Near Miss Report") && (
            <>
              <Route
                path="near-miss-report"
                element={
                  <NearMissReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="near-miss-report-chart"
                element={
                  <NearMissChart userId={userId} company_name={company_name} />
                }
              />
            </>
          )}
          {permissions.includes("Unsafe Act") && (
            <>
              <Route
                path="unsafe-act-report"
                element={
                  <UnsafeActReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="unsafe-act-report-chart"
                element={
                  <UnsafeActChart userId={userId} company_name={company_name} />
                }
              />
            </>
          )}
          {permissions.includes("Accident Report") && (
            <>
              <Route
                path="accident-report"
                element={
                  <AccidentReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="accident-report-chart"
                element={
                  <AccidentReportChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Weekly Safety Meeting") && (
            <>
              <Route
                path="weekly-safety-meeting"
                element={
                  <WeeklySafetyMeeting
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="weekly-safety-meeting-chart"
                element={
                  <WeeklySafetyMeetingChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Fire Injury") && (
            <>
              <Route
                path="fire-injury-report"
                element={
                  <FireInjuryReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="fire-injury-report-chart"
                element={
                  <FireInjuryChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("PPE Grievances") && (
            <>
              <Route
                path="ppe-grievances-report"
                element={
                  <PPEGrievancesReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="ppe-grievances-report-chart"
                element={
                  <PPEGrievancesChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Kaizen Reports") && (
            <>
              <Route
                path="kaizen-report"
                element={
                  <KaizenReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="kaizen-report-chart"
                element={
                  <KaizenReportChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Work Permit") && (
            <>
              <Route
                path="work-permit"
                element={
                  <WorkPermit
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="work-permit-chart"
                element={
                  <WorkPermitChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {/* added for managers */}
          {userData.role === "manager" && (
            <>
              <Route
                path="/"
                element={
                  <Dashboard
                    userId={userId}
                    company_name={company_name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path="fire-injury-list"
                element={
                  <FireInjuryList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="near-miss-list"
                element={
                  <NearMissReportList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="ppe-grievances-list"
                element={
                  <PPEGrievancesList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="unsafe-act-list"
                element={
                  <UnsafeActList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="work-permit-list"
                element={
                  <WorkPermitList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="work-permit-auditor-list"
                element={
                  <WorkPermitAuditorList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="kaizen-reports-list"
                element={
                  <KaizenReportsList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="accident-report-list"
                element={
                  <AccidentReportList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
};

export default UserRouter;
