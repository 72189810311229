// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   CircularProgress,
//   Checkbox,
//   FormGroup,
//   FormControlLabel,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker, TimePicker } from '@material-ui/pickers';
// import { firestore } from '../../firebase/firebaseConfig';
// import firebase from 'firebase/compat/app';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const db = firebase.firestore();

// const AccidentReport = ({ userId, company_name }) => {
//   const classes = useStyles();
//   const [date, setDate] = useState(new Date());
//   const [time, setTime] = useState(new Date());
//   const [injuryCategory, setInjuryCategory] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [reportingPersonName, setReportingPersonName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [affectedWorkmenName, setAffectedWorkmenName] = useState('');
//   const [affectedWorkmanEmploymentType, setAffectedWorkmanEmploymentType] = useState([]);
//   const [contractorName, setContractorName] = useState('');
//   const [affectedWorkmenEmployeeId, setAffectedWorkmenEmployeeId] = useState('');
//   const [manpowerAffected, setManpowerAffected] = useState(1);
//   const [gender, setGender] = useState('');
//   const [dateOfJoining, setDateOfJoining] = useState(new Date());
//   const [dateOfReporting, setDateOfReporting] = useState(new Date());
//   const [timeOfReporting, setTimeOfReporting] = useState(new Date());
//   const [bodyPartAffected, setBodyPartAffected] = useState('');
//   const [typeOfAccident, setTypeOfAccident] = useState('');
//   const [descriptionOfInjury, setDescriptionOfInjury] = useState('');

//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [caseCount, setCaseCount] = useState(0);
//   const [BPAffected, setBPAffected] = useState([]);
//   const [toA, settoA] = useState([]);
//   const [iCategory, setICategory] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const unsubscribeAreas = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('company_zones').onSnapshot((snapshot) => {
//         const areaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setAreas(areaData);
//       });

//     const unsubscribeCaseCount = db
//       .collection('company_data')
//       .doc(company_name)
//       .onSnapshot((doc) => {
//         if (doc.exists) {
//           setCaseCount(doc.data().case_count || 0);
//         }
//       });

//     const unsubscribeReportingPersonDesignation = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('reporting_person_designation').onSnapshot((snapshot) => {
//         const rpdData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setrpDesignation(rpdData);
//       });

//     const unsubscribbodyPartAffectedOptions = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('body_part_affected').onSnapshot((snapshot) => {
//         const bpaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setBPAffected(bpaData);
//       });

//     const unsubscriTOA = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('type_of_accident').onSnapshot((snapshot) => {
//         const toaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         settoA(toaData);
//       });

//     const unsubscribIC = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('injury_category').onSnapshot((snapshot) => {
//         const icData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setICategory(icData);
//       });

//     return () => {
//       unsubscribeAreas();
//       unsubscribeCaseCount();
//       unsubscribeReportingPersonDesignation();
//       unsubscribbodyPartAffectedOptions();
//       unsubscriTOA();
//       unsubscribIC();
//     };
//   }, [company_name]);

//   useEffect(() => {
//     const fetchReports = async () => {
//       const reportsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('accident_reports')
//         .where('userId', '==', userId)
//         .get();
  
//       const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setReports(reportsData);
//     };
//     fetchReports();
//   },[userId]);

//   const validate = () => {
//     const errors = {};

//     if (!date) errors.date = 'Date of Injury/Incident is required';
//     if (!time) errors.time = 'Time of Event is required';
//     if (!injuryCategory) errors.injuryCategory = 'Injury Category is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!affectedWorkmenName) errors.affectedWorkmenName = 'Affected Workmen Name is required';
//     if (affectedWorkmanEmploymentType.length === 0) errors.affectedWorkmanEmploymentType = 'Affected Workmen Employment Type is required';
//     if (!manpowerAffected) errors.manpowerAffected = 'Total no. of Manpower affected in a Injury is required';
//     if (!gender) errors.gender = 'Gender is required';
//     if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting of Injury/Incident is required';
//     if (!timeOfReporting) errors.timeOfReporting = 'Time of Reporting of Injury/Incident is required';
//     if (!bodyPartAffected) errors.bodyPartAffected = 'Body Part Affected is required';
//     if (!typeOfAccident) errors.typeOfAccident = 'Type of Accident/injury is required';
//     if (!descriptionOfInjury) errors.descriptionOfInjury = 'Description of Injury is required';

//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         await db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .add({
//             date,
//             time,
//             injuryCategory,
//             areaName,
//             reportingPersonName,
//             employeeId,
//             reportingPersonDesignation,
//             affectedWorkmenName,
//             affectedWorkmanEmploymentType,
//             contractorName,
//             affectedWorkmenEmployeeId,
//             manpowerAffected,
//             gender,
//             dateOfJoining,
//             dateOfReporting,
//             timeOfReporting,
//             bodyPartAffected,
//             typeOfAccident,
//             descriptionOfInjury,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//         await db.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });

//         const detailRef = db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .doc('details');

//         const detailRefDoc = await detailRef.get();

//         if (detailRefDoc.exists) {
//           await detailRef.delete();
//         }

//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (e) {
//         console.error('Error adding document: ', e);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDate(new Date());
//     setTime(new Date());
//     setInjuryCategory('');
//     setAreaName('');
//     setReportingPersonName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setAffectedWorkmenName('');
//     setAffectedWorkmanEmploymentType([]);
//     setContractorName('');
//     setAffectedWorkmenEmployeeId('');
//     setManpowerAffected(1);
//     setGender('');
//     setDateOfJoining(new Date());
//     setDateOfReporting(new Date());
//     setTimeOfReporting(new Date());
//     setBodyPartAffected('');
//     setTypeOfAccident('');
//     setDescriptionOfInjury('');
//     setErrors({});
//   };

//   const affectedWorkmanEmploymentTypeOptions = [
//     { label: 'Company Employee', value: 'Company Employee' },
//     { label: 'Contract Employee', value: 'Contract Employee' },
//   ];

//   const genderOptions = [
//     { label: 'Male', value: 'male' },
//     { label: 'Female', value: 'female' },
//   ];

//   const typeOfInjuryOptions = [
//     { label: 'First Aid', value: 'First Aid' },
//     { label: 'Minor', value: 'Minor' },
//     { label: 'Major', value: 'Major' },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           Accident Report
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of Injury/Incident"
//                 value={date.toString()}
//                 onChange={setDate}
//                 fullWidth
//                 error={!!errors.date}
//                 helperText={errors.date}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TimePicker
//                 label="Time of Event"
//                 value={time}
//                 onChange={setTime}
//                 fullWidth
//                 error={!!errors.time}
//                 helperText={errors.time}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.injuryCategory}>
//                 <InputLabel>Injury Category</InputLabel>
//                 <Select
//                   value={injuryCategory}
//                   onChange={(e) => setInjuryCategory(e.target.value)}
//                 >
//                   {typeOfInjuryOptions.map((option) => (
//                     <MenuItem key={option.value} value={option.value}>
//                       {option.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.injuryCategory && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.injuryCategory}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Area Name</InputLabel>
//                 <Select
//                   value={areaName}
//                   onChange={(e) => setAreaName(e.target.value)}
//                 >
//                   {areas.map((area) => (
//                     <MenuItem key={area.id} value={area.name}>
//                       {area.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.areaName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.areaName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Reporting Person Name"
//                 value={reportingPersonName}
//                 onChange={(e) => setReportingPersonName(e.target.value)}
//                 fullWidth
//                 error={!!errors.reportingPersonName}
//                 helperText={errors.reportingPersonName}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Employee ID"
//                 value={employeeId}
//                 onChange={(e) => setEmployeeId(e.target.value)}
//                 fullWidth
//                 error={!!errors.employeeId}
//                 helperText={errors.employeeId}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
//                 <InputLabel>Reporting Person Designation</InputLabel>
//                 <Select
//                   value={reportingPersonDesignation}
//                   onChange={(e) => setReportingPersonDesignation(e.target.value)}
//                 >
//                   {rpDesignation.map((designation) => (
//                     <MenuItem key={designation.id} value={designation.name}>
//                       {designation.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.reportingPersonDesignation && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.reportingPersonDesignation}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Affected Workmen Name"
//                 value={affectedWorkmenName}
//                 onChange={(e) => setAffectedWorkmenName(e.target.value)}
//                 fullWidth
//                 error={!!errors.affectedWorkmenName}
//                 helperText={errors.affectedWorkmenName}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControl component="fieldset" error={!!errors.affectedWorkmanEmploymentType}>
//                 <Typography variant="subtitle1">Affected Workman Employment Type</Typography>
//                 <FormGroup>
//                   {affectedWorkmanEmploymentTypeOptions.map((option) => (
//                     <FormControlLabel
//                       key={option.value}
//                       control={
//                         <Checkbox
//                           checked={affectedWorkmanEmploymentType.includes(option.value)}
//                           onChange={(e) => {
//                             if (e.target.checked) {
//                               setAffectedWorkmanEmploymentType([...affectedWorkmanEmploymentType, option.value]);
//                             } else {
//                               setAffectedWorkmanEmploymentType(affectedWorkmanEmploymentType.filter(type => type !== option.value));
//                             }
//                           }}
//                         />
//                       }
//                       label={option.label}
//                     />
//                   ))}
//                 </FormGroup>
//                 {errors.affectedWorkmanEmploymentType && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.affectedWorkmanEmploymentType}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             {affectedWorkmanEmploymentType.includes('Contract Employee') && (
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   label="Contractor Name"
//                   value={contractorName}
//                   onChange={(e) => setContractorName(e.target.value)}
//                   fullWidth
//                 />
//               </Grid>
//             )}
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Affected Workmen Employee ID"
//                 value={affectedWorkmenEmployeeId}
//                 onChange={(e) => setAffectedWorkmenEmployeeId(e.target.value)}
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.gender}>
//                 <InputLabel>Gender</InputLabel>
//                 <Select
//                   value={gender}
//                   onChange={(e) => setGender(e.target.value)}
//                 >
//                   {genderOptions.map((option) => (
//                     <MenuItem key={option.value} value={option.value}>
//                       {option.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.gender && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.gender}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of Joining"
//                 value={dateOfJoining}
//                 onChange={setDateOfJoining}
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date of Reporting of Injury/Incident"
//                 value={dateOfReporting}
//                 onChange={setDateOfReporting}
//                 fullWidth
//                 error={!!errors.dateOfReporting}
//                 helperText={errors.dateOfReporting}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TimePicker
//                 label="Time of Reporting of Injury/Incident"
//                 value={timeOfReporting}
//                 onChange={setTimeOfReporting}
//                 fullWidth
//                 error={!!errors.timeOfReporting}
//                 helperText={errors.timeOfReporting}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.bodyPartAffected}>
//                 <InputLabel>Body Part Affected</InputLabel>
//                 <Select
//                   value={bodyPartAffected}
//                   onChange={(e) => setBodyPartAffected(e.target.value)}
//                 >
//                   {BPAffected.map((bpa) => (
//                     <MenuItem key={bpa.id} value={bpa.name}>
//                       {bpa.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.bodyPartAffected && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.bodyPartAffected}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.typeOfAccident}>
//                 <InputLabel>Type of Hazard</InputLabel>
//                 <Select
//                   value={typeOfAccident}
//                   onChange={(e) => setTypeOfAccident(e.target.value)}
//                 >
//                   {toA.map((toa) => (
//                     <MenuItem key={toa.id} value={toa.name}>
//                       {toa.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.typeOfAccident && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.typeOfAccident}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="Description of Injury"
//                 value={descriptionOfInjury}
//                 onChange={(e) => setDescriptionOfInjury(e.target.value)}
//                 fullWidth
//                 multiline
//                 rows={4}
//                 error={!!errors.descriptionOfInjury}
//                 helperText={errors.descriptionOfInjury}
//               />
//             </Grid>
//           </Grid>
//           <div className={classes.buttonContainer}>
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               className={classes.submitButton}
//               disabled={isLoading}
//             >
//               {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//             </Button>
//             <Button variant="outlined" onClick={clearForm}>
//               Clear
//             </Button>
//           </div>
//         </form>
//       </Paper>

//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.date)} </Typography>
//                   <Typography>Description: {report.descriptionOfInjury} </Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>

//     </div>
//   );
// };

// export default AccidentReport;

// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   CircularProgress,
//   Checkbox,
//   FormGroup,
//   FormControlLabel,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box,
//   Container,
//   Snackbar,
// } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { styled } from '@mui/material/styles';
// import { firestore } from '../../firebase/firebaseConfig';
// import firebase from 'firebase/compat/app';
// import { format } from 'date-fns';


// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   marginBottom: theme.spacing(3),
// }));

// const StyledForm = styled('form')(({ theme }) => ({
//   '& .MuiTextField-root': {
//     marginBottom: theme.spacing(2),
//   },
//   '& .MuiFormControl-root': {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   marginRight: theme.spacing(2),
// }));

// const db = firebase.firestore();

// const AccidentReport = ({ userId, company_name }) => {
//   // ... [All your state variables and useEffects remain the same]
  
//   // const classes = useStyles();
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [date, setDate] = useState(new Date());
//   const [time, setTime] = useState(new Date());
//   const [injuryCategory, setInjuryCategory] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [reportingPersonName, setReportingPersonName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [affectedWorkmenName, setAffectedWorkmenName] = useState('');
//   const [affectedWorkmanEmploymentType, setAffectedWorkmanEmploymentType] = useState([]);
//   const [contractorName, setContractorName] = useState('');
//   const [affectedWorkmenEmployeeId, setAffectedWorkmenEmployeeId] = useState('');
//   const [manpowerAffected, setManpowerAffected] = useState(1);
//   const [gender, setGender] = useState('');
//   const [dateOfJoining, setDateOfJoining] = useState(new Date());
//   const [dateOfReporting, setDateOfReporting] = useState(new Date());
//   const [timeOfReporting, setTimeOfReporting] = useState(new Date());
//   const [bodyPartAffected, setBodyPartAffected] = useState('');
//   const [typeOfAccident, setTypeOfAccident] = useState('');
//   const [descriptionOfInjury, setDescriptionOfInjury] = useState('');

//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [caseCount, setCaseCount] = useState(0);
//   const [BPAffected, setBPAffected] = useState([]);
//   const [toA, settoA] = useState([]);
//   const [iCategory, setICategory] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const unsubscribeAreas = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('company_zones').onSnapshot((snapshot) => {
//         const areaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setAreas(areaData);
//       });

//     const unsubscribeCaseCount = db
//       .collection('company_data')
//       .doc(company_name)
//       .onSnapshot((doc) => {
//         if (doc.exists) {
//           setCaseCount(doc.data().case_count || 0);
//         }
//       });

//     const unsubscribeReportingPersonDesignation = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('reporting_person_designation').onSnapshot((snapshot) => {
//         const rpdData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setrpDesignation(rpdData);
//       });

//     const unsubscribbodyPartAffectedOptions = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('body_part_affected').onSnapshot((snapshot) => {
//         const bpaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setBPAffected(bpaData);
//       });

//     const unsubscriTOA = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('type_of_accident').onSnapshot((snapshot) => {
//         const toaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         settoA(toaData);
//       });

//     const unsubscribIC = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('injury_category').onSnapshot((snapshot) => {
//         const icData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setICategory(icData);
//       });

//     return () => {
//       unsubscribeAreas();
//       unsubscribeCaseCount();
//       unsubscribeReportingPersonDesignation();
//       unsubscribbodyPartAffectedOptions();
//       unsubscriTOA();
//       unsubscribIC();
//     };
//   }, [company_name]);

//   useEffect(() => {
//     const fetchReports = async () => {
//       const reportsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('accident_reports')
//         .where('userId', '==', userId)
//         .get();
  
//       const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setReports(reportsData);
//     };
//     fetchReports();
//   },[userId]);

//   const validate = () => {
//     const errors = {};

//     if (!date) errors.date = 'Date of Injury/Incident is required';
//     if (!time) errors.time = 'Time of Event is required';
//     if (!injuryCategory) errors.injuryCategory = 'Injury Category is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!affectedWorkmenName) errors.affectedWorkmenName = 'Affected Workmen Name is required';
//     if (affectedWorkmanEmploymentType.length === 0) errors.affectedWorkmanEmploymentType = 'Affected Workmen Employment Type is required';
//     if (!manpowerAffected) errors.manpowerAffected = 'Total no. of Manpower affected in a Injury is required';
//     if (!gender) errors.gender = 'Gender is required';
//     if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting of Injury/Incident is required';
//     if (!timeOfReporting) errors.timeOfReporting = 'Time of Reporting of Injury/Incident is required';
//     if (!bodyPartAffected) errors.bodyPartAffected = 'Body Part Affected is required';
//     if (!typeOfAccident) errors.typeOfAccident = 'Type of Accident/injury is required';
//     if (!descriptionOfInjury) errors.descriptionOfInjury = 'Description of Injury is required';

//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
//   // const formatDate = (timestamp) => {
//   //   if (typeof timestamp === 'string' && timestamp.includes('-')) {
//   //     return timestamp;
//   //   }
//   //   if (timestamp && timestamp.seconds) {
//   //     return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//   //   }
//   //   return 'Invalid Date';
//   // };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         await db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .add({
//             date,
//             time,
//             injuryCategory,
//             areaName,
//             reportingPersonName,
//             employeeId,
//             reportingPersonDesignation,
//             affectedWorkmenName,
//             affectedWorkmanEmploymentType,
//             contractorName,
//             affectedWorkmenEmployeeId,
//             manpowerAffected,
//             gender,
//             dateOfJoining,
//             dateOfReporting,
//             timeOfReporting,
//             bodyPartAffected,
//             typeOfAccident,
//             descriptionOfInjury,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//         await db.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });

//         const detailRef = db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .doc('details');

//         const detailRefDoc = await detailRef.get();

//         if (detailRefDoc.exists) {
//           await detailRef.delete();
//         }

//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (e) {
//         console.error('Error adding document: ', e);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDate(new Date());
//     setTime(new Date());
//     setInjuryCategory('');
//     setAreaName('');
//     setReportingPersonName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setAffectedWorkmenName('');
//     setAffectedWorkmanEmploymentType([]);
//     setContractorName('');
//     setAffectedWorkmenEmployeeId('');
//     setManpowerAffected(1);
//     setGender('');
//     setDateOfJoining(new Date());
//     setDateOfReporting(new Date());
//     setTimeOfReporting(new Date());
//     setBodyPartAffected('');
//     setTypeOfAccident('');
//     setDescriptionOfInjury('');
//     setErrors({});
//   };

//   const affectedWorkmanEmploymentTypeOptions = [
//     { label: 'Company Employee', value: 'Company Employee' },
//     { label: 'Contract Employee', value: 'Contract Employee' },
//   ];

//   const genderOptions = [
//     { label: 'Male', value: 'male' },
//     { label: 'Female', value: 'female' },
//   ];

//   const typeOfInjuryOptions = [
//     { label: 'First Aid', value: 'First Aid' },
//     { label: 'Minor', value: 'Minor' },
//     { label: 'Major', value: 'Major' },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const formatDate = (date) => {
//     if (date instanceof Date) {
//       return format(date, 'MMMM d, yyyy');
//     } else if (typeof date === 'string') {
//       return format(new Date(date), 'MMMM d, yyyy');
//     } else if (date && typeof date.toDate === 'function') {
//       return format(date.toDate(), 'MMMM d, yyyy');
//     }
//     return 'Invalid Date';
//   };

//   // ... [handleSubmit, clearForm, and other functions remain the same]
//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   // Example function to show the Snackbar
//   // const showSnackbar = (message) => {
//   //   setSnackbarMessage(message);
//   //   setSnackbarOpen(true);
//   // };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <Container maxWidth="lg">
//         <StyledPaper elevation={3}>
//           <Typography variant="h4" gutterBottom>
//             Accident Report
//           </Typography>
//           <StyledForm onSubmit={handleSubmit}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <DatePicker
//                   label="Date of Injury/Incident"
//                   value={date}
//                   onChange={setDate}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       error={!!errors.date}
//                       helperText={errors.date}
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TimePicker
//                   label="Time of Event"
//                   value={time}
//                   onChange={setTime}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       error={!!errors.time}
//                       helperText={errors.time}
//                     />
//                   )}
//                 />
//               </Grid>
//               {/* ... [Continue with all your form fields] */}
//               <Grid item xs={12}>
//                 <TextField
//                   label="Description of Injury"
//                   value={descriptionOfInjury}
//                   onChange={(e) => setDescriptionOfInjury(e.target.value)}
//                   fullWidth
//                   multiline
//                   rows={4}
//                   error={!!errors.descriptionOfInjury}
//                   helperText={errors.descriptionOfInjury}
//                 />
//               </Grid>
//             </Grid>
//             <Box mt={3}>
//               <StyledButton
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 disabled={isLoading}
//               >
//                 {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//               </StyledButton>
//               <Button variant="outlined" onClick={clearForm} disabled={isLoading}>
//                 Clear
//               </Button>
//             </Box>
//           </StyledForm>
//         </StyledPaper>

//         <Paper sx={{ marginTop: 4 }}>
//           <Tabs value={tabValue} onChange={handleTabChange} centered>
//             <Tab label="Pending Reports" />
//             <Tab label="Completed Reports" />
//           </Tabs>
//           <Box p={3}>
//             {tabValue === 0 && (
//               <Grid container spacing={3}>
//                 {reports.filter(report => report.isPending).map((report) => (
//                   <Grid item xs={12} sm={6} md={4} key={report.id}>
//                     <Card>
//                       <CardContent>
//                         <Typography variant="h6" gutterBottom>
//                           Case Number: {report.caseNumber}
//                         </Typography>
//                         <Typography>
//                           Date of Incident: {formatDate(report.date)}
//                         </Typography>
//                         <Typography>
//                           Area: {report.areaName}
//                         </Typography>
//                         <Typography>
//                           Injury Category: {report.injuryCategory}
//                         </Typography>
//                         <Typography color="error">
//                           Status: Pending
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//             {tabValue === 1 && (
//               <Grid container spacing={3}>
//                 {reports.filter(report => !report.isPending).map((report) => (
//                   <Grid item xs={12} sm={6} md={4} key={report.id}>
//                     <Card>
//                       <CardContent>
//                         <Typography variant="h6" gutterBottom>
//                           Case Number: {report.caseNumber}
//                         </Typography>
//                         <Typography>
//                           Date of Incident: {formatDate(report.date)}
//                         </Typography>
//                         <Typography>
//                           Area: {report.areaName}
//                         </Typography>
//                         <Typography>
//                           Injury Category: {report.injuryCategory}
//                         </Typography>
//                         <Typography color="success">
//                           Status: Completed
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//           </Box>
//         </Paper>
//       </Container>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//         message={snackbarMessage}
//       />
//     </LocalizationProvider>
//   );
// };

// export default AccidentReport;


// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   CircularProgress,
//   Checkbox,
//   FormGroup,
//   FormControlLabel,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box,
//   Container,
//   Snackbar,
// } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { styled } from '@mui/material/styles';
// import { firestore } from '../../firebase/firebaseConfig';
// import firebase from 'firebase/compat/app';
// import { format } from 'date-fns';

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   marginBottom: theme.spacing(3),
// }));

// const StyledForm = styled('form')(({ theme }) => ({
//   '& .MuiTextField-root': {
//     marginBottom: theme.spacing(2),
//   },
//   '& .MuiFormControl-root': {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   marginRight: theme.spacing(2),
// }));

// const db = firebase.firestore();

// const AccidentReport = ({ userId, company_name }) => {
//   // ... [All your state variables remain the same]

//     // const classes = useStyles();
//   const [date, setDate] = useState(new Date());
//   const [time, setTime] = useState(new Date());
//   const [injuryCategory, setInjuryCategory] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [reportingPersonName, setReportingPersonName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [affectedWorkmenName, setAffectedWorkmenName] = useState('');
//   const [affectedWorkmanEmploymentType, setAffectedWorkmanEmploymentType] = useState([]);
//   const [contractorName, setContractorName] = useState('');
//   const [affectedWorkmenEmployeeId, setAffectedWorkmenEmployeeId] = useState('');
//   const [manpowerAffected, setManpowerAffected] = useState(1);
//   const [gender, setGender] = useState('');
//   const [dateOfJoining, setDateOfJoining] = useState(new Date());
//   const [dateOfReporting, setDateOfReporting] = useState(new Date());
//   const [timeOfReporting, setTimeOfReporting] = useState(new Date());
//   const [bodyPartAffected, setBodyPartAffected] = useState('');
//   const [typeOfAccident, setTypeOfAccident] = useState('');
//   const [descriptionOfInjury, setDescriptionOfInjury] = useState('');

//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [caseCount, setCaseCount] = useState(0);
//   const [BPAffected, setBPAffected] = useState([]);
//   const [toA, settoA] = useState([]);
//   const [iCategory, setICategory] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const unsubscribeAreas = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('company_zones').onSnapshot((snapshot) => {
//         const areaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setAreas(areaData);
//       });

//     const unsubscribeCaseCount = db
//       .collection('company_data')
//       .doc(company_name)
//       .onSnapshot((doc) => {
//         if (doc.exists) {
//           setCaseCount(doc.data().case_count || 0);
//         }
//       });

//     const unsubscribeReportingPersonDesignation = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('reporting_person_designation').onSnapshot((snapshot) => {
//         const rpdData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setrpDesignation(rpdData);
//       });

//     const unsubscribbodyPartAffectedOptions = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('body_part_affected').onSnapshot((snapshot) => {
//         const bpaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setBPAffected(bpaData);
//       });

//     const unsubscriTOA = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('type_of_accident').onSnapshot((snapshot) => {
//         const toaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         settoA(toaData);
//       });

//     const unsubscribIC = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
//       .collection('injury_category').onSnapshot((snapshot) => {
//         const icData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
//         setICategory(icData);
//       });

//     return () => {
//       unsubscribeAreas();
//       unsubscribeCaseCount();
//       unsubscribeReportingPersonDesignation();
//       unsubscribbodyPartAffectedOptions();
//       unsubscriTOA();
//       unsubscribIC();
//     };
//   }, [company_name]);

//   useEffect(() => {
//     const fetchReports = async () => {
//       const reportsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('accident_reports')
//         .where('userId', '==', userId)
//         .get();
  
//       const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setReports(reportsData);
//     };
//     fetchReports();
//   },[userId]);

//   const validate = () => {
//     const errors = {};

//     if (!date) errors.date = 'Date of Injury/Incident is required';
//     if (!time) errors.time = 'Time of Event is required';
//     if (!injuryCategory) errors.injuryCategory = 'Injury Category is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!affectedWorkmenName) errors.affectedWorkmenName = 'Affected Workmen Name is required';
//     if (affectedWorkmanEmploymentType.length === 0) errors.affectedWorkmanEmploymentType = 'Affected Workmen Employment Type is required';
//     if (!manpowerAffected) errors.manpowerAffected = 'Total no. of Manpower affected in a Injury is required';
//     if (!gender) errors.gender = 'Gender is required';
//     if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting of Injury/Incident is required';
//     if (!timeOfReporting) errors.timeOfReporting = 'Time of Reporting of Injury/Incident is required';
//     if (!bodyPartAffected) errors.bodyPartAffected = 'Body Part Affected is required';
//     if (!typeOfAccident) errors.typeOfAccident = 'Type of Accident/injury is required';
//     if (!descriptionOfInjury) errors.descriptionOfInjury = 'Description of Injury is required';

//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

// //   const formatDate = (timestamp) => {
// //     if (typeof timestamp === 'string' && timestamp.includes('-')) {
// //       return timestamp;
// //     }
// //     if (timestamp && timestamp.seconds) {
// //       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
// //     }
// //     return 'Invalid Date';
// //   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         await db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .add({
//             date,
//             time,
//             injuryCategory,
//             areaName,
//             reportingPersonName,
//             employeeId,
//             reportingPersonDesignation,
//             affectedWorkmenName,
//             affectedWorkmanEmploymentType,
//             contractorName,
//             affectedWorkmenEmployeeId,
//             manpowerAffected,
//             gender,
//             dateOfJoining,
//             dateOfReporting,
//             timeOfReporting,
//             bodyPartAffected,
//             typeOfAccident,
//             descriptionOfInjury,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//         await db.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });

//         const detailRef = db
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('accident_reports')
//           .doc('details');

//         const detailRefDoc = await detailRef.get();

//         if (detailRefDoc.exists) {
//           await detailRef.delete();
//         }

//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (e) {
//         console.error('Error adding document: ', e);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDate(new Date());
//     setTime(new Date());
//     setInjuryCategory('');
//     setAreaName('');
//     setReportingPersonName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setAffectedWorkmenName('');
//     setAffectedWorkmanEmploymentType([]);
//     setContractorName('');
//     setAffectedWorkmenEmployeeId('');
//     setManpowerAffected(1);
//     setGender('');
//     setDateOfJoining(new Date());
//     setDateOfReporting(new Date());
//     setTimeOfReporting(new Date());
//     setBodyPartAffected('');
//     setTypeOfAccident('');
//     setDescriptionOfInjury('');
//     setErrors({});
//   };

//   const affectedWorkmanEmploymentTypeOptions = [
//     { label: 'Company Employee', value: 'Company Employee' },
//     { label: 'Contract Employee', value: 'Contract Employee' },
//   ];

//   const genderOptions = [
//     { label: 'Male', value: 'male' },
//     { label: 'Female', value: 'female' },
//   ];

//   const typeOfInjuryOptions = [
//     { label: 'First Aid', value: 'First Aid' },
//     { label: 'Minor', value: 'Minor' },
//     { label: 'Major', value: 'Major' },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   // ... [All your useEffects remain the same]

// //   const validate = () => {
// //     // ... [The validate function remains the same]
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     if (validate()) {
// //       setIsLoading(true);
// //       try {
// //         // ... [The rest of the handleSubmit function remains the same]

// //         clearForm();
// //         setIsLoading(false);
// //         setSnackbarMessage('Form submitted successfully');
// //         setSnackbarOpen(true);
// //       } catch (e) {
// //         console.error('Error adding document: ', e);
// //         setIsLoading(false);
// //         setSnackbarMessage('Failed to submit form');
// //         setSnackbarOpen(true);
// //       }
// //     }
// //   };

// //   const clearForm = () => {
// //     // ... [The clearForm function remains the same]
// //   };

// //   const handleTabChange = (event, newValue) => {
// //     setTabValue(newValue);
// //   };

//   const formatDate = (date) => {
//     if (date instanceof Date) {
//       return format(date, 'MMMM d, yyyy');
//     } else if (typeof date === 'string') {
//       return format(new Date(date), 'MMMM d, yyyy');
//     } else if (date && typeof date.toDate === 'function') {
//       return format(date.toDate(), 'MMMM d, yyyy');
//     }
//     return 'Invalid Date';
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <Container maxWidth="lg">
//         <StyledPaper elevation={3}>
//           <Typography variant="h4" gutterBottom>
//             Accident Report
//           </Typography>
//           <StyledForm onSubmit={handleSubmit}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <DatePicker
//                   label="Date of Injury/Incident"
//                   value={date}
//                   onChange={setDate}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       error={!!errors.date}
//                       helperText={errors.date}
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TimePicker
//                   label="Time of Event"
//                   value={time}
//                   onChange={setTime}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       error={!!errors.time}
//                       helperText={errors.time}
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth error={!!errors.injuryCategory}>
//                   <InputLabel>Injury Category</InputLabel>
//                   <Select
//                     value={injuryCategory}
//                     onChange={(e) => setInjuryCategory(e.target.value)}
//                   >
//                     {iCategory.map((option) => (
//                       <MenuItem key={option.id} value={option.name}>
//                         {option.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               {/* ... [Continue with all your form fields] */}
//               <Grid item xs={12}>
//                 <TextField
//                   label="Description of Injury"
//                   value={descriptionOfInjury}
//                   onChange={(e) => setDescriptionOfInjury(e.target.value)}
//                   fullWidth
//                   multiline
//                   rows={4}
//                   error={!!errors.descriptionOfInjury}
//                   helperText={errors.descriptionOfInjury}
//                 />
//               </Grid>
//             </Grid>
//             <Box mt={3}>
//               <StyledButton
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 disabled={isLoading}
//               >
//                 {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//               </StyledButton>
//               <Button variant="outlined" onClick={clearForm} disabled={isLoading}>
//                 Clear
//               </Button>
//             </Box>
//           </StyledForm>
//         </StyledPaper>

//         <Paper sx={{ marginTop: 4 }}>
//           <Tabs value={tabValue} onChange={handleTabChange} centered>
//             <Tab label="Pending Reports" />
//             <Tab label="Completed Reports" />
//           </Tabs>
//           <Box p={3}>
//             {tabValue === 0 && (
//               <Grid container spacing={3}>
//                 {reports.filter(report => report.isPending).map((report) => (
//                   <Grid item xs={12} sm={6} md={4} key={report.id}>
//                     <Card>
//                       <CardContent>
//                         <Typography variant="h6" gutterBottom>
//                           Case Number: {report.caseNumber}
//                         </Typography>
//                         <Typography>
//                           Date of Incident: {formatDate(report.date)}
//                         </Typography>
//                         <Typography>
//                           Area: {report.areaName}
//                         </Typography>
//                         <Typography>
//                           Injury Category: {report.injuryCategory}
//                         </Typography>
//                         <Typography color="error">
//                           Status: Pending
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//             {tabValue === 1 && (
//               <Grid container spacing={3}>
//                 {reports.filter(report => !report.isPending).map((report) => (
//                   <Grid item xs={12} sm={6} md={4} key={report.id}>
//                     <Card>
//                       <CardContent>
//                         <Typography variant="h6" gutterBottom>
//                           Case Number: {report.caseNumber}
//                         </Typography>
//                         <Typography>
//                           Date of Incident: {formatDate(report.date)}
//                         </Typography>
//                         <Typography>
//                           Area: {report.areaName}
//                         </Typography>
//                         <Typography>
//                           Injury Category: {report.injuryCategory}
//                         </Typography>
//                         <Typography color="success">
//                           Status: Completed
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//           </Box>
//         </Paper>
//       </Container>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//         message={snackbarMessage}
//       />
//     </LocalizationProvider>
//   );
// };

// export default AccidentReport;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container,
  Snackbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { firestore } from '../../firebase/firebaseConfig';
import firebase from 'firebase/compat/app';
import { format } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const db = firebase.firestore();

const AccidentReport = ({ userId, company_name }) => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [injuryCategory, setInjuryCategory] = useState('');
  const [areaName, setAreaName] = useState('');
  const [reportingPersonName, setReportingPersonName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
  const [affectedWorkmenName, setAffectedWorkmenName] = useState('');
  const [affectedWorkmanEmploymentType, setAffectedWorkmanEmploymentType] = useState([]);
  const [contractorName, setContractorName] = useState('');
  const [affectedWorkmenEmployeeId, setAffectedWorkmenEmployeeId] = useState('');
  const [manpowerAffected, setManpowerAffected] = useState(1);
  const [gender, setGender] = useState('');
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [dateOfReporting, setDateOfReporting] = useState(new Date());
  const [timeOfReporting, setTimeOfReporting] = useState(new Date());
  const [bodyPartAffected, setBodyPartAffected] = useState('');
  const [typeOfAccident, setTypeOfAccident] = useState('');
  const [descriptionOfInjury, setDescriptionOfInjury] = useState('');

  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [errors, setErrors] = useState({});
  const [caseCount, setCaseCount] = useState(0);
  const [BPAffected, setBPAffected] = useState([]);
  const [toA, settoA] = useState([]);
  const [iCategory, setICategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // useEffects remain the same...
    useEffect(() => {
    const unsubscribeAreas = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
      .collection('company_zones').onSnapshot((snapshot) => {
        const areaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setAreas(areaData);
      });

    const unsubscribeCaseCount = db
      .collection('company_data')
      .doc(company_name)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setCaseCount(doc.data().case_count || 0);
        }
      });

    const unsubscribeReportingPersonDesignation = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
      .collection('reporting_person_designation').onSnapshot((snapshot) => {
        const rpdData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setrpDesignation(rpdData);
      });

    const unsubscribbodyPartAffectedOptions = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
      .collection('body_part_affected').onSnapshot((snapshot) => {
        const bpaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setBPAffected(bpaData);
      });

    const unsubscriTOA = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
      .collection('type_of_accident').onSnapshot((snapshot) => {
        const toaData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        settoA(toaData);
      });

    const unsubscribIC = db.collection('company_data').doc(company_name).collection(company_name).doc('for_admin')
      .collection('injury_category').onSnapshot((snapshot) => {
        const icData = snapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setICategory(icData);
      });

    return () => {
      unsubscribeAreas();
      unsubscribeCaseCount();
      unsubscribeReportingPersonDesignation();
      unsubscribbodyPartAffectedOptions();
      unsubscriTOA();
      unsubscribIC();
    };
  }, [company_name]);

  useEffect(() => {
    const fetchReports = async () => {
      const reportsSnapshot = await firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('accident_reports')
        .where('userId', '==', userId)
        .get();
  
      const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReports(reportsData);
    };
    fetchReports();
  },[userId]);

  const validate = () => {
    const errors = {};

    if (!date) errors.date = 'Date of Injury/Incident is required';
    if (!time) errors.time = 'Time of Event is required';
    if (!injuryCategory) errors.injuryCategory = 'Injury Category is required';
    if (!areaName) errors.areaName = 'Area Name is required';
    if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
    if (!employeeId) errors.employeeId = 'Employee ID is required';
    if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
    if (!affectedWorkmenName) errors.affectedWorkmenName = 'Affected Workmen Name is required';
    if (affectedWorkmanEmploymentType.length === 0) errors.affectedWorkmanEmploymentType = 'Affected Workmen Employment Type is required';
    if (!manpowerAffected) errors.manpowerAffected = 'Total no. of Manpower affected in a Injury is required';
    if (!gender) errors.gender = 'Gender is required';
    if (!dateOfReporting) errors.dateOfReporting = 'Date of Reporting of Injury/Incident is required';
    if (!timeOfReporting) errors.timeOfReporting = 'Time of Reporting of Injury/Incident is required';
    if (!bodyPartAffected) errors.bodyPartAffected = 'Body Part Affected is required';
    if (!typeOfAccident) errors.typeOfAccident = 'Type of Accident/injury is required';
    if (!descriptionOfInjury) errors.descriptionOfInjury = 'Description of Injury is required';

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

    const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        await db
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('accident_reports')
          .add({
            date,
            time,
            injuryCategory,
            areaName,
            reportingPersonName,
            employeeId,
            reportingPersonDesignation,
            affectedWorkmenName,
            affectedWorkmanEmploymentType,
            contractorName,
            affectedWorkmenEmployeeId,
            manpowerAffected,
            gender,
            dateOfJoining,
            dateOfReporting,
            timeOfReporting,
            bodyPartAffected,
            typeOfAccident,
            descriptionOfInjury,
            caseNumber,
            userId,
            isPending: true,
          });

        await db.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        const detailRef = db
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('accident_reports')
          .doc('details');

        const detailRefDoc = await detailRef.get();

        if (detailRefDoc.exists) {
          await detailRef.delete();
        }

        clearForm();
        setIsLoading(false);
        alert('Form submitted successfully');
      } catch (e) {
        console.error('Error adding document: ', e);
        setIsLoading(false);
        alert('Failed to submit form');
      }
    }
  };

  const clearForm = () => {
    setDate(new Date());
    setTime(new Date());
    setInjuryCategory('');
    setAreaName('');
    setReportingPersonName('');
    setEmployeeId('');
    setReportingPersonDesignation('');
    setAffectedWorkmenName('');
    setAffectedWorkmanEmploymentType([]);
    setContractorName('');
    setAffectedWorkmenEmployeeId('');
    setManpowerAffected(1);
    setGender('');
    setDateOfJoining(new Date());
    setDateOfReporting(new Date());
    setTimeOfReporting(new Date());
    setBodyPartAffected('');
    setTypeOfAccident('');
    setDescriptionOfInjury('');
    setErrors({});
  };

  const affectedWorkmanEmploymentTypeOptions = [
    { label: 'Company Employee', value: 'Company Employee' },
    { label: 'Contract Employee', value: 'Contract Employee' },
  ];

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const typeOfInjuryOptions = [
    { label: 'First Aid', value: 'First Aid' },
    { label: 'Minor', value: 'Minor' },
    { label: 'Major', value: 'Major' },
  ];

  const formatDate = (date) => {
    if (date instanceof Date) {
      return format(date, 'MMMM d, yyyy');
    } else if (typeof date === 'string') {
      return format(new Date(date), 'MMMM d, yyyy');
    } else if (date && typeof date.toDate === 'function') {
      return format(date.toDate(), 'MMMM d, yyyy');
    }
    return 'Invalid Date';
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            Accident Report
          </Typography>
          <FormControl onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Injury/Incident"
                  value={date}
                  onChange={setDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.date}
                      helperText={errors.date}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePicker
                  label="Time of Event"
                  value={time}
                  onChange={setTime}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.time}
                      helperText={errors.time}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.injuryCategory}>
                  <InputLabel>Injury Category</InputLabel>
                  <Select
                    value={injuryCategory}
                    onChange={(e) => setInjuryCategory(e.target.value)}
                  >
                    {iCategory.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Reporting Person Name"
                  value={reportingPersonName}
                  onChange={(e) => setReportingPersonName(e.target.value)}
                  fullWidth
                  error={!!errors.reportingPersonName}
                  helperText={errors.reportingPersonName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  fullWidth
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel>Reporting Person Designation</InputLabel>
                  <Select
                    value={reportingPersonDesignation}
                    onChange={(e) => setReportingPersonDesignation(e.target.value)}
                  >
                    {rpDesignation.map((designation) => (
                      <MenuItem key={designation.id} value={designation.name}>
                        {designation.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Affected Workmen Name"
                  value={affectedWorkmenName}
                  onChange={(e) => setAffectedWorkmenName(e.target.value)}
                  fullWidth
                  error={!!errors.affectedWorkmenName}
                  helperText={errors.affectedWorkmenName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" error={!!errors.affectedWorkmanEmploymentType}>
                  <Typography variant="subtitle1">Affected Workman Employment Type</Typography>
                  <FormGroup>
                    {['Company Employee', 'Contract Employee'].map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={affectedWorkmanEmploymentType.includes(option)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAffectedWorkmanEmploymentType([...affectedWorkmanEmploymentType, option]);
                              } else {
                                setAffectedWorkmanEmploymentType(affectedWorkmanEmploymentType.filter(type => type !== option));
                              }
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              {affectedWorkmanEmploymentType.includes('Contract Employee') && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Contractor Name"
                    value={contractorName}
                    onChange={(e) => setContractorName(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Affected Workmen Employee ID"
                  value={affectedWorkmenEmployeeId}
                  onChange={(e) => setAffectedWorkmenEmployeeId(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Total no. of Manpower affected"
                  type="number"
                  value={manpowerAffected}
                  onChange={(e) => setManpowerAffected(parseInt(e.target.value))}
                  fullWidth
                  error={!!errors.manpowerAffected}
                  helperText={errors.manpowerAffected}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.gender}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Joining"
                  value={dateOfJoining}
                  onChange={setDateOfJoining}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Reporting of Injury/Incident"
                  value={dateOfReporting}
                  onChange={setDateOfReporting}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.dateOfReporting}
                      helperText={errors.dateOfReporting}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePicker
                  label="Time of Reporting of Injury/Incident"
                  value={timeOfReporting}
                  onChange={setTimeOfReporting}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.timeOfReporting}
                      helperText={errors.timeOfReporting}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.bodyPartAffected}>
                  <InputLabel>Body Part Affected</InputLabel>
                  <Select
                    value={bodyPartAffected}
                    onChange={(e) => setBodyPartAffected(e.target.value)}
                  >
                    {BPAffected.map((bpa) => (
                      <MenuItem key={bpa.id} value={bpa.name}>
                        {bpa.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.typeOfAccident}>
                  <InputLabel>Type of Accident/Injury</InputLabel>
                  <Select
                    value={typeOfAccident}
                    onChange={(e) => setTypeOfAccident(e.target.value)}
                  >
                    {toA.map((toa) => (
                      <MenuItem key={toa.id} value={toa.name}>
                        {toa.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description of Injury"
                  value={descriptionOfInjury}
                  onChange={(e) => setDescriptionOfInjury(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.descriptionOfInjury}
                  helperText={errors.descriptionOfInjury}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                </StyledButton>
                <Button
                  variant="outlined"
                  onClick={clearForm}
                  disabled={isLoading}
                  sx={{ ml: 2 }}
                >
                  Clear
                </Button>
              </Box>
            </FormControl>
          {/* {tabValue === 1 && (
            <Grid container spacing={3} mt={2}>
              {reports.map((report) => (
                <Grid item xs={12} sm={6} md={4} key={report.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Case Number: {report.caseNumber}
                      </Typography>
                      <Typography variant="body2">
                        Date of Injury: {formatDate(report.date)}
                      </Typography>
                      <Typography variant="body2">
                        Affected Workmen: {report.affectedWorkmenName}
                      </Typography>
                      <Typography variant="body2">
                        Injury Category: {report.injuryCategory}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )} */}
        </StyledPaper>
        <StyledPaper elevation={3}>
          <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3}>
          <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <Grid item xs={12} sm={6} md={4} key={report.id}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                    <Typography>Description: {report.description}</Typography>
                    <Typography color={report.isPending ? 'error' : 'green'} >Status: {report.isPending ? 'Pending' : 'Completed'}</Typography>
                  </CardContent>
                </StyledCard>
                </Grid>
              ))}
          </Grid>
          </Box>
        </StyledPaper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default AccidentReport;