import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Box, Paper, Container, ThemeProvider, createTheme, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions, styled, Card, IconButton, InputLabel, Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Close, CloudUpload, Delete } from "@material-ui/icons";
import { auditSections } from "../utils/questions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getUIDLocally } from "./../utils/userUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DetailedDataTable from "../Screens/users/auditTable/DetailedDataTable";


const db = firebase.firestore();

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          marginBottom: "16px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f8f8f8",
          borderRadius: "8px 8px 0 0",
          "& .MuiAccordionSummary-content": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "16px 24px 24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});
const PreviewImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: 200,
  objectFit: "contain",
  marginTop: theme.spacing(2),
}));

const StyledPaper = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: theme.shape.borderRadius,
}));

const ActionPlanForm = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
}));

export const AuditImageUploader = React.memo(({ questionId, onChange, error, helperText }) => {
    const [preview, setPreview] = useState(null);
  
    const handleFileChange = useCallback((file) => {
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);
        onChange(questionId, file, previewUrl);
      }
    }, [questionId, onChange]);
  
    const handleButtonClick = useCallback(() => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.onchange = (e) => handleFileChange(e.target.files[0]);
      fileInput.click();
    }, [handleFileChange]);
  
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUpload />}
          onClick={handleButtonClick}
        >
          Upload Image
        </Button>
        {error && <Typography variant="caption" color="error">{helperText}</Typography>}
        {preview && <img src={preview} alt={`Preview for question ${questionId}`} style={{ maxWidth: "100%", maxHeight: 200 }} />}
      </Box>
    );
  });