import React from "react";
import { Box, Typography, Link, IconButton, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Instagram, YouTube } from "@material-ui/icons";

const Footer = () => {
  return (
    <Box sx={{ color: "white", p: 2 }}>
      <Grid container sx={{ ml: 2, paddingBottom: 2 }}>
        <Grid item xs={12} md={6} p={5}>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOnIcon sx={{ mr: 2, color: "red" }} />
            <Typography variant="body2">
              Main Office:- G7-Ground Floor, <br />
              Near Hindi Khabar Sector 63, <br />
              Noida – 201307 Uttar Pradesh (India)
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <PhoneIcon sx={{ mr: 2, color: "red" }} />
            <Typography variant="body2">+91-8984189814</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <EmailIcon sx={{ mr: 2, color: "red" }} />
            <Link
              href="mailto:marketing@tetrahedron.in"
              color="primary"
              underline="hover"
            >
              marketing@tetrahedron.in
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="h4" fontWeight={"bold"} gutterBottom >
            Kavach Safety
          </Typography>
          <Box>
            <IconButton color="primary" aria-label="Facebook" sx={{
              transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'Scale(1.5)',
        },
            }}>
              <FacebookIcon style={{fontSize:40 ,border:'4px solid blue',borderRadius:"100%", backgroundColor:'blue',color:'white'}} />
            </IconButton>
            <IconButton color="primary" aria-label="Twitter"sx={{
              transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'Scale(1.5)',
        },
            }}>
              <TwitterIcon style={{fontSize:40,border:'4px solid #3DC2EC',borderRadius:"100%", backgroundColor:'#3DC2EC',color:'white' }} />
            </IconButton>
            <IconButton color="primary" aria-label="Twitter"sx={{
              transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'Scale(1.5)',
        },
            }}>
              <YouTube style={{color:'red',fontSize:40,border:'4px solid red',borderRadius:"100%", backgroundColor:'red',color:"white"}} />
            </IconButton>
            <IconButton color="primary" aria-label="LinkedIn"sx={{
              transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'Scale(1.5)',
        },
            }}>
              <LinkedInIcon style={{fontSize:40,border:'4px solid #125B9A',borderRadius:"100%", backgroundColor:'#125B9A',color:'white' }} />
            </IconButton>
            <IconButton color="primary" aria-label="Instagram" sx={{
              transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'Scale(1.5)',
        },
            }}>
              <Instagram style={{fontSize:40, color:'#dc2f02',border:'4px solid #DA0C81',borderRadius:"100%", backgroundColor:'#DA0C81',color:"white"}} />
            </IconButton>
          </Box>
          <Typography variant="body2" paragraph>
            Kavach Safety Management is an advanced system designed to enhance
            workplace safety by providing tools for incident reporting, document
            management, observation tracking, and customizable safety workflows.
            It aims to foster a proactive safety culture, ensuring compliance
            and mitigating risks through timely alerts and comprehensive
            dashboards.
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Typography variant="body2" color="text.secondry">
          &copy; 2024 TMS. All rights reserved.{" "}
          <Link href="#" color="#F0F8FF">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="#" color="#F0F8FF">
            Terms and Conditions.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
