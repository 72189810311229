// import React, { useState, useEffect } from 'react';
// import { 
//   Typography, 
//   TextField, 
//   Button, 
//   FormControl, 
//   InputLabel, 
//   Select, 
//   MenuItem, 
//   Grid, 
//   Paper,
//   CircularProgress,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker, TimePicker } from '@material-ui/pickers';
// import { firestore } from '../../firebase/firebaseConfig';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const WeeklySafetyMeeting = ({ userId, email, company_name }) => {
//   const classes = useStyles();
//   const [date, setDate] = useState(new Date());
//   const [departmentName, setDepartmentName] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [areaInchargeName, setAreaInchargeName] = useState('');
//   const [meetingConductedBy, setMeetingConductedBy] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [topicsDiscussed, setTopicsDiscussed] = useState('');
//   const [numberOfParticipants, setNumberOfParticipants] = useState(1);
//   const [durationOfMeeting, setDurationOfMeeting] = useState(new Date());
//   const [descriptionOfTopic, setDescriptionOfTopic] = useState('');
//   const [departments, setDepartments] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [topics, setTopics] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [caseCount, setCaseCount] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const companyRef = firestore.collection('company_data').doc(company_name);

//       // Fetch departments
//       const departmentsSnapshot = await companyRef
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('department_name')
//         .get();
//       setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

//       // Fetch areas
//       const areasSnapshot = await companyRef
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('company_zones')
//         .get();
//       setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

//       // Fetch topics
//       const topicsSnapshot = await companyRef
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('topics_discussed')
//         .get();
//       setTopics(topicsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

//       // Fetch case count
//       const companyDoc = await companyRef.get();
//       if (companyDoc.exists) {
//         setCaseCount(companyDoc.data().case_count || 0);
//       }
//       // Fetch user reports
//       fetchReports();
//     };

//     fetchData();
//   }, [company_name, userId]);

//   const fetchReports = async () => {
//     const reportsSnapshot = await firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('weekly_safety_reports')
//       .where('userId', '==', userId)
//       .get();

//     const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//     setReports(reportsData);
//   };
//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };

//   const validate = () => {
//     const errors = {};
//     if (!date) errors.date = 'Date is required';
//     if (!departmentName) errors.departmentName = 'Department Name is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!areaInchargeName) errors.areaInchargeName = 'Area Incharge Name is required';
//     if (!meetingConductedBy) errors.meetingConductedBy = 'Meeting Conducted By is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!topicsDiscussed) errors.topicsDiscussed = 'Topics Discussed is required';
//     if (!numberOfParticipants) errors.numberOfParticipants = 'Number of Participants is required';
//     if (!durationOfMeeting) errors.durationOfMeeting = 'Duration of Meeting is required';
//     if (!descriptionOfTopic) errors.descriptionOfTopic = 'Description of Topic is required';
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;
//         await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('weekly_safety_reports')
//           .add({
//             date,
//             departmentName,
//             areaName,
//             areaInchargeName,
//             meetingConductedBy,
//             employeeId,
//             topicsDiscussed,
//             numberOfParticipants,
//             durationOfMeeting,
//             descriptionOfTopic,
//             caseNumber,
//             userId,
//             isPending: true,
//           });

//         // Update case count
//         await firestore.collection('company_data').doc(company_name).update({
//           case_count: caseNumber,
//         });

//         const detailRef = firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('weekly_safety_reports')
//       .doc('details');

//     const detailRefDoc = await detailRef.get();

//     if (detailRefDoc.exists) {
//       await detailRef.delete();
//     }

//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const clearForm = () => {
//     setDate(new Date());
//     setDepartmentName('');
//     setAreaName('');
//     setAreaInchargeName('');
//     setMeetingConductedBy('');
//     setEmployeeId('');
//     setTopicsDiscussed('');
//     setNumberOfParticipants(0);
//     setDurationOfMeeting(new Date());
//     setDescriptionOfTopic('');
//     setErrors({});
//   };
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           Weekly Safety Meeting
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date"
//                 value={date.toString()}
//                 onChange={setDate}
//                 fullWidth
//                 error={!!errors.date}
//                 helperText={errors.date}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.departmentName}>
//                 <InputLabel>Department Name</InputLabel>
//                 <Select
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                 >
//                   {departments.map((dept) => (
//                     <MenuItem key={dept.id} value={dept.name}>
//                       {dept.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.departmentName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.departmentName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Area Name</InputLabel>
//                 <Select
//                   value={areaName}
//                   onChange={(e) => setAreaName(e.target.value)}
//                 >
//                   {areas.map((area) => (
//                     <MenuItem key={area.id} value={area.name}>
//                       {area.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.areaName && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.areaName}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Area Incharge Name"
//                 value={areaInchargeName}
//                 onChange={(e) => setAreaInchargeName(e.target.value)}
//                 fullWidth
//                 error={!!errors.areaInchargeName}
//                 helperText={errors.areaInchargeName}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Meeting Conducted By"
//                 value={meetingConductedBy}
//                 onChange={(e) => setMeetingConductedBy(e.target.value)}
//                 fullWidth
//                 error={!!errors.meetingConductedBy}
//                 helperText={errors.meetingConductedBy}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Employee ID"
//                 value={employeeId}
//                 onChange={(e) => setEmployeeId(e.target.value)}
//                 fullWidth
//                 error={!!errors.employeeId}
//                 helperText={errors.employeeId}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.topicsDiscussed}>
//                 <InputLabel>Topics Discussed</InputLabel>
//                 <Select
//                   value={topicsDiscussed}
//                   onChange={(e) => setTopicsDiscussed(e.target.value)}
//                 >
//                   {topics.map((topic) => (
//                     <MenuItem key={topic.id} value={topic.name}>
//                       {topic.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.topicsDiscussed && (
//                   <Typography variant="caption" className={classes.error}>
//                     {errors.topicsDiscussed}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Number of Participants"
//                 type="number"
//                 value={numberOfParticipants}
//                 onChange={(e) => setNumberOfParticipants(parseInt(e.target.value, 10) || 0)}
//                 fullWidth
//                 error={!!errors.numberOfParticipants}
//                 helperText={errors.numberOfParticipants}
//                 inputProps={{ min: 1 }}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TimePicker
//                 label="Duration of Meeting"
//                 value={durationOfMeeting}
//                 onChange={setDurationOfMeeting}
//                 fullWidth
//                 error={!!errors.durationOfMeeting}
//                 helperText={errors.durationOfMeeting}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="Description of Topic"
//                 value={descriptionOfTopic}
//                 onChange={(e) => setDescriptionOfTopic(e.target.value)}
//                 fullWidth
//                 multiline
//                 rows={4}
//                 error={!!errors.descriptionOfTopic}
//                 helperText={errors.descriptionOfTopic}
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className={classes.submitButton}
//             disabled={isLoading}
//           >
//             {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//           </Button>
//           <Button variant="outlined" onClick={clearForm}>
//             Clear
//           </Button>
//         </form>
//       </Paper>

//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.date)} </Typography>
//                   <Typography>Description of the Topic: {report.descriptionOfTopic}  </Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>

//     </div>
//   );
// };

// export default WeeklySafetyMeeting;

import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/material/styles';
import { firestore } from '../../firebase/firebaseConfig';
import { format } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledForm = styled('form')(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const WeeklySafetyMeeting = ({ userId, email, company_name }) => {
  const [date, setDate] = useState(new Date());
  const [departmentName, setDepartmentName] = useState('');
  const [areaName, setAreaName] = useState('');
  const [areaInchargeName, setAreaInchargeName] = useState('');
  const [meetingConductedBy, setMeetingConductedBy] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [topicsDiscussed, setTopicsDiscussed] = useState('');
  const [numberOfParticipants, setNumberOfParticipants] = useState(1);
  const [durationOfMeeting, setDurationOfMeeting] = useState(new Date());
  const [descriptionOfTopic, setDescriptionOfTopic] = useState('');
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [topics, setTopics] = useState([]);
  const [errors, setErrors] = useState({});
  const [caseCount, setCaseCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);

      // Fetch departments
      const departmentsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch topics
      const topicsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('topics_discussed')
        .get();
      setTopics(topicsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }
      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('weekly_safety_reports')
      .where('userId', '==', userId)
      .get();

    const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReports(reportsData);
  };

  const formatDate = (timestamp) => {
    if (timestamp instanceof Date) {
      return format(timestamp, 'MMMM d, yyyy');
    }
    if (timestamp && timestamp.seconds) {
      return format(new Date(timestamp.seconds * 1000), 'MMMM d, yyyy');
    }
    return 'Invalid Date';
  };

  const validate = () => {
    const errors = {};
    if (!date) errors.date = 'Date is required';
    if (!departmentName) errors.departmentName = 'Department Name is required';
    if (!areaName) errors.areaName = 'Area Name is required';
    if (!areaInchargeName) errors.areaInchargeName = 'Area Incharge Name is required';
    if (!meetingConductedBy) errors.meetingConductedBy = 'Meeting Conducted By is required';
    if (!employeeId) errors.employeeId = 'Employee ID is required';
    if (!topicsDiscussed) errors.topicsDiscussed = 'Topics Discussed is required';
    if (!numberOfParticipants) errors.numberOfParticipants = 'Number of Participants is required';
    if (!durationOfMeeting) errors.durationOfMeeting = 'Duration of Meeting is required';
    if (!descriptionOfTopic) errors.descriptionOfTopic = 'Description of Topic is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('weekly_safety_reports')
          .add({
            date,
            departmentName,
            areaName,
            areaInchargeName,
            meetingConductedBy,
            employeeId,
            topicsDiscussed,
            numberOfParticipants,
            durationOfMeeting,
            descriptionOfTopic,
            caseNumber,
            userId,
            isPending: true,
          });

        // Update case count
        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        const detailRef = firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('weekly_safety_reports')
          .doc('details');

        const detailRefDoc = await detailRef.get();

        if (detailRefDoc.exists) {
          await detailRef.delete();
        }

        clearForm();
        setIsLoading(false);
        alert('Form submitted successfully');
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsLoading(false);
        alert('Failed to submit form');
      }
    }
  };

  const clearForm = () => {
    setDate(new Date());
    setDepartmentName('');
    setAreaName('');
    setAreaInchargeName('');
    setMeetingConductedBy('');
    setEmployeeId('');
    setTopicsDiscussed('');
    setNumberOfParticipants(0);
    setDurationOfMeeting(new Date());
    setDescriptionOfTopic('');
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3 }}>
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            Weekly Safety Meeting
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth error={!!errors.date} helperText={errors.date} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    label="Department Name"
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.departmentName && (
                    <Typography variant="caption" color="error">
                      {errors.departmentName}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                    label="Area Name"
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.areaName && (
                    <Typography variant="caption" color="error">
                      {errors.areaName}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Area Incharge Name"
                  value={areaInchargeName}
                  onChange={(e) => setAreaInchargeName(e.target.value)}
                  fullWidth
                  error={!!errors.areaInchargeName}
                  helperText={errors.areaInchargeName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Meeting Conducted By"
                  value={meetingConductedBy}
                  onChange={(e) => setMeetingConductedBy(e.target.value)}
                  fullWidth
                  error={!!errors.meetingConductedBy}
                  helperText={errors.meetingConductedBy}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Employee ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  fullWidth
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.topicsDiscussed}>
                  <InputLabel>Topics Discussed</InputLabel>
                  <Select
                    value={topicsDiscussed}
                    onChange={(e) => setTopicsDiscussed(e.target.value)}
                    label="Topics Discussed"
                  >
                    {topics.map((topic) => (
                      <MenuItem key={topic.id} value={topic.name}>
                        {topic.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.topicsDiscussed && (
                    <Typography variant="caption" color="error">
                      {errors.topicsDiscussed}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Number of Participants"
                  type="number"
                  value={numberOfParticipants}
                  onChange={(e) => setNumberOfParticipants(parseInt(e.target.value, 10) || 0)}
                  fullWidth
                  error={!!errors.numberOfParticipants}
                  helperText={errors.numberOfParticipants}
                  inputProps={{ min: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePicker
                  label="Duration of Meeting"
                  value={durationOfMeeting}
                  onChange={(newTime) => setDurationOfMeeting(newTime)}
                  renderInput={(params) => <TextField {...params} fullWidth error={!!errors.durationOfMeeting} helperText={errors.durationOfMeeting} />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description of Topic"
                  value={descriptionOfTopic}
                  onChange={(e) => setDescriptionOfTopic(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.descriptionOfTopic}
                  helperText={errors.descriptionOfTopic}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button variant="outlined" onClick={clearForm}>
                Clear
              </Button>
            </Box>
          </StyledForm>
        </StyledPaper>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <StyledCard key={report.id}>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                    <Typography>Description of the Topic: {report.descriptionOfTopic}</Typography>
                    <Typography>Department: {report.departmentName}</Typography>
                    <Typography>Area: {report.areaName}</Typography>
                    <Typography>Meeting Conducted By: {report.meetingConductedBy}</Typography>
                    <Typography>Number of Participants: {report.numberOfParticipants}</Typography>
                  </CardContent>
                </StyledCard>
              ))}
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default WeeklySafetyMeeting;