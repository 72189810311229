import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const db = firebase.firestore();

const exportToExcel = async (companyName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Safety Audit');

  // Add headers
  worksheet.addRow(['Section', 'Question', 'Answer', 'Image URL', 'Date']);

  try {
    const auditDocs = await db.collection("company_data")
      .doc(companyName)
      .collection(companyName)
      .doc("for_user")
      .collection("audits")
      .get();

    for (const auditDoc of auditDocs.docs) {
      const sectionTitle = auditDoc.id;
      const questionsCollection = await auditDoc.ref.collection("questions").get();

      for (const questionDoc of questionsCollection.docs) {
        const questionData = questionDoc.data();
        worksheet.addRow([
          sectionTitle,
          questionData.questionText,
          questionData.answer,
          questionData.imageUrl,
          auditDoc.data().date.toDate().toLocaleDateString()
        ]);
      }
    }

    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${companyName}_Safety_Audit.xlsx`);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
    alert("Failed to export data to Excel");
  }
};

export default exportToExcel;