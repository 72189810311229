import React, { useState } from 'react';
//import {firebase} from './../../firebase/firebase';
import { FaPlus, FaTrash } from 'react-icons/fa';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// const firestore = firebase.firestore();
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import { firestore } from '../../firebase/firebaseConfig';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  assignmentContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
  consultantContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
  },
}));


const CompanyData = ({ companyName }) => {
//   const { companyName } = route.params;
// console.log('CompanyData');
const classes = useStyles();
  const [consultants, setConsultants] = useState([{ name: '' }]);
  const [companyData, setCompanyData] = useState({
    companyName: '',
    plantLocation: '',
    projectName: '',
    projectDate: '',
    projectEndDate: '',
    projectSponsor: '',
    projectLeader: '',
    projectManager: '',
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors1 = validateFields();
    if (Object.keys(errors1).length > 0) {
      setErrors(errors1);
      alert('Please fill in all mandatory fields');
      return;
    }

    try {
      const companyDataRef = await firestore
        .collection('company_data')
        .doc(companyName)
        .collection(companyName)
        .doc('for_admin')
        .collection('company_details')
        .add(companyData);

      if (companyDataRef && companyDataRef.id) {
        for (const consultant of consultants) {
          await firestore
            .collection('company_data')
            .doc(companyName)
            .collection(companyName)
            .doc('for_admin')
            .collection('company_details')
            .doc(companyDataRef.id)
            .collection('Consultants')
            .add(consultant);
        }
        alert('Form submitted successfully!');
        clearForm();
      } else {
        console.error('Error saving company data: CompanyDataRef or ID is undefined');
        alert('Form not submitted!');
      }
    } catch (error) {
      console.error('Error saving company data:', error);
      alert('Form not submitted!');
    }
  };

  const validateFields = () => {
    const errors2 = {};
    const requiredFields = [
      'companyName',
      'plantLocation',
      'projectName',
      'projectDate',
      'projectEndDate',
      'projectSponsor',
      'projectLeader',
      'projectManager',
    ];

    requiredFields.forEach((field) => {
      if (!companyData[field]) {
        errors2[field] = 'This field is required';
      }
    });

    if (consultants.length === 1 && !consultants[0].name.trim()) {
      errors2.consultants = 'At least one consultant is required';
    } else if (consultants.some((consultant) => !consultant.name.trim())) {
      errors2.consultants = 'Please fill in all consultant names';
    }

    return errors2;
  };

  const clearForm = () => {
    setCompanyData({
      companyName: '',
      plantLocation: '',
      projectName: '',
      projectDate: '',
      projectEndDate: '',
      projectSponsor: '',
      projectLeader: '',
      projectManager: '',
    });
    setConsultants([{ name: '' }]);
    setErrors({});
  };

  const handleAddConsultant = () => {
    setConsultants([...consultants, { name: '' }]);
  };

  const handleConsultantChange = (text, index) => {
    const updatedConsultants = [...consultants];
    updatedConsultants[index].name = text;
    setConsultants(updatedConsultants);
    setErrors({ ...errors, consultants: '' }); // Clear consultant error
  };

  const handleRemoveConsultant = (index) => {
    const updatedConsultants = [...consultants];
    updatedConsultants.splice(index, 1);
    setConsultants(updatedConsultants);
  };

  const handleInputChange = (value, field) => {
    setCompanyData({ ...companyData, [field]: value });
    setErrors({ ...errors, [field]: '' }); // Clear error for this field
  };

  return (
 <Box className={classes.container}>
  <Typography variant="h5" gutterBottom>
  Company Data
  </Typography>
  <Box className={classes.assignmentContainer}>
<form onSubmit={handleSubmit}>
  <Grid container spacing={2}>
    <Grid item xs={12} className={classes.inputContainer}> 
      <Typography variant="body1" className={classes.label}>
        Company Name
      </Typography>
      <TextField
        fullWidth
        id="companyName"
        value={companyData.companyName}
        onChange={(e) => handleInputChange(e.target.value, 'companyName')}
        error={!!errors.companyName}
        helperText={errors.companyName}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Plant Location
      </Typography>
      <TextField
        fullWidth
        id="plantLocation"
        value={companyData.plantLocation}
        onChange={(e) => handleInputChange(e.target.value, 'plantLocation')}
        error={!!errors.plantLocation}
        helperText={errors.plantLocation}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project Name
      </Typography>
      <TextField
        fullWidth
        id="projectName"
        value={companyData.projectName}
        onChange={(e) => handleInputChange(e.target.value, 'projectName')}
        error={!!errors.projectName}
        helperText={errors.projectName}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project Date
      </Typography>
      <TextField
        fullWidth
        id="projectDate"
        type="date"
        value={companyData.projectDate}
        onChange={(e) => handleInputChange(e.target.value, 'projectDate')}
        error={!!errors.projectDate}
        helperText={errors.projectDate}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project End Date
      </Typography>
      <TextField
        fullWidth
        id="projectEndDate"
        type="date"
        value={companyData.projectEndDate}
        onChange={(e) => handleInputChange(e.target.value, 'projectEndDate')}
        error={!!errors.projectEndDate}
        helperText={errors.projectEndDate}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project Sponsor (client)
      </Typography>
      <TextField
        fullWidth
        id="projectSponsor"
        value={companyData.projectSponsor}
        onChange={(e) => handleInputChange(e.target.value, 'projectSponsor')}
        error={!!errors.projectSponsor}
        helperText={errors.projectSponsor}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project Leader (client)
      </Typography>
      <TextField
        fullWidth
        id="projectLeader"
        value={companyData.projectLeader}
        onChange={(e) => handleInputChange(e.target.value, 'projectLeader')}
        error={!!errors.projectLeader}
        helperText={errors.projectLeader}
      />
    </Grid>
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="body1" className={classes.label}>
        Project Manager (TMS)
      </Typography>
      <TextField
        fullWidth
        id="projectManager"
        value={companyData.projectManager}
        onChange={(e) => handleInputChange(e.target.value, 'projectManager')}
        error={!!errors.projectManager}
        helperText={errors.projectManager}
      />
    </Grid>
  </Grid>

  <Box className={classes.consultantContainer}>
    <Typography variant="body1" className={classes.label}>
      Project Consultants (TMS)
    </Typography>
    {consultants.map((consultant, index) => (
      <div key={index} className={classes.consultantInput}>
        <TextField
          fullWidth
          value={consultant.name}
          onChange={(e) => handleConsultantChange(e.target.value, index)}
          placeholder={`Project Consultant ${index + 1}`}
        />
        {index > 0 && (
          <IconButton
            className={classes.iconButton}
            onClick={() => handleRemoveConsultant(index)}
          >
            <FaTrash />
          </IconButton>
        )}
      </div>
    ))}
    {errors.consultants && <span className={classes.error}>{errors.consultants}</span>}
    <Box className={classes.actionButtons}>
      <IconButton className={classes.iconButton} onClick={handleAddConsultant}>
        <FaPlus />
      </IconButton>
    </Box>
  </Box>

  <Button variant="contained" color="primary" type="submit">
    Submit
  </Button>
</form>
</Box>
 </Box>

);
};

export default CompanyData;