import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Counter = ({ end, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = end / (duration / 10);

    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(timer);
        start = end;
      }
      setCount(Math.round(start));
    }, 10);

    return () => clearInterval(timer);
  }, [end, duration]);

  return <span>{count}</span>;
};

const Statistics = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: isSmallScreen ? 2 : 4,
        textAlign: 'center',
      }}
    >
      <Typography
        variant={isSmallScreen ? 'h5' : 'h2'}
        color={'#002244'}
        fontWeight={'bold'}
        mb={2}
      >
        Our Proven Track Record in Safety Consulting
      </Typography>
      <Typography
        variant={isSmallScreen ? 'body2' : 'body1'}
        color="text.secondary"
      >
        Our proven track record in safety consulting demonstrates our commitment to creating safer workplaces. With years of experience, we’ve successfully helped numerous businesses reduce risks, comply with regulations, and enhance employee safety. Trust our expert team to deliver comprehensive safety consulting services tailored to your specific needs.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 4,
          mt: 4,
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant={isSmallScreen ? 'h5' : 'h2'}
            color={'#002244'}
            fontWeight={'bold'}
          >
            <Counter end={100} duration={2000} />+
          </Typography>
          <Typography
            variant={isSmallScreen ? 'body1' : 'h4'}
            color={'#002244'}
          >
            Successful Projects
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant={isSmallScreen ? 'h5' : 'h2'}
            color={'#002244'}
            fontWeight={'bold'}
          >
            <Counter end={10} duration={2000} />+
          </Typography>
          <Typography
            variant={isSmallScreen ? 'body1' : 'h4'}
            color={'#002244'}
          >
            Years of Experience
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant={isSmallScreen ? 'h5' : 'h2'}
            color={'#002244'}
            fontWeight={'bold'}
          >
            <Counter end={20} duration={2000} />+
          </Typography>
          <Typography
variant={isSmallScreen ? 'body1' : 'h4'}
            color={'#002244'}
          >
            Industries Served
          </Typography>
        </Box>
        {/* <Box sx={{ textAlign: 'center' }}>
          <Typography
           variant={isSmallScreen ? 'h5' : 'h2'}
            color={'#002244'}
            fontWeight={'bold'}
          >
            <Counter end={98} duration={2000} />+
          </Typography>
          <Typography
            variant={isSmallScreen ? 'body1' : 'h4'}
            color={'#002244'}
          >
            Client Satisfaction
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Statistics;
