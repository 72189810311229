import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import AuthenticationModule from '../AuthenticationModule';
import ContactForm from '../../componants/ContactForm';
import Pricing from '../../componants/Pricing';
import AboutUs from '../../componants/AboutUs';

const NavBarScreens = () => {
  return (
    <div>
      <Navbar />
      <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/ContactForm" element={<ContactForm/>}/>
         <Route path="/Login/User" element={<AuthenticationModule />} />
        <Route path="/Login/Admin" element={<AuthenticationModule />} />
        <Route path="/AboutUs" element={<AboutUs/>} />
        <Route path="/pricing" element={<Pricing/>} />
      </Routes>
    </div>
  );
};

export default NavBarScreens;
