import React from 'react';
import { Typography, Divider, Container, Box } from '@mui/material';
import SearchableInput from './../../componants/SearchableInput';

const AddReportingPersonDesignation = ({ companyName }) => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add Reporting Person Designation
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="reporting_person_designation"
          companyName={companyName}
          containerHeight={400}
          placeholder="Add Reporting Person Designation"
        />
      </Box>
    </Container>
  );
};

export default AddReportingPersonDesignation;