import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, Button, Box, CircularProgress
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';

const DetailedDataTable = ({ company_name, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailedData();
  }, [company_name, startDate, endDate, dataType]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return 'Invalid Date';
  };

  const fetchDetailedData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const collection = db
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('weekly_safety_reports');

    const querySnapshot = await collection.get();

    const detailedData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const dateObj = data.date instanceof firebase.firestore.Timestamp 
        ? data.date.toDate() 
        : new Date(data.date);

      if (dateObj >= startDate && dateObj <= endDate) {
        if ( dataType === 'meetings')
        {
        detailedData.push({
          date: formatDate(data.date),
          caseNumber: data.caseNumber,
          departmentName: data.departmentName || '',
          areaName: data.areaName || '',
          areaInchargeName: data.areaInchargeName || '',
          meetingConductedBy: data.meetingConductedBy || '',
          employeeId: data.employeeId || '',
          topicsDiscussed: data.topicsDiscussed || '',
          numberOfParticipants: data.numberOfParticipants || 0,
          // durationOfMeeting: data.durationOfMeeting || '',
          descriptionOfTopic: data.descriptionOfTopic || '',
        });
      }
      }
    });

    setData(detailedData);
    setLoading(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(workbook, `weekly_safety_meeting_detailed_data.xlsx`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detailed Data for Weekly Safety Meetings
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '1rem' }}>
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Case Number </TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>Area Incharge Name</TableCell>
               <TableCell>Meeting Conducted By</TableCell>
              <TableCell>Employee ID</TableCell>
              <TableCell>Topics Discussed</TableCell>
              <TableCell>Number of Participants</TableCell>
              {/*<TableCell>Duration of Meeting</TableCell> */}
              <TableCell>Description of Topic</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.caseNumber}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.areaName}</TableCell>
                 <TableCell>{row.areaInchargeName}</TableCell>
                <TableCell>{row.meetingConductedBy}</TableCell>
                <TableCell>{row.employeeId}</TableCell>
                <TableCell>{row.topicsDiscussed}</TableCell>
                <TableCell>{row.numberOfParticipants}</TableCell>
                {/*<TableCell>{row.durationOfMeeting}</TableCell> */}
                <TableCell>{row.descriptionOfTopic}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedDataTable;