import React from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const FreeTrialBanner = ( { onScrollTo } ) => {
  const navigate = useNavigate();


  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleScrollTo = (section) => {
    onScrollTo(section);
    navigate('/');
  };
  return (
    <Box
      sx={{
        
        textAlign: 'center',
        py: { xs: 4, sm: 5 }, // Responsive padding for different screen sizes
        px: { xs: 2, sm: 3 }, // Responsive padding for different screen sizes
      }}
    >
      <Typography
        variant={isSmallScreen ? "h6" : "h4"}
        component="h1"
        gutterBottom
        
        sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } ,fontWeight:'bold',}} // Responsive font size
      >
        Try all the features with a 30-day free trial
      </Typography>
      
      {/* <Typography
        variant="body1"
        gutterBottom
        sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }} // Responsive font size
      >
        No credit card. No commitment. Unlimited users and usage.
      </Typography> */}
      
      <Box sx={{ my: 3 }}>
        <Button
          variant="contained"
          onClick={() => handleScrollTo('contact')}
          sx={{
            mx: 1,
            my: 1,
            px: { xs: 2, sm: 3 }, // Responsive padding for button
            py: { xs: 1, sm: 1.5 }, // Responsive padding for button
            fontSize: { xs: '0.875rem', sm: '1rem' }, // Responsive font size
            backgroundColor: "#28CC9E",color:'white',
            "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
          }}
        >
          Request free trial
        </Button>
      </Box>
      
      <Typography
        variant="body2"
        sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }} // Responsive font size
      >
        Looking for a customized solution?{' '}
        <Link  onClick={() => handleScrollTo('contact')}  underline="always">
          Get in touch.
        </Link>
      </Typography>
    </Box>
  );
};

export default FreeTrialBanner;
