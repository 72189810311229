import React from 'react';
import { Typography, Divider, Container, Box } from '@mui/material';
import SearchableInput from './../../componants/SearchableInput';

const AddTopicsDiscussed = ({ companyName }) => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add Topics Discussed
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <SearchableInput
          collectionName="topics_discussed"
          companyName={companyName}
          containerHeight={400}
          placeholder="Add Topics Discussed"
        />
      </Box>
    </Container>
  );
};

export default AddTopicsDiscussed;