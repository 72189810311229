// DetailedDataTable.js
import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, Button, Box, CircularProgress
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';

const DetailedDataTable = ({ company_name, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailedData();
  }, [company_name, startDate, endDate, dataType]);

  const fetchDetailedData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const unsafeActCollection = db
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('unsafe_act');

    const querySnapshot = await unsafeActCollection.get();

    const detailedData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const dateObj = data.date.toDate ? data.date.toDate() : new Date(data.date);

      if (dateObj >= startDate && dateObj <= endDate) {
        const isRelevant = (
          (dataType === 'unsafeActOpen' && data.typeOfObservation === 'Unsafe Act' && data.isPending) ||
          (dataType === 'unsafeActClosed' && data.typeOfObservation === 'Unsafe Act' && !data.isPending) ||
          (dataType === 'unsafeConditionOpen' && data.typeOfObservation === 'Unsafe Condition' && data.isPending) ||
          (dataType === 'unsafeConditionClosed' && data.typeOfObservation === 'Unsafe Condition' && !data.isPending)
        );

        if (isRelevant) {
          detailedData.push({
            caseNumber: data.caseNumber,
            date: dateObj.toLocaleDateString(),
            auditorName: data.auditorName,
            employeeId: data.employeeId,
            reportingPersonDesignation: data.reportingPersonDesignation,
            typeOfObservation: data.typeOfObservation,
            description: data.description,
            severity: data.severity,
            immediateActionRequired: data.immediateActionRequired,
            departmentName: data.departmentName,
            areaName: data.areaName,
            responsiblePerson: data.responsiblePerson,
            responsibleDepartment: data.responsibleDepartment,
            plannedDateOfClosing: data.plannedDateOfClosing,
          });
        }
      }
    });

    setData(detailedData);
    setLoading(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(workbook, `${dataType}_detailed_data.xlsx`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detailed Data for {dataType}
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '1rem' }}>
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Case Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Auditor Name</TableCell>
              <TableCell>Employee ID</TableCell>
              <TableCell>Reporting Person Designation</TableCell>
              <TableCell>Type of Observation</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Immediate Action Required</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>Responsible Person</TableCell>
              <TableCell>Responsible Department</TableCell>
              <TableCell>Planned Date of Closing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.caseNumber}>
                <TableCell>{row.caseNumber}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.auditorName}</TableCell>
                <TableCell>{row.employeeId}</TableCell>
                <TableCell>{row.reportingPersonDesignation}</TableCell>
                <TableCell>{row.typeOfObservation}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.severity}</TableCell>
                <TableCell>{row.immediateActionRequired}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.areaName}</TableCell>
                <TableCell>{row.responsiblePerson}</TableCell>
                <TableCell>{row.responsibleDepartment}</TableCell>
                <TableCell>{row.plannedDateOfClosing}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedDataTable;