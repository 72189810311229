// import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Button, Typography, Box , ThemeProvider} from '@material-ui/core';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import { styled } from '@mui/material';

// const StyledButton = styled(Button)(({ theme }) => ({
//   marginRight: theme.spacing(2),
// }));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     gap: theme.spacing(2),
//   },
//   input: {
//     display: 'none',
//   },
//   preview: {
//     maxWidth: '100%',
//     maxHeight: 200,
//     objectFit: 'contain',
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
// }));

// const ImageUploader = ({ value, onChange, error, helperText , theme}) => {
//   const classes = useStyles();
//   const [preview, setPreview] = useState(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreview(reader.result);
//       };
//       reader.readAsDataURL(file);
//       onChange(file);
//     }
//   };

//   return (
//     <ThemeProvider theme={theme} >

//     <Box>
//       <input
//         accept="image/*"
//         className={classes.input}
//         id="contained-button-file"
//         type="file"
//         onChange={handleFileChange}
//       />
//       <label htmlFor="contained-button-file">
//         <StyledButton
//           variant="contained"
//           color="primary"
//           component="span"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload Image
//         </StyledButton>
//       </label>
//       {error && (
//         <Typography variant="caption" className={classes.error}>
//           {helperText}
//         </Typography>
//       )}
//       {preview && (
//         <img src={preview} alt="Preview" className={classes.preview} />
//       )}
//     </Box>
//     </ThemeProvider>
//   );
// };

// export default ImageUploader;

import React, { useEffect, useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const PreviewImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  objectFit: 'contain',
  marginTop: theme.spacing(2),
}));

// const ImageUploader = ({ value, onChange, error, helperText, localPreview }) => {
//   const [preview, setPreview] = useState(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreview(reader.result);
//       };
//       reader.readAsDataURL(file);
//       onChange(file);
//     }
//   };

//   useEffect(() => {
//     setPreview(localPreview);
//   }, [localPreview]);

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
//       <Input
//         accept="image/*"
//         id="contained-button-file"
//         type="file"
//         onChange={handleFileChange}
//       />
//       <label htmlFor="contained-button-file">
//         <Button
//           variant="contained"
//           color="primary"
//           component="span"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload Image
//         </Button>
//       </label>
//       {error && (
//         <Typography variant="caption" color="error">
//           {helperText}
//         </Typography>
//       )}
//       {preview && (
//         <PreviewImage src={preview} alt="Preview" />
//       )}
//     </Box>
//   );
// };

// export default ImageUploader;


const ImageUploader = ({ value, onChange, error, helperText, preview }) => {
  const [localPreview, setLocalPreview] = useState(preview);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalPreview(reader.result);
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  useEffect(() => {
    setLocalPreview(preview);
  }, [preview]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
      <Input
        accept="image/*"
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload Image
        </Button>
      </label>
      {error && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
      {localPreview && (
        <PreviewImage src={localPreview} alt="Preview" />
      )}
    </Box>
  );
};
export default ImageUploader;
