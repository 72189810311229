import React from 'react';
import { Box, Grid } from '@mui/material';
import PriceCard from './PriceCard';

const PricingSection = () => {
  const pricingTiers = [
    {
      title: "Basic",
      price: "2000",
      desc: "Get Started with your Safety Journey.",
      description: [
        "Near miss Reporting",
        "Unsafe Act Reporting",
        "Accident Reporting",
      ],
      feature: [
        "Up to 10 Users Access",
        "1 Admin License",
        "Reporting Analytics",
        "Up to 5GB Storage",
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
    },
    {
      title: "Advance",
      price: "5000",
      desc: "Take your Safety Journey to the next level",
      description: [
        "Near miss Reporting",
        "Unsafe Act Reporting",
        "Accident Reporting",
        "PPE Grievance Reporting",
        "Fire Incident Reporting"
      ],
      feature: [
        "Up to 20 Users Access",
        "2 Admin License",
        "Reporting Analytics",
        "Reporting Tracking",
        "Up to 15GB Storage",
        "Data Export Access",
        "1 Training Exam Uploaded on Request"
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
    },
    {
      title: "Pro",
      price: "",
      desc: "Implement Advanced Safety with Improvement Scheduling/Tracking",
      description: [
        "Near miss Reporting",
        "Unsafe Act Reporting",
        "Accident Reporting",
        "Work Permit",
        "Kaizen Reporting",
        "Weekly Safety Meeting"
      ],
      feature: [
        "Up to 50 Users Access",
        "5 Admin License",
        "Reporting Analytics",
        "Report Tracking",
        "Up to 30GB Storage",
        "Data Export Access",
        "2 Training Exam Uploaded on Request"
      ],
      buttonText: "Get in Touch",
      buttonVariant: "contained",
    }
  ];

  return (
    <Box sx={{ padding: 2,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center' }}>
      <Box container sx={{display:'flex',gap:4}} justifyContent="center">
        {pricingTiers.map((tier, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PriceCard
              title={tier.title}
              price={tier.price}
              desc={tier.desc}
              description={tier.description}
              feature={tier.feature}
              buttonText={tier.buttonText}
              buttonVariant={tier.buttonVariant}
            />
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default PricingSection;
