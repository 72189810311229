import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  CardContent,
  Card,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./FireIncident/DetailedDataTable";

import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faFireExtinguisher, faRoadCircleExclamation, faRoute } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const FireInjuryChart = ({ company_name }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    monthlyFireIncident: null,
    departmentReportedVsClosed: null,
    departmentActionPlanOpenVsClosed: null,
    areaReportedVsClosed: null,
    areaActionPlanOpenVsClosed: null,
    departmentReportedVsInvestigated: null,
    departmentInvestigatedVsTimelyInvestigated: null,
  });
  const [totals, setTotals] = useState({
    totalReported: 0,
    totalClosed: 0,
    totalInvestigated: 0,
    totalTimelyInvestigated: 0,
    totalActionPlanOpen: 0,
    totalActionPlanClosed: 0,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const chartRefs = useRef([]);

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === "string") {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return "Invalid Date";
  };

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const fireInjuryReportsCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("fire_injury_reports");

    const querySnapshot = await fireInjuryReportsCollection.get();

    const monthlyData = {};
    const departmentData = {};
    const areaData = {};
    let totalReported = 0;
    let totalClosed = 0;
    let totalInvestigated = 0;
    let totalTimelyInvestigated = 0;
    let totalActionPlanOpen = 0;
    let totalActionPlanClosed = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        departmentName,
        areaName,
        isPending,
        dateOfFireIncident,
        DateOfInvestigation,
        dateOfClosing,
        actionPlans,
      } = data;

      //  const dateObj = new Date(dateOfFireIncident);
      const dateObj =
        data.dateOfFireIncident instanceof firebase.firestore.Timestamp
          ? data.dateOfFireIncident.toDate()
          : new Date(data.dateOfFireIncident);
      // console.log('dateobjs',dateObj,startDate.toDate(),endDate.toDate());
      if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
        const monthLabel = `${dateObj.toLocaleString("default", {
          month: "short",
        })} ${dateObj.getFullYear()}`;
        // console.log('month',monthLabel);
        // Update monthly data
        if (!monthlyData[monthLabel]) {
          monthlyData[monthLabel] = { reported: 0 };
        }
        monthlyData[monthLabel].reported++;

        // Update department data
        if (!departmentData[departmentName]) {
          departmentData[departmentName] = {
            reported: 0,
            closed: 0,
            investigated: 0,
            timelyInvestigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        departmentData[departmentName].reported++;
        if (!isPending) departmentData[departmentName].closed++;

        // Update area data
        if (!areaData[areaName]) {
          areaData[areaName] = {
            reported: 0,
            closed: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        areaData[areaName].reported++;
        if (!isPending) areaData[areaName].closed++;

        // Check if investigated
        if (DateOfInvestigation) {
          departmentData[departmentName].investigated++;
          totalInvestigated++;

          // Check if timely investigated
          const investigationDate = new Date(DateOfInvestigation);
          const closingDate = new Date(dateOfClosing);
          const daysDifference =
            (closingDate - investigationDate) / (1000 * 60 * 60 * 24);

          if (daysDifference <= 2) {
            departmentData[departmentName].timelyInvestigated++;
            totalTimelyInvestigated++;
          }
        }

        // Update action plan data
        if (actionPlans && Array.isArray(actionPlans)) {
          actionPlans.forEach((plan) => {
            if (plan.status === "Open") {
              departmentData[departmentName].actionPlanOpen++;
              areaData[areaName].actionPlanOpen++;
              totalActionPlanOpen++;
            } else if (plan.status === "Close") {
              departmentData[departmentName].actionPlanClosed++;
              areaData[areaName].actionPlanClosed++;
              totalActionPlanClosed++;
            }
          });
        }

        // Update totals
        totalReported++;
        if (!isPending) totalClosed++;
      }
    });

    const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    setChartData({
      monthlyFireIncident: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Reported",
            data: sortedMonthLabels.map((label) => monthlyData[label].reported),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      },
      departmentReportedVsClosed: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(departmentData).map((d) => d.reported),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Closed",
            data: Object.values(departmentData).map((d) => d.closed),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      departmentActionPlanOpenVsClosed: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(departmentData).map((d) => d.actionPlanOpen),
            backgroundColor: "rgba(255, 159, 64, 0.5)",
          },
          {
            label: "Action Plan Closed",
            data: Object.values(departmentData).map((d) => d.actionPlanClosed),
            backgroundColor: "rgba(75, 192, 192, 0.5)",
          },
        ],
      },
      areaReportedVsClosed: {
        labels: Object.keys(areaData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(areaData).map((d) => d.reported),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Closed",
            data: Object.values(areaData).map((d) => d.closed),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      areaActionPlanOpenVsClosed: {
        labels: Object.keys(areaData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(areaData).map((d) => d.actionPlanOpen),
            backgroundColor: "rgba(255, 159, 64, 0.5)",
          },
          {
            label: "Action Plan Closed",
            data: Object.values(areaData).map((d) => d.actionPlanClosed),
            backgroundColor: "rgba(75, 192, 192, 0.5)",
          },
        ],
      },
      departmentReportedVsInvestigated: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(departmentData).map((d) => d.reported),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Investigated",
            data: Object.values(departmentData).map((d) => d.investigated),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      departmentInvestigatedVsTimelyInvestigated: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Investigated",
            data: Object.values(departmentData).map((d) => d.investigated),
            backgroundColor: "rgba(255, 159, 64, 0.5)",
          },
          {
            label: "Timely Investigated",
            data: Object.values(departmentData).map(
              (d) => d.timelyInvestigated
            ),
            backgroundColor: "rgba(75, 192, 192, 0.5)",
          },
        ],
      },
    });

    setTotals({
      totalReported,
      totalClosed,
      totalInvestigated,
      totalTimelyInvestigated,
      totalActionPlanOpen,
      totalActionPlanClosed,
    });
    setLoading(false);
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("fire_incident_charts.pdf");
      }
    };
    processCharts(0);
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Fire Incident Charts" },
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1500,
      easing: "easeInOutBounce",
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Categories",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Values",
        },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <Box>
        <ChartContainer elevation={3} sx={{ p: 2, position: "relative" }}>
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
            {console.log("chartComponent", chartComponent)}
            <IconButton
              onClick={() => handleClickOpen(index, title, chartComponent)}
            >
              <FullscreenIcon />
            </IconButton>
            {/* <IconButton onClick={() => handleFullScreen(index)}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton> */}
            {!isFullScreen && (
              <IconButton onClick={() => handleResize(index)}>
                <AspectRatioIcon />
              </IconButton>
            )}
          </Box>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ fontSize: "0.895rem" }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              height: isFullScreen
                ? "calc(100% - 60px)"
                : chartSizes[index].height,
              width: "100%",
            }}
          >
            {chartComponent}
          </Box>
        </ChartContainer>
      </Box>
    );
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };
  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Fire Incident Report
      </Typography>

      <Box mb={2}>
        <Grid container spacing={2}>
        <CustomCard
            bannerText="Fire Incident"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("reported")}
            onCloseClick={() => handleTotalClick("investigated")}
            openTitle="Reported"
            openValue={totals.totalReported}
            closedTitle="Investigated"
            closedValue={totals.totalInvestigated}
            subtitleColor="text.secondary"
          />
          <CustomCard
            bannerText="Action Plan"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("open")}
            onCloseClick={() => handleTotalClick("closed")}
            openTitle="open"
            openValue={totals.totalActionPlanOpen}
            closedTitle="closed"
            closedValue={totals.totalClosed}
            subtitleColor="text.secondary"
          />
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {currentChart?.title} - Full Screen
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {currentChart?.component}
          {/* {renderAdditionalInfo()} */}
          {/* Add additional information here */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Additional information about this chart can be displayed here.
          </Typography>
        </DialogContent>
      </Dialog>
      {/* 
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={4}> */}
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: { xs: 2, md: 0 },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid>
        <Grid item xs={12} md={4}> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid> */}
        </Box>
        {/* <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" onClick={fetchData} fullWidth>
            Generate Charts
          </Button>
        </Grid>
      </Grid> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Paper elevation={3} ref={el => chartRefs.current[0] = el}>
                <Typography variant="h6" align="center" gutterBottom>
                  Monthly Fire Incident Reported
                </Typography>
                {chartData.monthlyFireIncident ? (
                  <Line data={chartData.monthlyFireIncident} options={chartOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )}
              </Paper>
            </Grid> */}

            <Grid item xs={12} md={9} ref={(el) => (chartRefs.current[0] = el)}>
              {renderChart(
                0,
                "Monthly Fire Incident Reported",
                chartData.monthlyFireIncident ? (
                  <Line
                    data={chartData.monthlyFireIncident}
                    options={lineChartOptions}
                  />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>

            {/* <Grid item xs={12} md={3}>
    <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <Button  onClick={() => handleTotalClick('reported')}>
      <Typography variant="h6" align="center" gutterBottom>
      Total reported: {totals.totalReported}
      </Typography>
      </Button>
      <Button  onClick={() => handleTotalClick('closed')}>
      <Typography variant="h6" align="center" gutterBottom>
      Total Closed: {totals.totalClosed}
      </Typography>
      </Button>
      <Button  onClick={() => handleTotalClick('investigated')}>
      <Typography variant="h6" align="center" gutterBottom>
      Total Investigated: {totals.totalInvestigated}
      </Typography>
      </Button>
      <Button  onClick={() => handleTotalClick('actionPlanOpen')}>
      <Typography variant="h6" align="center" gutterBottom>
      Total Action Plan Open: {totals.totalActionPlanOpen}
      </Typography>
      </Button>
      <Button  onClick={() => handleTotalClick('actionPlanClosed')}>
      <Typography variant="h6" align="center" gutterBottom>
      Total Action Plan Closed: {totals.totalActionPlanClosed}
      </Typography>
      </Button>
    </Paper>
  </Grid> */}
          </Grid>

          {/* {Object.entries(chartData).slice(1).map(([key, data], index) => (
              <Grid item xs={12} md={6} key={key}>
                <Paper elevation={3} ref={el => chartRefs.current[index + 1] = el}>
                  <Typography variant="h6" align="center" gutterBottom>
                    {key.split(/(?=[A-Z])/).join(' ')}
                  </Typography>
                  {data ? (
                    <Bar data={data} options={chartOptions} />
                  ) : (
                    <Typography align="center" color="error">
                      No data available
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))} */}

          <Grid container spacing={3} pt={3}>
            {Object.entries(chartData)
              .slice(1)
              .map(([key, data], index) => (
                <Grid item xs={12} md={6} key={key}>
                  <Paper
                    elevation={3}
                    ref={(el) => (chartRefs.current[index + 1] = el)}
                  >
                    {renderChart(
                      1,
                      key.split(/(?=[A-Z])/).join(" "),
                      data ? (
                        <Bar data={data} options={barChartOptions} />
                      ) : (
                        <Typography align="center" color="error">
                          No data available
                        </Typography>
                      )
                    )}
                  </Paper>
                </Grid>
              ))}
          </Grid>

          {/* <Box mt={3}>
            <Typography variant="h6" gutterBottom>Totals</Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('reported')}>
                Total Reported: {totals.totalReported}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('closed')}>
                Total Closed: {totals.totalClosed}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('investigated')}>
                Total Investigated: {totals.totalInvestigated}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('actionPlanOpen')}>
                Total Action Plan Open: {totals.totalActionPlanOpen}
              </Button>
            </Typography>
            <Typography>
              <Button color="primary" onClick={() => handleTotalClick('actionPlanClosed')}>
                Total Action Plan Closed: {totals.totalActionPlanClosed}
              </Button>
            </Typography>
        </Box>
           */}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>Detailed Data</DialogTitle>
            <DialogContent>
              <DetailedDataTable
                companyName={company_name}
                startDate={startDate.toDate()}
                endDate={endDate.toDate()}
                dataType={selectedDataType}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default FireInjuryChart;
