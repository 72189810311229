import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, Button, Box, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions, styled, TextField, Card, CardContent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./NearMissReport/DetailedDataTable";
import { COLORS } from "../../utils/Constants";

import CustomCard from "../../componants/CustomCard";
import { faPersonBurst } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#4caf50", // Label color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1976d2", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#0288d1", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0288d1", // Border color when focused
    },
  },
  "& .MuiSvgIcon-root": {
    color: "#ff5722", // Icon color
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const NearMissChart = ({ company_name }) => {
  console.log("comapany name", company_name);
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    monthlyReportedVsInvestigated: null,
    monthlyActionPlanOpenVsClosed: null,
    areaReportedVsInvestigated: null,
    areaActionPlanOpenVsClosed: null,
    designationReportedVsInvestigated: null,
    designationActionPlanOpenVsClosed: null,
    departmentReportedVsInvestigated: null,
    departmentActionPlanOpenVsClosed: null,
  });
  const [totals, setTotals] = useState({
    totalReported: 0,
    totalInvestigated: 0,
    totalActionPlanOpen: 0,
    totalActionPlanClosed: 0,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const chartRefs = useRef([]);

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const nearMissReportsCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("near_miss_report");

    const querySnapshot = await nearMissReportsCollection.get();

    const monthlyData = {};
    const areaData = {};
    const designationData = {};
    const departmentData = {};
    let totalReported = 0;
    let totalInvestigated = 0;
    let totalActionPlanOpen = 0;
    let totalActionPlanClosed = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        areaName,
        departmentName,
        reportingPersonDesignation,
        isPending,
        dateOfNearMiss,
      } = data;
      //  console.log('actionPlans', data.actionPlans);
      const dateObj = dateOfNearMiss.toDate();
      if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
        //const formatedDateOfDocument = typeof dateOfNearMiss === 'string' ? new Date(dateOfNearMiss).getTime() : dateOfNearMiss.toMillis();
        //if (formatedDateOfDocument >= startDate.valueOf() && formatedDateOfDocument <= endDate.valueOf()) {
        const monthLabel = `${dateObj.toLocaleString("default", {
          month: "short",
        })} ${dateObj.getFullYear()}`;
        // Update monthly data
        if (!monthlyData[monthLabel]) {
          monthlyData[monthLabel] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        monthlyData[monthLabel].reported++;
        if (!isPending) monthlyData[monthLabel].investigated++;

        // Update area data
        if (!areaData[areaName]) {
          areaData[areaName] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        areaData[areaName].reported++;
        if (!isPending) areaData[areaName].investigated++;

        // Update designation data
        if (!designationData[reportingPersonDesignation]) {
          designationData[reportingPersonDesignation] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        designationData[reportingPersonDesignation].reported++;
        if (!isPending)
          designationData[reportingPersonDesignation].investigated++;

        // Update department data
        if (!departmentData[departmentName]) {
          departmentData[departmentName] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        departmentData[departmentName].reported++;
        if (!isPending) departmentData[departmentName].investigated++;

        //  console.log('action plans',data.actionPlans);

        // // Update action plan data
        if (data.actionPlans && Array.isArray(data.actionPlans)) {
          data.actionPlans.forEach((plan) => {
            if (plan.status === "Open") {
              // console.log('plans',plan);
              monthlyData[monthLabel].actionPlanOpen++;
              areaData[areaName].actionPlanOpen++;
              designationData[reportingPersonDesignation].actionPlanOpen++;
              departmentData[departmentName].actionPlanOpen++;
              totalActionPlanOpen++;
            } else if (plan.status === "Close") {
              monthlyData[monthLabel].actionPlanClosed++;
              areaData[areaName].actionPlanClosed++;
              designationData[reportingPersonDesignation].actionPlanClosed++;
              departmentData[departmentName].actionPlanClosed++;
              totalActionPlanClosed++;
            }
          });
        }
        // Update totals
        totalReported++;
        if (!isPending) totalInvestigated++;
      }
    });
    const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    setChartData({
      MonthlyReportedVsInvestigated: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Reported",
            data: sortedMonthLabels.map((label) => monthlyData[label].reported),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "#FF3800",
          },
          {
            label: "Investigated",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].investigated
            ),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "blue",
          },
        ],
      },

      MonthlyActionPlanOpenVsClosed: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Action Plan Open",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].actionPlanOpen
            ),
            borderColor: "rgb(255, 159, 64)",
            backgroundColor: "#EF0107",
          },
          {
            label: "Action Plan Closed",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].actionPlanClosed
            ),
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "#00FF00",
          },
        ],
      },
      AreaReportedVsInvestigated: {
        labels: Object.keys(areaData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(areaData).map((d) => d.reported),
            backgroundColor: "#FF3800",
          },
          {
            label: "Investigated",
            data: Object.values(areaData).map((d) => d.investigated),
            backgroundColor: "blue",
          },
        ],
      },
      AreaActionPlanOpenVsClosed: {
        labels: Object.keys(areaData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(areaData).map((d) => d.actionPlanOpen),
            backgroundColor: "#EF0107",
          },
          {
            label: "Action Plan Closed",
            data: Object.values(areaData).map((d) => d.actionPlanClosed),
            backgroundColor: "#00FF00",
          },
        ],
      },
      DesignationReportedVsInvestigated: {
        labels: Object.keys(designationData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(designationData).map((d) => d.reported),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Investigated",
            data: Object.values(designationData).map((d) => d.investigated),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      DesignationActionPlanOpenVsClosed: {
        labels: Object.keys(designationData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(designationData).map((d) => d.actionPlanOpen),
            backgroundColor: COLORS.OPEN,
          },
          {
            label: "Action Plan Closed",
            data: Object.values(designationData).map((d) => d.actionPlanClosed),
            backgroundColor: COLORS.CLOSED,
          },
        ],
      },
      DepartmentReportedVsInvestigated: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Reported",
            data: Object.values(departmentData).map((d) => d.reported),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Investigated",
            data: Object.values(departmentData).map((d) => d.investigated),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      DepartmentActionPlanOpenVsClosed: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Action Plan Open",
            data: Object.values(departmentData).map((d) => d.actionPlanOpen),
            backgroundColor: COLORS.OPEN,
          },
          {
            label: "Action Plan Closed",
            data: Object.values(departmentData).map((d) => d.actionPlanClosed),
            backgroundColor: COLORS.CLOSED,
          },
        ],
      },
    });

    setTotals({
      totalReported,
      totalInvestigated,
      totalActionPlanOpen,
      totalActionPlanClosed,
    });
    setLoading(false);
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("near_miss_charts.pdf");
      }
    };
    processCharts(0);
  };

  // const chartOptions = {
  //   responsive: true,
  //   scales: {
  //     x: { stacked: true, grid: { display: false } },
  //     y: { stacked: true, grid: { display: false } },
  //   },
  //   plugins: {
  //     legend: { position: "top" },
  //     title: { display: true, text: "Near Miss Charts", font:{family:'poppins',size:14,weight:400},color: '#002244', },
  //   },
  // };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: { display: false, offset: true },
        ticks: {
          font: {
            family: "'Roboto', sans-serif", // Font family for x-axis labels
            size: 12, // Font size for x-axis labels
          },
          color: "#666", // Color for x-axis labels
          padding: 10,
        },
        offset: true,
      },
      y: {
        stacked: true,
        grid: { display: true },
        ticks: {
          callback: function (value, index, values) {
            // Customizing the tick labels
            // Example: Change to a percentage
            return value;
          },
          padding: 10,
          font: {
            family: "'Roboto', sans-serif", // Font family for y-axis labels
            size: 12, // Font size for y-axis labels
          },
          color: "#666", // Color for y-axis labels
          stepSize: 1,
        },
        min: 0,
        max: 5,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "'Roboto', sans-serif", // Font family for legend labels
            size: 14, // Font size for legend labels
            weight: "400",
          },
          color: "#333", // Color for legend labels
        },
      },
      title: {
        display: true,
        text: "Near Miss Charts",
        font: {
          family: "'poppins', sans-serif", // Font family for title
          size: 14, // Font size for title
          weight: "400",
        },
        color: "#002244", // Color for title
      },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
      <Box p={2}>
        <Typography variant="h4" gutterBottom fontWeight={"bold"}>
          Near Miss Report
        </Typography>
        <Box mb={2}>
            <Grid container spacing={2}>
            <CustomCard
            bannerText="Near Miss Reported"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("reported")}
            onCloseClick={() => handleTotalClick("investigated")}
            openTitle="Reported"
            openValue={totals.totalReported}
            closedTitle="Invetigated"
            closedValue={totals.totalInvestigated}
            subtitleColor="text.secondary"
          />
              <CustomCard
                bannerText="Action Plan"
                bannerColor="#ff5722"
                bannerTextColor="#f8f9fa"
                onOpenClick={() => handleTotalClick("actionPlanOpen")}
                onCloseClick={() => handleTotalClick("actionPlanClosed")}
                openTitle="Open"
                openValue={totals.totalActionPlanOpen}
                closedTitle="Closed"
                closedValue={totals.totalActionPlanClosed}
                subtitleColor="text.secondary"
              />
            </Grid>
        </Box>
        <Grid container spacing={3} mb={3} mt={2}>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              onClick={fetchData}
            >
              <Typography>Generate Charts</Typography>
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {Object.entries(chartData).map(([key, data], index) => (
                <Grid item xs={12} md={6} key={key}>
                  <Paper
                    elevation={12}
                    ref={(el) => (chartRefs.current[index] = el)}
                    sx={{ p: 2 }}
                  >
                    <Typography variant="h6" align="center" gutterBottom>
                      {key.split(/(?=[A-Z])/).join(" ")}
                    </Typography>
                    {data ? (
                      key.includes("monthly") ? (
                        <Line data={data} options={chartOptions} />
                      ) : (
                        <Bar data={data} options={chartOptions} />
                      )
                    ) : (
                      <Typography align="center" color="error">
                        No data available
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>Detailed Data</DialogTitle>
              <DialogContent>
                <DetailedDataTable
                  companyName={company_name}
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  dataType={selectedDataType}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Box mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExportPDF}
          >
            Export as PDF
          </Button>
        </Box>
      </Box>
  );
};
export default NearMissChart;

// import React, { useEffect, useState, useRef } from "react";
// import {
//   Container,
//   Grid,
//   Paper,
//   Typography,
//   Button,
//   Box,
//   CircularProgress,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   DialogActions,
//   TextField,
// } from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import dayjs from "dayjs";
// import { Bar, Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import DetailedDataTable from "./NearMissReport/DetailedDataTable";
// import { COLORS } from "../../utils/Constants";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const NearMissChart = ({ company_name }) => {
//   const [startDate, setStartDate] = useState(dayjs().startOf("year"));
//   const [endDate, setEndDate] = useState(dayjs());
//   const [loading, setLoading] = useState(false);
//   const [chartData, setChartData] = useState({});
//   const [totals, setTotals] = useState({});
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedDataType, setSelectedDataType] = useState("");
//   const chartRefs = useRef([]);

//   useEffect(() => {
//     fetchData();
//   }, [company_name, startDate, endDate]);

//   const fetchData = async () => {
//     setLoading(true);
//     const db = firebase.firestore();
//     const reports = await fetchNearMissReports(db);
//     const {
//       monthlyData,
//       areaData,
//       designationData,
//       departmentData,
//       totalReported,
//       totalInvestigated,
//       totalActionPlanOpen,
//       totalActionPlanClosed,
//     } = processReports(reports);

//     setChartData(
//       generateChartData(monthlyData, areaData, designationData, departmentData)
//     );
//     setTotals({
//       totalReported,
//       totalInvestigated,
//       totalActionPlanOpen,
//       totalActionPlanClosed,
//     });
//     setLoading(false);
//   };

//   const fetchNearMissReports = async (db) => {
//     const collection = db
//       .collection("company_data")
//       .doc(company_name)
//       .collection(company_name)
//       .doc("for_user")
//       .collection("near_miss_report");

//     const querySnapshot = await collection.get();
//     return querySnapshot.docs.map((doc) => doc.data());
//   };

//   const processReports = (reports) => {
//     const monthlyData = {};
//     const areaData = {};
//     const designationData = {};
//     const departmentData = {};
//     let totalReported = 0;
//     let totalInvestigated = 0;
//     let totalActionPlanOpen = 0;
//     let totalActionPlanClosed = 0;

//     reports.forEach((data) => {
//       const {
//         areaName,
//         departmentName,
//         reportingPersonDesignation,
//         isPending,
//         dateOfNearMiss,
//         actionPlans = [],
//       } = data;

//       const dateObj = dateOfNearMiss.toDate();
//       if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
//         const monthLabel = `${dateObj.toLocaleString("default", {
//           month: "short",
//         })} ${dateObj.getFullYear()}`;
//         updateMonthlyData(monthLabel, monthlyData, isPending, actionPlans);
//         updateAreaData(areaName, areaData, isPending, actionPlans);
//         updateDesignationData(
//           reportingPersonDesignation,
//           designationData,
//           isPending,
//           actionPlans
//         );
//         updateDepartmentData(
//           departmentName,
//           departmentData,
//           isPending,
//           actionPlans
//         );

//         totalReported++;
//         if (!isPending) totalInvestigated++;
//         totalActionPlanOpen += actionPlans.filter(
//           (plan) => plan.status === "Open"
//         ).length;
//         totalActionPlanClosed += actionPlans.filter(
//           (plan) => plan.status === "Close"
//         ).length;
//       }
//     });

//     return {
//       monthlyData,
//       areaData,
//       designationData,
//       departmentData,
//       totalReported,
//       totalInvestigated,
//       totalActionPlanOpen,
//       totalActionPlanClosed,
//     };
//   };

//   const updateMonthlyData = (
//     monthLabel,
//     monthlyData,
//     isPending,
//     actionPlans
//   ) => {
//     if (!monthlyData[monthLabel]) {
//       monthlyData[monthLabel] = {
//         reported: 0,
//         investigated: 0,
//         actionPlanOpen: 0,
//         actionPlanClosed: 0,
//       };
//     }
//     monthlyData[monthLabel].reported++;
//     if (!isPending) monthlyData[monthLabel].investigated++;
//     actionPlans.forEach((plan) => {
//       if (plan.status === "Open") monthlyData[monthLabel].actionPlanOpen++;
//       if (plan.status === "Close") monthlyData[monthLabel].actionPlanClosed++;
//     });
//   };

//   const updateAreaData = (areaName, areaData, isPending, actionPlans) => {
//     if (!areaData[areaName]) {
//       areaData[areaName] = {
//         reported: 0,
//         investigated: 0,
//         actionPlanOpen: 0,
//         actionPlanClosed: 0,
//       };
//     }
//     areaData[areaName].reported++;
//     if (!isPending) areaData[areaName].investigated++;
//     actionPlans.forEach((plan) => {
//       if (plan.status === "Open") areaData[areaName].actionPlanOpen++;
//       if (plan.status === "Close") areaData[areaName].actionPlanClosed++;
//     });
//   };

//   const updateDesignationData = (
//     designation,
//     designationData,
//     isPending,
//     actionPlans
//   ) => {
//     if (!designationData[designation]) {
//       designationData[designation] = {
//         reported: 0,
//         investigated: 0,
//         actionPlanOpen: 0,
//         actionPlanClosed: 0,
//       };
//     }
//     designationData[designation].reported++;
//     if (!isPending) designationData[designation].investigated++;
//     actionPlans.forEach((plan) => {
//       if (plan.status === "Open") designationData[designation].actionPlanOpen++;
//       if (plan.status === "Close")
//         designationData[designation].actionPlanClosed++;
//     });
//   };

//   const updateDepartmentData = (
//     department,
//     departmentData,
//     isPending,
//     actionPlans
//   ) => {
//     if (!departmentData[department]) {
//       departmentData[department] = {
//         reported: 0,
//         investigated: 0,
//         actionPlanOpen: 0,
//         actionPlanClosed: 0,
//       };
//     }
//     departmentData[department].reported++;
//     if (!isPending) departmentData[department].investigated++;
//     actionPlans.forEach((plan) => {
//       if (plan.status === "Open") departmentData[department].actionPlanOpen++;
//       if (plan.status === "Close")
//         departmentData[department].actionPlanClosed++;
//     });
//   };

//   const generateChartData = (
//     monthlyData,
//     areaData,
//     designationData,
//     departmentData
//   ) => {
//     const sortedMonthLabels = Object.keys(monthlyData).sort(
//       (a, b) => new Date(a) - new Date(b)
//     );

//     return {
//       MonthlyReportedVsInvestigated: {
//         labels: sortedMonthLabels,
//         datasets: createDatasets(
//           monthlyData,
//           "reported",
//           "investigated",
//           "#FF3800",
//           "blue"
//         ),
//       },
//       MonthlyActionPlanOpenVsClosed: {
//         labels: sortedMonthLabels,
//         datasets: createDatasets(
//           monthlyData,
//           "actionPlanOpen",
//           "actionPlanClosed",
//           "#EF0107",
//           "#00FF00"
//         ),
//       },
//       AreaReportedVsInvestigated: {
//         labels: Object.keys(areaData),
//         datasets: createDatasets(
//           areaData,
//           "reported",
//           "investigated",
//           "#FF3800",
//           "blue"
//         ),
//       },
//       AreaActionPlanOpenVsClosed: {
//         labels: Object.keys(areaData),
//         datasets: createDatasets(
//           areaData,
//           "actionPlanOpen",
//           "actionPlanClosed",
//           "#EF0107",
//           "#00FF00"
//         ),
//       },
//       DesignationReportedVsInvestigated: {
//         labels: Object.keys(designationData),
//         datasets: createDatasets(
//           designationData,
//           "reported",
//           "investigated",
//           "rgba(255, 99, 132, 0.5)",
//           "rgba(53, 162, 235, 0.5)"
//         ),
//       },
//       DesignationActionPlanOpenVsClosed: {
//         labels: Object.keys(designationData),
//         datasets: createDatasets(
//           designationData,
//           "actionPlanOpen",
//           "actionPlanClosed",
//           COLORS.OPEN,
//           COLORS.CLOSED
//         ),
//       },
//       DepartmentReportedVsInvestigated: {
//         labels: Object.keys(departmentData),
//         datasets: createDatasets(
//           departmentData,
//           "reported",
//           "investigated",
//           "rgba(255, 99, 132, 0.5)",
//           "rgba(53, 162, 235, 0.5)"
//         ),
//       },
//       DepartmentActionPlanOpenVsClosed: {
//         labels: Object.keys(departmentData),
//         datasets: createDatasets(
//           departmentData,
//           "actionPlanOpen",
//           "actionPlanClosed",
//           COLORS.OPEN,
//           COLORS.CLOSED
//         ),
//       },
//     };
//   };

//   const createDatasets = (data, key1, key2, color1, color2) => {
//     return [
//       {
//         label: key1
//           .replace(/([A-Z])/g, " $1")
//           .replace(/^./, (str) => str.toUpperCase()), // Format the key for better readability
//         data: Object.values(data).map((item) => item[key1]),
//         backgroundColor: color1,
//         borderColor: color1,
//         borderWidth: 1,
//       },
//       {
//         label: key2
//           .replace(/([A-Z])/g, " $1")
//           .replace(/^./, (str) => str.toUpperCase()),
//         data: Object.values(data).map((item) => item[key2]),
//         backgroundColor: color2,
//         borderColor: color2,
//         borderWidth: 1,
//       },
//     ];
//   };

//   const handleGeneratePDF = async () => {
//     setLoading(true);
//     const pdf = new jsPDF("p", "mm", "a4");

//     for (let i = 0; i < chartRefs.current.length; i++) {
//       const chartRef = chartRefs.current[i];
//       const canvas = await html2canvas(chartRef);
//       const imgData = canvas.toDataURL("image/png");
//       const pageWidth = pdf.internal.pageSize.getWidth();
//       const imgWidth = pageWidth - 20;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       if (i !== 0) pdf.addPage();
//       pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
//     }

//     pdf.save(`${company_name}_nearmiss_report.pdf`);
//     setLoading(false);
//   };

//   return (
//     <Container>
//       {/* Title */}
//       <Typography variant="h4" align="center" gutterBottom>
//         Near Miss Report Analysis - {company_name}
//       </Typography>

//       {/* Date Picker */}
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Grid container spacing={3} justifyContent="center">
//           <Grid item xs={12} sm={6}>
//             <DatePicker
//               label="Start Date"
//               value={startDate}
//               onChange={(newValue) => setStartDate(newValue)}
//               renderInput={(params) => <TextField {...params} fullWidth />}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <DatePicker
//               label="End Date"
//               value={endDate}
//               onChange={(newValue) => setEndDate(newValue)}
//               renderInput={(params) => <TextField {...params} fullWidth />}
//             />
//           </Grid>
//         </Grid>
//       </LocalizationProvider>

//       {/* Loading Spinner */}
//       {loading && (
//         <Box display="flex" justifyContent="center" my={3}>
//           <CircularProgress />
//         </Box>
//       )}

//       {/* Charts */}
//       {!loading && (
//         <>
//           <Grid container spacing={4} justifyContent="center">
//             {Object.entries(chartData).map(([key, data], index) => (
//               <Grid item xs={12} sm={6} key={key}>
//                 <Paper elevation={3}>
//                   <Typography variant="h6" align="center">
//                     {key
//                       .replace(/([A-Z])/g, " $1")
//                       .replace(/^./, (str) => str.toUpperCase())}{" "}
//                     {/* Format the key for better readability */}
//                   </Typography>
//                   <Box ref={(el) => (chartRefs.current[index] = el)}>
//                     {key.includes("ReportedVsInvestigated") ? (
//                       <Bar data={data} />
//                     ) : (
//                       <Line data={data} />
//                     )}
//                   </Box>
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>

//           {/* Export Button */}
//           <Box display="flex" justifyContent="center" my={3}>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleGeneratePDF}
//               disabled={loading}
//             >
//               Export as PDF
//             </Button>
//           </Box>
//         </>
//       )}

//       {/* Detailed Data Dialog */}
//       <Dialog
//         open={openDialog}
//         onClose={() => setOpenDialog(false)}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>{selectedDataType} Detailed Data</DialogTitle>
//         <DialogContent>
//           <DetailedDataTable
//             data={chartData[selectedDataType]}
//             dataType={selectedDataType}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDialog(false)} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default NearMissChart;
