import firebase from 'firebase/app';
import 'firebase/auth';

export const getLoginStatusLocally = async () => {
  try {
    const loginStatus = await localStorage.getItem('isLoggedIn');
    return loginStatus === 'true';
  } catch (error) {
    console.error('Error getting login status:', error);
    return false;
  }
};

export const setLoginStatusLocally = async (status) => {
  try {
    await localStorage.setItem('isLoggedIn', status.toString());
  } catch (error) {
    console.error('Error setting login status:', error);
  }
};

export const getUIDLocally = async () => {
  try {
    const uid = await localStorage.getItem('uid');
    return uid;
  } catch (error) {
    console.error('Error getting UID:', error);
    return null;
  }
};

export const setUIDLocally = async (uid) => {
  try {
    await localStorage.setItem('uid', uid);
  } catch (error) {
    console.error('Error setting UID:', error);
  }
};