import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton, 
  Card, 
  CardContent, 
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore';
//import { db } from './firebase'; // Assume you have a firebase.js file with the db export
import { firestore } from './../firebase/firebaseConfig';
const SearchableInput = ({ collectionName, companyName, containerHeight = 300, placeholder }) => {
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    try {
    //   const companyRef = doc(db, 'company_data', companyName);
      const companyRef = firestore.collection('company_data').doc(companyName);
      const companyNameAdminRef = doc(collection(companyRef, companyName), 'for_admin');
      const collectionRef = collection(companyNameAdminRef, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const tempData = querySnapshot.docs.map(doc => doc.data().name);
      setData(tempData);
      setFilteredData(tempData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = (event) => {
    const text = event.target.value;
    setSearchValue(text);
    const filtered = data.filter((value) =>
      value !== undefined ? value.toLowerCase().includes(text.toLowerCase()) : false
    );
    setFilteredData(filtered);
  };

  const handleAddValue = async () => {
    try {
    //   const companyRef = doc(db, 'company_data', companyName);
    const companyRef = firestore.collection('company_data').doc(companyName);
      const companyNameAdminRef = doc(collection(companyRef, companyName), 'for_admin');
      const collectionRef = collection(companyNameAdminRef, collectionName);
      await setDoc(doc(collectionRef), { name: searchValue });
      setSearchValue('');
      fetchData();
    } catch (error) {
      console.error('Error adding value:', error);
    }
  };

  const deleteValue = async (value) => {
    try {
    //   const companyRef = doc(db, 'company_data', companyName);
    const companyRef = firestore.collection('company_data').doc(companyName);
      const companyNameAdminRef = doc(collection(companyRef, companyName), 'for_admin');
      const collectionRef = collection(companyNameAdminRef, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      querySnapshot.forEach(async (doc) => {
        if (doc.data().name === value) {
          await deleteDoc(doc.ref);
        }
      });
      fetchData();
    } catch (error) {
      console.error('Error deleting value:', error);
    }
  };

  const handleDeleteValue = (value) => {
    setSelectedValue(value);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    deleteValue(selectedValue);
    setOpenDialog(false);
  };

  return (
    <Card sx={{ maxHeight: containerHeight, overflow: 'auto' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleSearch}
            placeholder={placeholder}
            variant="outlined"
            size="small"
          />
          <IconButton color="primary" onClick={handleAddValue}>
            <Add />
          </IconButton>
        </Box>
        <List>
          {filteredData.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteValue(item)}>
                  <Delete />
                </IconButton>
              }
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this value?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SearchableInput;