import { createTheme } from "@mui/material/styles";

// Define custom colors
const primaryColor = '#000000';
const secondaryColor = '#fff';
const errorColor = '#d32f2f';
const warningColor = '#ffa000';
const infoColor = '#0288d1';
const successColor = '#2e7d32';
const backgroundColor = '#f8f9fa';
const textPrimaryColor = '#212121';
const textSecondaryColor = '#757575';

const Theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: warningColor,
    },
    info: {
      main: infoColor,
    },
    success: {
      main: successColor,
    },
    background: {
      default: backgroundColor,
      paper: '#fff',
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    h1: {
      fontSize: '4.25rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 800,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#212529',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#212529',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#adb5bd',
          },
        },
      },
    },
  },
});

export default Theme;
