import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  IconButton,
  CardContent,
  Card,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./WeeklySafetyMeeting/DetailedDataTable";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const WeeklySafetyMeetingChart = ({ company_name }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [departmentChartData, setDepartmentChartData] = useState(null);
  const [areaChartData, setAreaChartData] = useState(null);
  const [totalDepartmentMeetings, setTotalDepartmentMeetings] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");
  const [monthlyData, setMonthlyData] = useState(null);
  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const chartRefs = useRef([]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === "string") {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return "Invalid Date";
  };

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const weeklySafetyReportsCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("weekly_safety_reports");

    const querySnapshot = await weeklySafetyReportsCollection.get();

    const monthlyDataObj = {};
    const departmentData = {};
    const areaData = {};
    let totalDeptCount = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const { departmentName, areaName, date } = data;

      const formatedDateOfDocument =
        typeof date === "string" ? new Date(date).getTime() : date.toMillis();
      const date1 = dayjs(formatDate(date));

      if (
        formatedDateOfDocument >= startDate.valueOf() &&
        formatedDateOfDocument <= endDate.valueOf()
      ) {
        const monthYear = date1.format("MMM YY");
        monthlyDataObj[monthYear] = (monthlyDataObj[monthYear] || 0) + 1;

        if (departmentData[departmentName]) {
          departmentData[departmentName]++;
          totalDeptCount++;
        } else {
          departmentData[departmentName] = 1;
          totalDeptCount++;
        }

        if (areaData[areaName]) {
          areaData[areaName]++;
        } else {
          areaData[areaName] = 1;
        }
      }
    });

    const formattedDepartmentData = Object.entries(departmentData).map(
      ([label, value]) => ({
        label,
        value,
      })
    );

    const formattedAreaData = Object.entries(areaData).map(
      ([label, value]) => ({
        label,
        value,
      })
    );

    setMonthlyData({
      labels: Object.keys(monthlyDataObj).sort((a, b) =>
        dayjs(a, "MMM YY").diff(dayjs(b, "MMM YY"))
      ),
      datasets: [
        {
          label: "Weekly safety meeting Reported",
          data: Object.values(monthlyDataObj),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });

    setDepartmentChartData({
      labels: formattedDepartmentData.map((item) => item.label),
      datasets: [
        {
          label: "Department Meetings",
          data: formattedDepartmentData.map((item) => item.value),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
      ],
    });

    setAreaChartData({
      labels: formattedAreaData.map((item) => item.label),
      datasets: [
        {
          label: "Area Meetings",
          data: formattedAreaData.map((item) => item.value),
          backgroundColor: "rgba(255, 99, 132, 0.6)",
        },
      ],
    });

    setTotalDepartmentMeetings(totalDeptCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("weekly_safety_meeting_charts.pdf");
      }
    };
    processCharts(0);
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: { grid: { display: false } },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Meetings" },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFullScreen = (index) => {
    if (fullScreenChart === index) {
      setFullScreenChart(null);
    } else {
      setFullScreenChart(index);
    }
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };

  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <ChartContainer elevation={3} sx={{ p: 2, position: "relative" }}>
        <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
          <IconButton
            onClick={() => handleClickOpen(index, title, chartComponent)}
          >
            <FullscreenIcon />
          </IconButton>
          {!isFullScreen && (
            <IconButton onClick={() => handleResize(index)}>
              <AspectRatioIcon />
            </IconButton>
          )}
        </Box>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Box
          sx={{
            height: isFullScreen
              ? "calc(100% - 60px)"
              : chartSizes[index].height,
            width: "100%",
          }}
        >
          {chartComponent}
        </Box>
      </ChartContainer>
    );
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Weekly Safety Meeting Report
      </Typography>
      <Box mb={2}>
        <Grid container spacing={2}>
          <CustomCard
            bannerText="Meetings"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            onCardClick={() => handleTotalClick("meetings")}
            openTitle="Reported"
            openValue={totalDepartmentMeetings}
            subtitle="Reported" 
          />
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {currentChart?.title} - Full Screen
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {currentChart?.component}
          {/* {renderAdditionalInfo()} */}
          {/* Add additional information here */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Additional information about this chart can be displayed here.
          </Typography>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: { xs: 2, md: 0 },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} ref={(el) => (chartRefs.current[0] = el)}>
            {renderChart(
              0,
              "Monthly Weekly Safety Meetings Reported",
              monthlyData ? (
                <Line data={monthlyData} options={lineChartOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
          {/* <Grid item xs={12} md={3}>
    <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <Button  onClick={() => handleTotalClick('meetings')}>
      <Typography variant="h6" align="center" gutterBottom>
        Total Meetings
      </Typography>
      <Typography variant="h3" align="center" color="primary">
        {totalDepartmentMeetings}
      </Typography>
      </Button>
    </Paper>
  </Grid> */}
          <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[1] = el)}>
            {renderChart(
              1,
              "Department-wise Meetings",
              departmentChartData ? (
                <Bar data={departmentChartData} options={barChartOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
          <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[2] = el)}>
            {renderChart(
              2,
              "Area-wise Meetings",
              areaChartData ? (
                <Bar data={areaChartData} options={barChartOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
        </Grid>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Detailed Data</DialogTitle>
        <DialogContent>
          <DetailedDataTable
            company_name={company_name}
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
            dataType={selectedDataType}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WeeklySafetyMeetingChart;
