import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Container, Typography, TextField, Button, IconButton, 
  Grid, Paper, Select, MenuItem, FormControl, InputLabel 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { firestore } from '../../firebase/firebaseConfig';

//const firestore = firebase.firestore();

const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    leaderContainer: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    leaderTitle: {
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    addButton: {
      marginRight: theme.spacing(1),
    },
    submitButton: {
      marginTop: theme.spacing(3),
    },
  }));
const ZoneLeader = ({ companyName }) => {
  const classes = useStyles();
  const [leaders, setLeaders] = useState([
    {
      name: '',
      contact: '',
      email: '',
      category: '',
    },
  ]);

  const [errors, setErrors] = useState({});

  const handleAddLeader = () => {
    setLeaders([
      ...leaders,
      {
        name: '',
        contact: '',
        email: '',
        category: '',
      },
    ]);
  };

  const handleSubmit = async () => {
    const errors1 = validateFields();
    if (Object.keys(errors1).length > 0) {
      setErrors(errors1);
      alert('Please fill in all mandatory fields');
      return;
    }
    try {
      const savedLeaderIds = [];
      for (const leader of leaders) {
        const savedLeader = await firestore
          .collection('company_data').doc(companyName)
          .collection(companyName).doc('for_admin')
          .collection('zone_leader').add({
            name: leader.name,
            contact: leader.contact,
            email: leader.email,
            category: leader.category,
          });
        savedLeaderIds.push(savedLeader.id);
      }
      alert('Your Data inserted successfully');
      clearForm();
    } catch (error) {
      console.error('Error saving zone leaders:', error);
      alert('Data not inserted');
    }
  };

  const validateFields = () => {
    const errors2 = {};

    leaders.forEach((leader, index) => {
      if (!leader.name.trim()) {
        errors2[`name-${index}`] = 'Name is required*';
      }
      if (!leader.category.trim()) {
        errors2[`category-${index}`] = 'Category is required*';
      }
      if (!leader.contact.trim()) {
        errors2[`contact-${index}`] = 'Contact is required*';
      }
      if (!leader.email.trim()) {
        errors2[`email-${index}`] = 'Email is required*';
      }
    });

    return errors2;
  };

  const clearForm = () => {
    setLeaders([
      {
        name: '',
        contact: '',
        email: '',
        category: '',
      },
    ]);
    setErrors({});
  };

  const handleLeaderChange = (value, field, index) => {
    const updatedLeaders = [...leaders];
    updatedLeaders[index][field] = value;
    setLeaders(updatedLeaders);
    setErrors({ ...errors, [`${field}-${index}`]: '' });
  };

  const handleRemoveLeader = (index) => {
    const updatedLeaders = [...leaders];
    updatedLeaders.splice(index, 1);
    setLeaders(updatedLeaders);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      {leaders.map((leader, index) => (
        <Paper key={index} elevation={3} className={classes.leaderContainer}>
          <Typography variant="h6" className={classes.leaderTitle}>
            Leader {index + 1}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id={`category-label-${index}`}>Category *</InputLabel>
                <Select
                  labelId={`category-label-${index}`}
                  value={leader.category}
                  onChange={(e) => handleLeaderChange(e.target.value, 'category', index)}
                  label="Category *"
                  error={!!errors[`category-${index}`]}
                >
                  {['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5', 'Others'].map((level) => (
                    <MenuItem key={level} value={level.toLowerCase().replace(' ', '-')}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
                {errors[`category-${index}`] && (
                  <Typography color="error" variant="caption">
                    {errors[`category-${index}`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name *"
                value={leader.name}
                onChange={(e) => handleLeaderChange(e.target.value, 'name', index)}
                error={!!errors[`name-${index}`]}
                helperText={errors[`name-${index}`]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact *"
                value={leader.contact}
                onChange={(e) => handleLeaderChange(e.target.value, 'contact', index)}
                error={!!errors[`contact-${index}`]}
                helperText={errors[`contact-${index}`]}
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email ID *"
                value={leader.email}
                onChange={(e) => handleLeaderChange(e.target.value, 'email', index)}
                error={!!errors[`email-${index}`]}
                helperText={errors[`email-${index}`]}
                type="email"
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            {index === leaders.length - 1 && (
              <IconButton color="primary" onClick={handleAddLeader} className={classes.addButton}>
                <AddIcon />
              </IconButton>
            )}
            {index > 0 && (
              <IconButton color="secondary" onClick={() => handleRemoveLeader(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </Paper>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.submitButton}
      >
        Submit
      </Button>
    </Container>
  );
};

export default ZoneLeader;