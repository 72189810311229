import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { firestore } from '../../firebase/firebaseConfig'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { makeStyles } from '@material-ui/core/styles';

import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  actionPlanForm: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));


const AccidentReportList = () => {
  const classes = useStyles();
  const [accidentReports, setAccidentReports] = useState([]);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isInvestigationModalOpen, setIsInvestigationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfVerification] = useState(null);
  const location = useLocation();
  const { company_name } = location.state || {};

  // New state for investigation form
  const [investigationTeam, setInvestigationTeam] = useState('');
  const [dateOfInvestigation, setDateOfInvestigation] = useState(new Date());
  const [timeOfInvestigation, setTimeOfInvestigation] = useState(new Date());
  const [rootCause, setRootCause] = useState('');
  const [actionPlans, setActionPlans] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const getAccidentReports = async () => {
      const accidentReportsRef = firestore.collection('company_data')
      .doc(company_name).collection(company_name)
      .doc('for_user').collection('accident_reports');
      const snapshot = await accidentReportsRef.get();
      const accidentReportsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      //sorting based on case number
      const sortedAccidentReports = accidentReportsData.sort((a, b) => {
        const caseNumberA = parseInt(a.caseNumber, 10);
        const caseNumberB = parseInt(b.caseNumber, 10);
        return caseNumberB - caseNumberA;
      });

      setAccidentReports(sortedAccidentReports);
    };
    getAccidentReports();
  }, [company_name]);

  const formatDate = (timestamp) => {
    if (typeof timestamp === "string" && timestamp.includes("-")) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      ).format("MMMM D, YYYY");
    }
    return "Invalid Date";
  };
  
  // const formatTime = (timestamp) => {
  //   if (!timestamp) return 'Invalid Time';
  
  //   let date;
  //   if (typeof timestamp === 'string') {
  //     date = new Date(timestamp);
  //   } else if (timestamp instanceof Date) {
  //     date = timestamp;
  //   } else if (timestamp.seconds) {
  //     // Firestore timestamp
  //     date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  //   } else {
  //     return 'Invalid Time';
  //   }
  
  //   if (isNaN(date.getTime())) return 'Invalid Time';
  
  //   const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  
  //   try {
  //     return date.toLocaleTimeString('en-US', options);
  //   } catch (e) {
  //     // Fallback for environments with limited support for toLocaleTimeString options
  //     return `${date.getHours()}:${date.getMinutes()}`;
  //   }
  // };

    const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return format(date, "dd-MM-yyyy HH:mm:ss");
    }
    return "";
  };
  
  

  const handleDetailsModalOpen = (accident) => {
    setSelectedAccident(accident);
    setIsDetailsModalOpen(true);
  };

  const handleFormModalOpen = (accident) => {
    setSelectedAccident(accident);
    setIsFormModalOpen(true);
  };

  const handleInvestigationModalOpen = (accident) => {
    setSelectedAccident(accident);
    setIsInvestigationModalOpen(true);
    if (accident.DateOfInvestigation) {
      setInvestigationTeam(accident.InvestigationTeam || '');
      setDateOfInvestigation(accident.DateOfInvestigation.toDate());
      setTimeOfInvestigation(accident.TimeOfInvestigation.toDate());
      setRootCause(accident.rootCause || '');
      setActionPlans(accident.actionPlans || []);
      setIsEditMode(true);
    } else {
      setInvestigationTeam('');
      setDateOfInvestigation(new Date());
      setTimeOfInvestigation(new Date());
      setRootCause('');
      setActionPlans([]);
      setIsEditMode(false);
    }
  };

  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    setIsFormModalOpen(false);
    setIsInvestigationModalOpen(false);
    setSelectedAccident(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`accident_report_images/${Date.now()}_${file.name}`);

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleCloseAccidentReport = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const accidentReportRef = firestore.collection('company_data')
      .doc(company_name).collection(company_name).doc('for_user').collection('accident_reports').doc(selectedAccident.id);
      await accidentReportRef.update({
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error('Error updating accident report:', error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfVerification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredAccidentReports = accidentReports.filter(w => 
    tabValue === 0 ? w.isPending : !w.isPending
  );

  const handleAddActionPlan = () => {
    if (actionPlans.length < 5) {
      setActionPlans([...actionPlans, { 
        TypeOfActionPlan: '', 
        ResponsiblePerson: '', 
        ApDescription: '',
        TargetDate: new Date(), 
        status: 'Open' 
      }]);
    }
  };

  const handleRemoveActionPlan = (index) => {
    const newActionPlans = actionPlans.filter((_, i) => i !== index);
    setActionPlans(newActionPlans);
  };

  const handleActionPlanChange = (index, field, value) => {
    const newActionPlans = [...actionPlans];
    newActionPlans[index][field] = value;
    setActionPlans(newActionPlans);
  };

  const handleSubmitInvestigation = async () => {
    setIsLoading(true);
    try {
      const accidentReportRef = firestore.collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('accident_reports')
      .doc(selectedAccident.id);
      await accidentReportRef.update({
        InvestigationTeam: investigationTeam,
        DateOfInvestigation: dateOfInvestigation,
        TimeOfInvestigation: timeOfInvestigation,
        rootCause: rootCause,
        actionPlans: actionPlans,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error('Error updating investigation details:', error);
      setIsLoading(false);
    }
  };

  const canCloseAccidentReport = () => {
    return ( actionPlans.length !== 0 && actionPlans.every(plan => plan.status === 'Close'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

    <StyledPaper elevation={3}>
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Accident Reports
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box mt={3}>
        <Grid container spacing={2}>
          {filteredAccidentReports.map((accident) => (
            <Grid item xs={12} sm={6} md={6} key={accident.id}>
              <StyledCard elevation={1}>
                <CardContent>
                  <Typography><strong>Case Number:</strong> {accident.caseNumber}</Typography>
                  <Typography><strong>Date:</strong> {formatDate(accident.date)}</Typography>
                  <Typography><strong>Area Name:</strong> {accident.areaName}</Typography>
                  <Typography><strong>Description:</strong> {accident.descriptionOfInjury}</Typography>
                  <Typography color={accident.isPending ? "error":"green"}><strong>Status:</strong> {accident.isPending ? 'Pending' : 'Completed'}</Typography>
                  <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => handleDetailsModalOpen(accident)} style={{ marginRight: '10px' }}>
                      View Details
                    </Button>
                    {accident.isPending && (
                    <Button variant="contained" color="secondary" onClick={() => handleInvestigationModalOpen(accident)} style={{ marginRight: '10px' }}>
                      Investigate
                    </Button>
                  )}
                    {accident.isPending && (
                      <Button variant="contained" color="secondary" onClick={() => handleFormModalOpen(accident)}>
                        Close Form
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Details Modal */}
      <Modal
        open={isDetailsModalOpen}
        onClose={handleModalClose}
        aria-labelledby="accident-details-modal-title"
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedAccident && (
            <Box>
              <Typography id="accident-details-modal-title" variant="h6" gutterBottom>Accident Details</Typography>
              <Typography>Description: {selectedAccident.descriptionOfInjury}</Typography>
              <Typography>Date: {formatDate(selectedAccident.date)}</Typography>
              <Typography>Time: {formatTimestamp(selectedAccident.time)}</Typography>
              <Typography>Case Number: {selectedAccident.caseNumber}</Typography>
              <Typography>Affected Workman Employment Type: {selectedAccident.affectedWorkmanEmploymentType}</Typography>
              <Typography>Affected Workmen Employee ID: {selectedAccident.affectedWorkmenEmployeeId}</Typography>
              <Typography>Affected Workmen Name: {selectedAccident.affectedWorkmenName}</Typography>
              <Typography>Area Name: {selectedAccident.areaName}</Typography>
              <Typography>Body Part Affected: {selectedAccident.bodyPartAffected}</Typography>
              <Typography>Contractor Name: {selectedAccident.contractorName}</Typography>
              <Typography>Date of Joining: {formatDate(selectedAccident.dateOfJoining)}</Typography>
              <Typography>Employee ID: {selectedAccident.employeeId}</Typography>
              <Typography>Gender: {selectedAccident.gender}</Typography>
              <Typography>Injury Category: {selectedAccident.injuryCategory}</Typography>
              <Typography>Man Power Affected: {selectedAccident.manpowerAffected}</Typography>
              <Typography>Reporting Person Designation: {selectedAccident.reportingPersonDesignation}</Typography>
              <Typography>Reporting Person Name: {selectedAccident.reportingPersonName}</Typography>
              <Typography>Type of Accident: {selectedAccident.typeOfAccident}</Typography>
              <Box mt={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Back to list
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Investigation Modal */}
      <Modal
        open={isInvestigationModalOpen}
        onClose={handleModalClose}
        aria-labelledby="accident-investigation-modal-title"
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedAccident && (
            <Box>
              <Typography id="accident-investigation-modal-title" variant="h6" gutterBottom>Accident Investigation</Typography>
              <TextField
                label="Investigation Team"
                value={investigationTeam}
                onChange={(e) => setInvestigationTeam(e.target.value)}
                fullWidth
                margin="normal"
                disabled={isEditMode && !isLoading}
              />
              <DatePicker
                label="Date of Investigation"
                value={dateOfInvestigation}
                onChange={setDateOfInvestigation}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                disabled={isEditMode && !isLoading}
              />
              <TimePicker
                label="Time of Investigation"
                value={timeOfInvestigation}
                onChange={setTimeOfInvestigation}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                disabled={isEditMode && !isLoading}
              />
              <TextField
                label="Root Cause"
                value={rootCause}
                onChange={(e) => setRootCause(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                disabled={isEditMode && !isLoading}
              />
              <Typography variant="h6" gutterBottom>Action Plans</Typography>
              {actionPlans.map((plan, index) => (
                <Box key={index} className={classes.actionPlanForm}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type of Action Plan</InputLabel>
                    <Select
                      value={plan.TypeOfActionPlan}
                      onChange={(e) => handleActionPlanChange(index, 'TypeOfActionPlan', e.target.value)}
                      disabled={isEditMode && !isLoading}
                    >
                      <MenuItem value="corrective">Corrective AP</MenuItem>
                      <MenuItem value="preventive">Preventive AP</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                label="Action plan description"
                value={plan.ApDescription}
                onChange={(e) => handleActionPlanChange(index, 'ApDescription', e.target.value)}
                fullWidth
                multiline
                rows={2}
                margin="normal"
                disabled={isEditMode && !isLoading}
              />
                  <TextField
                    label="Responsible Person"
                    value={plan.ResponsiblePerson}
                    onChange={(e) => handleActionPlanChange(index, 'ResponsiblePerson', e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <DatePicker
                    label="Target Date"
                    value={plan.TargetDate}
                    onChange={(date) => handleActionPlanChange(index, 'TargetDate', date)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={isEditMode && !isLoading}
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>CAPA Status</InputLabel>
                    <Select
                      value={plan.status}
                      onChange={(e) => handleActionPlanChange(index, 'status', e.target.value)}
                      disabled={isEditMode && !isLoading}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Close">Close</MenuItem>
                    </Select>
                  </FormControl>
                  {!isEditMode && (
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveActionPlan(index)}
                      disabled={isLoading}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              ))}
              {!isEditMode && actionPlans.length < 5 && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddActionPlan}
                  disabled={isLoading}
                >
                  Add Action Plan
                </Button>
              )}
              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditMode(false)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitInvestigation}
                    disabled={isLoading}
                  >
                    Submit Investigation
                  </Button>
                )}
                {isLoading && <CircularProgress size={24} />}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Close Form Modal */}
      <Modal
        open={isFormModalOpen}
        onClose={handleModalClose}
        aria-labelledby="accident-form-modal-title"
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedAccident && (
            <Box>
              <Typography id="accident-form-modal-title" variant="h6" gutterBottom>Close Accident Report</Typography>
              <Typography>Case Number: {selectedAccident.caseNumber}</Typography>
              
              {/* Display Action Plan Progress */}
              <Typography variant="h6" gutterBottom>Action Plan Progress</Typography>
              {selectedAccident.actionPlans && selectedAccident.actionPlans.map((plan, index) => (
                <>
                <Typography key={index}>
                  Action Plan {index + 1}: {plan.ApDescription}
                  </Typography>
                  <Typography>
                  status: {plan.status}
                  </Typography>
                </>
              ))}
              <Typography>Date of Closing*</Typography>
              <TextField
                type="date"
                value={moment(date).format('YYYY-MM-DD')}
                onChange={handleDateChange}
                fullWidth
                margin="normal"
                disabled={true}
              />
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  disabled={!canCloseAccidentReport()}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image for Verification
                  </Button>
                </label>
                {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
              </Grid>
              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseAccidentReport}
                  disabled={isLoading || !canCloseAccidentReport()}
                >
                  {isLoading ? 'Closing...' : 'Close the form'}
                </Button>
                {isLoading && <CircularProgress size={24} />}
              </Box>
              {!canCloseAccidentReport() && (
                <Typography color="error">
                  All action plans must be closed before closing the accident report.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </Container>
    </StyledPaper>
    </LocalizationProvider>
   
  );
};

export default AccidentReportList;