import React, { useState, useEffect } from 'react';
import { Box, Card, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';

const images = [
  { src: '/Images/dashboard.png', title: 'Dashboard' },
  { src: '/Images/detaileddata.png', title: 'Detail Analytics' },
  { src: '/Images/barchart.png', title: 'Bar Charts' },
  { src: '/Images/linechart.png', title: 'Line Charts' },
  { src: '/Images/forms.png', title: 'Forms' },
];

const cardVariants = {
  initial: (custom) => ({
    y: custom * 20,
    scale: 1 - custom * 0.05,
    zIndex: custom,
    opacity: 0,
  }),
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.5,
    },
  },
};

const ImageCard = ({ image, title }) => (
  <motion.div
    variants={cardVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    style={{ position: 'absolute', width: '100%', height: '100%' }}
  >
    <Card
      sx={{
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
        boxShadow: '0px 10px 30px rgba(0,0,0,0.3)',
      }}
    >
      <CardMedia component="img" height='50%' image={image} alt={title} />
    </Card>
  </motion.div>
);

const CardStack = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Rotate images at intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change card every 2 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%', height:'100%', margin: 'auto' }}>
      {images.map((img, index) => (
        index === currentIndex && (
          <ImageCard key={index} image={img.src} title={img.title} />
        )
      ))}
    </Box>
  );
};

export default CardStack;
