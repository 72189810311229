import React, { useLayoutEffect, useRef } from 'react';
import { Box, useMediaQuery } from '@mui/material';

const images = [
  "/Images/Hero.jpg",
  "/Images/HeroMotors.jpg",
  "/Images/Autoliv.jpg",
  "/Images/ImperialAuto.jpg",
  "/Images/JaiBalaji.jpg",
  "/Images/MahindraCie.jpg",
  "/Images/Pioneer.jpg",
  "/Images/TataHitachi.jpg",
  "/Images/Triton.jpg",
  "/Images/Electrotherm.jpg",
  // Add more image paths as needed
];

const SlidingCarousel = () => {
  const sliderRef = useRef(null);
  const requestRef = useRef(null);
  const start = useRef(null);

  const animate = (timestamp) => {
    if (!start.current) start.current = timestamp;
    const elapsed = timestamp - start.current;

    const slider = sliderRef.current;
    if (!slider) return; // Ensure sliderRef is valid

    const width = slider.scrollWidth / 2;
    const translateX = (elapsed / 20) % width;

    slider.style.transform = `translateX(-${translateX}px)`;

    requestRef.current = requestAnimationFrame(animate);
  };

  useLayoutEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      // Start the animation
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      // Cleanup the animation frame
      cancelAnimationFrame(requestRef.current);
    };
  });

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        position: 'relative',
        gap:6
      }}
    >
      <Box
        ref={sliderRef}
        sx={{
          display: 'flex',
          width: 'fit-content',
        }}
      >
        {images.concat(images).map((image, index) => (
          <Box
            key={index}
            sx={{
              flex: '0 0 auto',
              width: '20%', // Adjust this based on the number of images
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              style={{ width:isSmallScreen ?  '100%' : '50%', display: 'block' }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SlidingCarousel;
