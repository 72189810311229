import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getUIDLocally, setLoginStatusLocally, setUIDLocally } from './../utils/userUtils'; // Ensure these utility functions are imported correctly

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        const userDocRef = firebase.firestore().collection('peoples').doc('users').collection('users').doc(userId);
        const userDoc = await userDocRef.get();

        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  // Logout handler
  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setLoginStatusLocally(false);
      setUIDLocally(null);
      setUserData(null); // Clear user data from state
      // Optionally, redirect to the login or home page after logging out
      window.location.href = '/'; // Or use a router method if using react-router
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  const { company_name, email, permissions, role, user_name, userId } = userData;

  // Navigation handlers
  const goToForm = () => {
    if (!permissions) {
      console.error("Permissions are undefined in Profile");
      // Handle the error, maybe show a message to the user
      return;
    }
    navigate('/user/user/', {
      state: { userId, email, company_name, permissions, user_name }
    });
  };

  const seeTheStatus = () => {
    navigate('/user/manager', {
      state: { userId, company_name, permissions }
    });
  };
 console.log('permission',permissions);
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <div className="text-center mb-4">
            <Image src="/path/to/avatar.png" roundedCircle width="150" height="150" alt="Profile Picture" />
          </div>
          <h2 className="text-center mb-4">{user_name}</h2>
          <p className="text-center mb-1">
            <strong>Company Name:</strong> {company_name}
          </p>
          <p className="text-center mb-1">
            <strong>Email:</strong> {email}
          </p>
          <p className="text-center mb-4">
            <strong>Role:</strong> {role}
          </p>
          <div className="d-flex justify-content-center mb-4">
            <Button variant="primary" className="mr-3" onClick={goToForm}>
              Go to Form
            </Button>
            {role === 'manager' && (
              <Button variant="secondary" onClick={seeTheStatus}>See the Status</Button>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Button variant="danger" onClick={handleLogout}>Logout</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
