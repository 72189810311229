import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { firestore } from '../../firebase/firebaseConfig';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';


const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  maxHeight: '80vh',
  overflowY: 'auto',
}));

const PPEGrievancesList = () => {
  const [ppeGrievances, setPpeGrievances] = useState([]);
  const [selectedGrievance, setSelectedGrievance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfverification] = useState(null);

  const location = useLocation();
  const { company_name } = location.state || {};

  useEffect(() => {
    const getPpeGrievances = async () => {
      const ppeGrievancesRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('ppe_grievances');
      const snapshot = await ppeGrievancesRef.get();
      const ppeGrievancesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
         // Sort work permits by case number (highest first)
     const sortedppeGrievancesData = ppeGrievancesData.sort((a, b) => {
      // Assuming caseNumber is a string, we'll parse it to an integer
      const caseNumberA = parseInt(a.caseNumber, 10);
      const caseNumberB = parseInt(b.caseNumber, 10);
      return caseNumberB - caseNumberA; // For descending order
    });
      setPpeGrievances(ppeGrievancesData);
    };
    getPpeGrievances();
  }, [company_name]);

  const formatDate = (date) => {
    if (typeof date === 'string' && date.includes('-')) {
      return moment(date).format('MMMM D, YYYY');
    }
    if (date && date.seconds) {
      return moment(date.seconds * 1000 + date.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const handleModalOpen = (grievance) => {
    setSelectedGrievance(grievance);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedGrievance(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`ppe_report_images/${Date.now()}_${file.name}`);
    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleCloseWorkPermit = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const workPermitRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('ppe_grievances').doc(selectedGrievance.id);
      await workPermitRef.update({
        isPending: false,
        imageOfVerification: imageUrl,
        dateOfClosing: date,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error('Error updating ppe grievances:', error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfverification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredppeGrievances = ppeGrievances.filter(w => 
    tabValue === 0 ? w.isPending : !w.isPending
  );

  return (
    <StyledPaper elevation={3}>
       <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        PPE Grievances
      </Typography>
      <Tabs 
      value={tabValue} 
      onChange={handleTabChange} 
      centered>
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box mt={3}>
      <Grid container spacing={2}>
        {filteredppeGrievances.map((grievance) => (
          <Grid item xs={12} sm={6} md={4} key={grievance.id}>
            <StyledCard elevation={1}>
              <CardContent>
                <Typography><strong>Case Number:</strong> {grievance.caseNumber}</Typography>
                <Typography><strong>Date:</strong> {formatDate(grievance.dateOfPPEGrievancesRaised)}</Typography>
                <Typography><strong>Area Name:</strong> {grievance.areaName}</Typography>
                <Typography><strong>Description:</strong> {grievance.description}</Typography>
                <Typography color={grievance.isPending ? "error" : "green"}><strong>Status:</strong> {grievance.isPending ? 'Pending' : 'Completed'}</Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={() => handleModalOpen(grievance)}>
                    View Details
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="grievance-modal-title"
        aria-describedby="grievance-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedGrievance && (
            <Box>
              <Typography id="grievance-modal-title" variant="h6" gutterBottom>Grievance Details</Typography>
              <Typography>Area Name: {selectedGrievance.areaName}</Typography>
              <Typography>Case Number: {selectedGrievance.caseNumber}</Typography>
              <Typography>Date of PPE Grievances Raised: {formatDate(selectedGrievance.dateOfPPEGrievancesRaised)}</Typography>
              <Typography>Department Name: {selectedGrievance.departmentName}</Typography>
              <Typography>Employee ID: {selectedGrievance.employeeId}</Typography>
              <Typography>Grievances Raised By: {selectedGrievance.grievancesRaisedBy}</Typography>
              <Typography>PPE Grievances Details: {selectedGrievance.ppeGrievancesDetails}</Typography>
              <Typography>Reporting Person Designation: {selectedGrievance.reportingPersonDesignation}</Typography>
              <Typography>User ID: {selectedGrievance.userId}</Typography>
              {selectedGrievance.isPending && (
                <Box mt={2}>
                  <Typography>Date of Closing*</Typography>
                  <TextField
                    type="date"
                    value={moment(date).format('YYYY-MM-DD')}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                  />
              <Grid item xs={12} >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image for Verification
                </Button>
              </label>
              {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
            </Grid>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseWorkPermit}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Closing...' : 'Close the form'}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
              <Box mt={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Back to list
                </Button>
              </Box>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Container>
    </StyledPaper>
   
  );
};

export default PPEGrievancesList;