// DetailedDataTable.js
import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, Button, Box, CircularProgress
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';

const DetailedDataTable = ({ company_name, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailedData();
  }, [company_name, startDate, endDate, dataType]);

  const fetchDetailedData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const workPermitCollection = db
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('work_permit');

    const querySnapshot = await workPermitCollection.get();

    const detailedData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const dateObj = data.date.toDate ? data.date.toDate() : new Date(data.date);

      if (dateObj >= startDate && dateObj <= endDate) {
        if (
          (dataType === 'issued' && data.noOfWorkPermitIssued > 0) ||
          (dataType === 'closed' && data.noOfWorkPermitClosed > 0) ||
          (dataType === 'inspected' && data.noOfWorkPermitInspected > 0) ||
          (dataType === 'complied' && data.noOfWorkPermitComplied > 0)
        ) {
          detailedData.push({
           // caseNumber: doc.id,
            caseNumber: data.caseNumber,
            date: dateObj.toLocaleDateString(),
            departmentName: data.departmentName,
            workPermitType: data.workPermitType,
            noOfWorkPermitIssued: data.noOfWorkPermitIssued,
            noOfWorkPermitClosed: data.noOfWorkPermitClosed,
            noOfWorkPermitInspected: data.noOfWorkPermitInspected,
            noOfWorkPermitComplied: data.noOfWorkPermitComplied,
          });
        }
      }
    });

    setData(detailedData);
    setLoading(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(workbook, `${dataType}_detailed_data.xlsx`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detailed Data for {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
      </Typography>
      <Button variant="contained" color="primary" onClick={exportToExcel} style={{ marginBottom: '1rem' }}>
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Case Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Work permit type</TableCell>
              <TableCell>Issued</TableCell>
              <TableCell>Closed</TableCell>
              <TableCell>Inspected</TableCell>
              <TableCell>Complied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.caseNumber}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.caseNumber}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.workPermitType}</TableCell>
                <TableCell>{row.noOfWorkPermitIssued}</TableCell>
                <TableCell>{row.noOfWorkPermitClosed}</TableCell>
                <TableCell>{row.noOfWorkPermitInspected}</TableCell>
                <TableCell>{row.noOfWorkPermitComplied}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedDataTable;