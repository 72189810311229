import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Button, useMediaQuery } from '@mui/material';

const PriceCard = ({ title, price, desc, description, feature, buttonText, buttonVariant }) => {
  const [showMoreModules, setShowMoreModules] = useState(false);
  const [showMoreFeatures, setShowMoreFeatures] = useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    
    <Card
      sx={{
        height:600,
        maxWidth: 345,
        border: '1px solid #28CC9E',
        boxShadow: 1,
        borderRadius: 2,
        textAlign: 'center',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        padding: 2, // Adjust padding for better responsiveness
      }}
    >
      <CardContent>
        {/* Plan Title */}
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {title}
        </Typography>

        {/* Price */}
        <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
          {price ? `₹${price} / year` : 'Contact Us'}
        </Typography>
        <Typography variant="caption" component="p" sx={{ marginBottom: 2 }}>
          {desc}
        </Typography>

        {/* Modules */}
        <Typography variant="body2" component="p" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Modules
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          {description.slice(0, 2).map((item, index) => (
            <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
              {item}
            </Typography>
          ))}
          {description.length > 2 && !showMoreModules && (
            <Button onClick={() => setShowMoreModules(true)} variant="text" sx={{color:'#5390d9'}}>
              Show More Modules
            </Button>
          )}
          {showMoreModules && (
            <>
              {description.slice(2).map((item, index) => (
                <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
                  {item}
                </Typography>
              ))}
              <Button onClick={() => setShowMoreModules(false)} variant="text" sx={{color:'#5390d9'}}>
                Show Less Modules
              </Button>
            </>
          )}
        </Box>

        {/* Features */}
        <Typography variant="body2" component="p" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Features
        </Typography>
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          {feature.slice(0, 3).map((item, index) => (
            <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
              {item}
            </Typography>
          ))}
          {feature.length > 3 && !showMoreFeatures && (
            <Button onClick={() => setShowMoreFeatures(true)} variant="text" color="primary">
              Show More Features
            </Button>
          )}
          {showMoreFeatures && (
            <>
              {feature.slice(3).map((item, index) => (
                <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
                  {item}
                </Typography>
              ))}
              <Button onClick={() => setShowMoreFeatures(false)} variant="text" color="primary">
                Show Less Features
              </Button>
            </>
          )}
        </Box>

        {/* Button */}
        <Button variant="contained"
                sx={{width:isSmallScreen ? '40%':'40', backgroundColor: "#28CC9E",color:'white',
                "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },}}
                fullWidth>
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PriceCard;
