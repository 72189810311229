import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Box, Paper, Container, ThemeProvider, createTheme, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions, styled, Card, IconButton, InputLabel, Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Close, CloudUpload, Delete } from "@material-ui/icons";
import { auditSections } from "../utils/questions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getUIDLocally } from "./../utils/userUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DetailedDataTable from "../Screens/users/auditTable/DetailedDataTable";
import { ActionPlanModal } from "./ActionPlanModal";
import { AuditImageUploader } from "../functions/AuditImageUploder";


const db = firebase.firestore();

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          borderRadius: "8px",
          marginBottom: "16px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f8f8f8",
          borderRadius: "8px 8px 0 0",
          "& .MuiAccordionSummary-content": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "16px 24px 24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});


export const Question = React.memo(({ question, answer, onChange, error, onImageChange, onActionPlanClick }) => {
    const handleChange = useCallback((e) => {
      onChange(question.id, e.target.value);
    }, [onChange, question.id]);
  
    return (
      <Box>
        <Typography variant="body1" gutterBottom>{question.question}</Typography>
        {question.type === "radio" ? (
          <FormControl component="fieldset" fullWidth>
            <RadioGroup value={answer || ""} onChange={handleChange}>
              {question.options.map((option) => (
                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <TextField fullWidth value={answer || ""} onChange={handleChange} variant="outlined" />
        )}
        {error && <Typography color="error" variant="caption" sx={{ mt: 1 }}>This field is required.</Typography>}
        <Box display="flex" mt={2}>
          <AuditImageUploader questionId={question.id} onChange={onImageChange} error={error} helperText="This field is required." />
          <Button onClick={() => onActionPlanClick(question.id, question.question)}>Action Plan</Button>
        </Box>
      </Box>
    );
  });